import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Link } from "react-router-dom";
import { MdCircle } from "react-icons/md";
import { ReactComponent as ChatHome } from "../../assets/Home/chatHome.svg";
import { ReactComponent as NosFile } from "../../assets/Home/NosFile.svg";
export default function NosFormulaires() {
  const currentUser = useSelector((state) => state.authentication.user);
  const [accounttype, setAccounttype] = useState("");
  useEffect(() => {
    localStorage.removeItem("activeStep");
    localStorage.removeItem("fromAdmin");
    window.scrollTo(0, 0);
    if (
      currentUser &&
      currentUser.data &&
      currentUser.data.accounttype &&
      currentUser.data.accounttype != null
    ) {
      // setAccounttype((currentUser.data.accounttype).split(","));
      setAccounttype(currentUser.data.accounttype);
    }
  }, [currentUser]);
  // console.log("currentUser",currentUser)
  // console.log("currentUser",currentUser)
  const nosData = [
    {
      heading: "Constitution d’une société commerciale",
      listItem: [
        {
          name: "Société à Responsabilité Limitée Unipersonnelle (EURL)",
          path: `/nos-formulaires/constitution-d’une-société-commerciale/eurl`,
        },
        {
          name: "Société à Responsabilité Limitée (SARL)",
          path: `/nos-formulaires/constitution-d’une-société-commerciale/sarl`,
        },
        {
          name: "Société par Actions Simplifiée Unipersonnelle (SASU)",
          path: `/nos-formulaires/constitution-d’une-société-commerciale/sasu`,
        },
        {
          name: "Société par Actions Simplifiée (SAS)",
          path: `/nos-formulaires/constitution-d’une-société-commerciale/sas`,
        },
        {
          name: "Société en Nom Collectif (SNC)",
          path: `/nos-formulaires/constitution-d’une-société-commerciale/snc`,
        },

        {
          name: "Société Anonyme",
          path: `/nos-formulaires/constitution-d’une-société-commerciale/sa`,
        },
      ],
    },
    {
      heading: "Constitution d’une société civile",
      listItem: [
        {
          name: "Société Civile",
          path: `/nos-formulaires/constitution-d’une-société-civile/sc`,
        },
        {
          name: "Société Civile Immobilière (SCI)",
          path: `/nos-formulaires/constitution-d’une-société-civile/sci`,
        },
        {
          name: "SCI de Construction Vente (SCCV ou SCICV)",
          path: `/nos-formulaires/constitution-d’une-société-civile/sccv`,
        },
        {
          name: "Société Civile De Moyens (SCM)",
          path: `/nos-formulaires/constitution-d’une-société-civile/scm`,
        },
        {
          name: "Société Civile De Placement Immobilier (SCPI)",
          path: `/nos-formulaires/constitution-d’une-société-civile/scpi`,
        },
        {
          name: "Société Civile Professionnelle (SCP)",
          path: `/nos-formulaires/constitution-d’une-société-civile/scp`,
        },
      ],
    },
    {
      heading: "Constitution d’une société libérale",
      listItem: [
        {
          name: `Société d'Exercice Libéral ayant un associé Unique (SELEURL)`,
          path: `/nos-formulaires/constitution-d’une-société-libérale/seleurl`,
        },
        {
          name: `Société d'Exercice Libéral à Responsabilité Limitée (SELARL)`,
          path: `/nos-formulaires/constitution-d’une-société-libérale/selarl`,
        },
        {
          name: `Société d'Exercice Libéral par Actions Simplifiée (SELAS)`,
          path: `/nos-formulaires/constitution-d’une-société-libérale/selas`,
        },

        {
          name: `Société d'Exercice Libéral par Actions Simplifiée Unipersonnelle (SELASU)`,
          path: `/nos-formulaires/constitution-d’une-société-libérale/selasu`,
        },

        {
          name: `Société d'Exercice Libéral sous Forme Anonyme (SELAFA)`,
          path: `/nos-formulaires/constitution-d’une-société-libérale/selafa`,
        },
      ],
    },
  ];
  const secondRowData = [
    {
      heading: "Modification de société",
      listItem: [
        {
          name: `Modifications multiples`,
          path: `/nos-formulaires/modification-de-société/modifications-multiples`,
        },
        {
          name: `Changement Gérant / Président / Nomination`,
          path: `/nos-formulaires/modification-de-société/changement-gerant`,
        },
        {
          name: `Transfert de siège social dans le département`,
          path: `/nos-formulaires/modification-de-société/transfert-siege-meme-departement`,
        },
        {
          name: `Transfert de siège social hors département (départ)`,
          path: `/nos-formulaires/modification-de-société/transfert-siege-hors-departement-depart`,
        },
        {
          name: `Transfert de siège social hors département (arrivée)`,
          path: `/nos-formulaires/modification-de-société/transfert-siege-hors-departement-arrivee`,
        },
        {
          name: `Changement de dénomination`,
          path: `/nos-formulaires/modification-de-société/changement-denomination`,
        },
        {
          name: `Modification d'objet social`,
          path: `/nos-formulaires/modification-de-société/changement-objet-social`,
        },
        {
          name: `Poursuite d'activité malgré pertes`,
          path: `/nos-formulaires/modification-de-société/continuation-activite`,
        },
        {
          name: `Avis de modification de capital`,
          path: `/nos-formulaires/modification-de-société/modification-capital-social`,
        },
        {
          name: `Transformation d’une SARL en SAS`,
          path: `/nos-formulaires/modification-de-société/transformation-sarl-en-sas`,
        },
        {
          name: `Transformation d’une SAS en SARL`,
          path: `/nos-formulaires/modification-de-société/transformation-sas-en-sarl`,
        },
        {
          name: `Cession de parts sociales`,
          path: `/nos-formulaires/modification-de-société/cession-parts-sociales`,
        },
        {
          name: `Transformation d’une SA en SAS`,
          path: `/nos-formulaires/modification-de-société/transformation-sa-en-sas`,
        },
        {
          name: `Prorogation de durée`,
          path: `/nos-formulaires/modification-de-société/prorogation-duree`,
        },
      ],
    },
    {
      heading: "Cessation d’activité",
      listItem: [
        {
          name: `Avis de dissolution anticipée `,
          path: `/nos-formulaires/cessation-d’activité/dissolution-anticipee`,
        },
        {
          name: `Avis de clôture de liquidation`,
          path: `/nos-formulaires/cessation-d’activité/cloture-liquidation`,
        },
        {
          name: `Avis de dissolution sans liquidation TUP`,
          path: `/nos-formulaires/cessation-d’activité/dissolution-sans-liquidation`,
        },
      ],
    },
    {
      heading: "Additifs - rectificatifs",
      listItem: [
        {
          name: `Additif`,
          path: `/nos-formulaires/additifs-rectificatifs/additif`,
        },
        {
          name: `Annonce rectificative `,
          path: `/nos-formulaires/additifs-rectificatifs/rectificatif`,
        },
      ],
    },
    {
      heading: "Fonds de commerce",
      listItem: [
        {
          name: `Location gérance`,
          path: `/nos-formulaires/fonds-de-commerce/location-gerance`,
        },
        {
          name: `Fin de location gérance `,
          path: `/nos-formulaires/fonds-de-commerce/fin-location-gerance`,
        },
        {
          name: `Vente de Fonds de Commerce`,
          path: `/nos-formulaires/fonds-de-commerce/cession-fond-commerce`,
        },
        {
          name: `Apport de Fonds de Commerce`,
          path: `/nos-formulaires/fonds-de-commerce/apport-fond-commerce`,
        },
        {
          name: `Cession de Droit au Bail `,
          path: `/nos-formulaires/fonds-de-commerce/cession-droit-bail`,
        },
      ],
    },
    {
      heading: "Modele libre",
      listItem: [
        {
          name: `Modèle libre`,
          path: `/nos-formulaires/modele-libre/modellibre`,
        },
      ],
    },
  ];

  return (
    <>
      <div
        className="bg-thColor w-full h-full"
        style={{ clipPath: "polygon(0 3%, 100% 0, 100% 100%, 0% 100%)" }}
        id="nosform"
      >
        <div className="md:max-w-[1477px] relative w-full h-full m-auto pt-24 pb-12 sm:pt-24 sm:pb-24 px-6 sm:px-14 ">
          <div className="flex items-center justify-center gap-4 w-full m-auto">
            {/* <img src={NosFile} alt="NosFile" className='w-8 sm:w-auto' />  */}
            <NosFile />
            <h1 className=" text-2xl xl:text-3xl font-black text-white">
              {" "}
              Nos formulaires
            </h1>
          </div>

          <div className="hidden sm:block absolute right-9 top-5 cursor-pointer">
            <ChatHome />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-start  gap-x-12 lg:gap-x-12 xl:gap-x-24 gap-y-12 mt-10 sm:mt-20">
            {nosData.map((items, i) => {
              return (
                <div
                  className="max-w-[454px] m-auto w-full h-full  bg-white rounded-[22px]"
                  key={i}
                >
                  <div className="px-6 py-5 nosShadow flex items-center">
                    <h2 className="text-lg sm:text-xl font-bold text-primary">
                      {items.heading}
                    </h2>
                  </div>
                  <ul className="p-6 flex flex-col gap-5 mt-3 h-max">
                    {items.listItem.map((sublist, i) => {
                      return (
                        <li key={i}>
                          <Link
                            to={sublist.path}
                            className="flex gap-3 items-baseline text-[15px] font-light text-primary hover:font-bold cursor-pointer transition-all duration-300"
                          >
                            <div
                              style={{
                                width: "15px",
                                height: "15px",
                                marginTop: "4px",
                              }}
                            >
                              <MdCircle
                                style={{
                                  color: "#1CBE93",
                                  width: "15px",
                                  height: "15px",
                                }}
                              />
                            </div>
                            {sublist.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3   gap-x-12 lg:gap-x-12 xl:gap-x-24 gap-y-12 mt-10 sm:mt-20">
            <div className="max-w-[454px] m-auto w-full h-full  bg-white rounded-[22px] ">
              <div className="px-6 py-5 nosShadow flex items-center">
                <h2 className="text-lg sm:text-xl font-bold text-primary">
                  {secondRowData[0].heading}
                </h2>
              </div>
              <ul className="p-6 flex flex-col gap-5 mt-3 h-max">
                {secondRowData[0].listItem.map((sublist, i) => {
                  return (
                    <li key={i}>
                      <Link
                        to={sublist.path}
                        className="flex gap-3 items-baseline text-[15px] font-light text-primary hover:font-bold cursor-pointer transition-all duration-300"
                      >
                        <div
                          style={{
                            width: "15px",
                            height: "15px",
                            marginTop: "4px",
                          }}
                        >
                          <MdCircle
                            style={{
                              color: "#1CBE93",
                              width: "15px",
                              height: "15px",
                            }}
                          />
                        </div>
                        {sublist.name}
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div>
              <div className="max-w-[454px] m-auto w-full h-max  bg-white rounded-[22px]">
                <div className="px-6 py-5 nosShadow flex items-center">
                  <h2 className="text-lg sm:text-xl font-bold text-primary ">
                    {secondRowData[1].heading}
                  </h2>
                </div>
                <ul className="p-6 flex flex-col gap-5 mt-3 h-max">
                  {secondRowData[1].listItem.map((sublist, i) => {
                    return (
                      <li key={i}>
                        <Link
                          to={sublist.path}
                          className="flex gap-3 items-baseline text-[15px] font-light text-primary hover:font-bold cursor-pointer transition-all duration-300"
                        >
                          <div
                            style={{
                              width: "15px",
                              height: "15px",
                              marginTop: "4px",
                            }}
                          >
                            <MdCircle
                              style={{
                                color: "#1CBE93",
                                width: "15px",
                                height: "15px",
                              }}
                            />
                          </div>
                          {sublist.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="max-w-[454px] m-auto w-full h-max  bg-white rounded-[22px] mt-12">
                <div className="px-6 py-5 nosShadow flex items-center">
                  <h2 className="text-lg sm:text-xl font-bold text-primary ">
                    {secondRowData[2].heading}
                  </h2>
                </div>
                <ul className="p-6 flex flex-col gap-5 mt-3 h-max">
                  {secondRowData[2].listItem.map((sublist, i) => {
                    return (
                      <li key={i}>
                        <Link
                          to={sublist.path}
                          className="flex gap-3 items-baseline text-[15px] font-light text-primary hover:font-bold cursor-pointer transition-all duration-300"
                        >
                          <div
                            style={{
                              width: "15px",
                              height: "15px",
                              marginTop: "4px",
                            }}
                          >
                            <MdCircle
                              style={{
                                color: "#1CBE93",
                                width: "15px",
                                height: "15px",
                              }}
                            />
                          </div>
                          {sublist.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              {accounttype.includes("free") ? (
                <div className="max-w-[454px] m-auto w-full h-max  bg-white rounded-[22px] mt-12">
                  <div className="px-6 py-5 nosShadow flex items-center">
                    <h2 className="text-lg sm:text-xl font-bold text-primary ">
                      {secondRowData[4].heading}
                    </h2>
                  </div>
                  <ul className="p-6 flex flex-col gap-5 mt-3 h-max">
                    {secondRowData[4].listItem.map((sublist, i) => {
                      return (
                        <li key={i}>
                          <Link
                            to={sublist.path}
                            className="flex gap-3 items-baseline text-[15px] font-light text-primary hover:font-bold cursor-pointer transition-all duration-300"
                          >
                            <div
                              style={{
                                width: "15px",
                                height: "15px",
                                marginTop: "4px",
                              }}
                            >
                              <MdCircle
                                style={{
                                  color: "#1CBE93",
                                  width: "15px",
                                  height: "15px",
                                }}
                              />
                            </div>
                            {sublist.name}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                ""
              )}
            </div>
            <div>
              <div className="max-w-[454px] m-auto w-full h-max  bg-white rounded-[22px]">
                <div className="px-6 py-5 nosShadow flex items-center">
                  <h2 className="text-lg sm:text-xl font-bold text-primary ">
                    {secondRowData[3].heading}
                  </h2>
                </div>
                <ul className="p-6 flex flex-col gap-5 mt-3 h-max">
                  {secondRowData[3].listItem.map((sublist, i) => {
                    return (
                      <li key={i}>
                        <Link
                          to={sublist.path}
                          className="flex gap-3 items-baseline text-[15px] font-light text-primary hover:font-bold cursor-pointer transition-all duration-300"
                        >
                          <div
                            style={{
                              width: "15px",
                              height: "15px",
                              marginTop: "4px",
                            }}
                          >
                            <MdCircle
                              style={{
                                color: "#1CBE93",
                                width: "15px",
                                height: "15px",
                              }}
                            />
                          </div>
                          {sublist.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
