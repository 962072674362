/* eslint-disable array-callback-return */
import React, {useState, useEffect } from 'react' 
import moment from 'moment';
import dayjs from 'dayjs';
import { BsFillQuestionCircleFill, BsPlusCircle } from 'react-icons/bs'
import { MdEuroSymbol } from 'react-icons/md'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/fr.js';
import { frFR } from '@mui/x-date-pickers/locales';
import Tooltip from '@mui/material/Tooltip';
import { FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select,ListSubheader , TextField ,Divider} from '@mui/material';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';

export  function CessionPartsSociales(props) {
 
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const StyledTooltip = ({ title, children, ...props }) => (
    <Tooltip
      {...props}
      title={title}
      placement="right"
      componentsProps={{
        tooltip: {
          sx: {
            background: '#DEE3E2',
            width: '129px',
            padding: '10px',
            color: '#27295B',
            fontSize: '14px',
            fontWeight: '400'
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );

  return (
    <>
      <div className='' >
        <div className='py-6 px-3 sm:px-6' style={{ borderBottom: '0.5px solid #DEE3E2' }}>
          <h1 className='text-lg sm:text-2xl font-black text-primary'>Annonce légale : {props.adsname}</h1>
        </div>
        <div className='py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Choisir l'organe et la date de la décision</h2>
          
          <div className='grid grid-cols-12 items-center mt-2 sm:mt-6'>
            <label className='col-span-12 sm:col-span-3 my-2 sm:mb-0 text-base font-normal text-black-light'>Par décision *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Sélectionnez"
                  id="type_perdecision_acte" name="type_perdecision_acte"
                  value={props.formData.type_perdecision_acte} onChange={props.handleChange}
                  error={!props.formData.type_perdecision_acte && props.isSubmitted?true:false}
                >
                  <MenuItem value={"AGO"}>de L'Assemblée Générale Ordinaire</MenuItem>
                  <MenuItem value={"AGE"}>de L'Assemblée Générale Extraordinaire</MenuItem>
                  <MenuItem value={"AGM"}>de L'Assemblée Générale Mixte</MenuItem>
                  <MenuItem value={"Gerant"}>du Gérant</MenuItem>
                  <MenuItem value={"President"}>du Président</MenuItem>
                  <MenuItem value={"Conseil_Administration"}>du Conseil d'Administration</MenuItem>
                  <MenuItem value={"Associe_Unique"}>de L'Associé Unique</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Date de l'AG *</label>
            <div className='col-span-12 sm:col-span-9 mr-7 md:mr-0 md:col-span-7 lg:col-span-4'>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}  >
                <DemoContainer components={['DatePicker']}  >
                  {
                    props.formData.date_typeag?
                    <DatePicker value={dayjs(props.formData.date_typeag)}   name="date_typeag" onChange={(e)=>{props.handleChangeDate(e,"date_typeag")}} format={"DD/MM/YYYY"}                    
                    />
                    :
                    <DatePicker  name="date_typeag"  onChange={(e)=>{props.handleChangeDate(e,"date_typeag")}} format={"DD/MM/YYYY"}                   
                    />
                  }
                  
                </DemoContainer>
              </LocalizationProvider>
            </div>

          </div>
          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Date d'effet *</label>
            <div className='col-span-12 sm:col-span-9 mr-7 md:mr-0 md:col-span-7 lg:col-span-4'>
              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}  >
                <DemoContainer components={['DatePicker']}  >
                  {
                    props.formData.date_type_effet?
                    <DatePicker value={dayjs(props.formData.date_type_effet)}   name="date_type_effet"   onChange={(e)=>{props.handleChangeDate(e,"date_type_effet")}} format={"DD/MM/YYYY"}                   
                    />
                    :
                    <DatePicker  name="date_type_effet"   onChange={(e)=>{props.handleChangeDate(e,"date_type_effet")} }  format={"DD/MM/YYYY"}                  
                    />
                  }
                  
                </DemoContainer>
              </LocalizationProvider>
            </div>

          </div>
        </div>

        <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>La société</h2>
          <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>N° SIREN *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7  mr-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full outline-secondary focus:outline-secondary'
                name="rcs_societe" 
                placeholder='845098359'
                value={props.formData.rcs_societe} 
                onChange={props.handleChange} 
                error={!props.formData.rcs_societe  && props.isSubmitted?true:false}
              /> 
            </div>
          </div>        
          <div className='grid grid-cols-12 items-center mt-3 sm:mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Dénomination *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full outline-secondary focus:outline-secondary'
                name="denomination_societe" 
                value={props.formData.denomination_societe} 
                onChange={props.handleChange}  
                error={!props.formData.denomination_societe  && props.isSubmitted?true:false}
              />
              <StyledTooltip title="Indiquez le nom de la Société tel que mentionné dans les statuts.">
                <div className='p-0 m-0 w-auto'>
                  <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                </div>
              </StyledTooltip>
            </div>
          </div> 

          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Forme juridique *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-8'>
            <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Choisissez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Choisissez"
                  id="forme_juridique_societe" 
                  name="forme_juridique_societe"
                  value={props.formData.forme_juridique_societe} 
                  onChange={(e)=>props.handleChange(e)}  
                  error={!props.formData.forme_juridique_societe  && props.isSubmitted?true:false}
                > 
                  <ListSubheader>Constitution de société commerciale</ListSubheader>
                    <MenuItem onClick={(e)=>props.handleSelectChange(e)} value="SARLU" id={"parts sociales"} >SARL Unipersonnelle (EURL)</MenuItem>
                    <MenuItem value="SARL" onClick={(e)=>props.handleSelectChange(e)} id={"parts sociales"}>Société à responsabilité limitée (SARL)</MenuItem>
                    <MenuItem value="SASU" onClick={(e)=>props.handleSelectChange(e)} id={"actions"}>Société par actions simplifiée unipersonnelle (SASU)</MenuItem>
                    <MenuItem value="SAS" onClick={(e)=>props.handleSelectChange(e)} id={"actions"} >Société par actions simplifiée (SAS)</MenuItem>
                    <MenuItem value="SA" onClick={(e)=>props.handleSelectChange(e)} id={"actions"} >Société anonyme (SA)</MenuItem> 
                    <MenuItem value="SNC" onClick={(e)=>props.handleSelectChange(e)} id={"parts sociales"} >Société en nom collectif (SNC)</MenuItem>
                  
                  <ListSubheader>Constitution d'une société civile</ListSubheader>
                    <MenuItem value="SC" onClick={(e)=>props.handleSelectChange(e)} id={"parts sociales"} >Société civile</MenuItem>
                    <MenuItem value="SCI" onClick={(e)=>props.handleSelectChange(e)} id={"parts sociales"} >Société civile immobilière (SCI)</MenuItem>
                    <MenuItem value="SCCV" onClick={(e)=>props.handleSelectChange(e)} id={"parts sociales"} >Société civile immobilière de construction vente (SCCV ou SCICV)</MenuItem>
                    <MenuItem value="SCM" onClick={(e)=>props.handleSelectChange(e)} id={"parts sociales"} >Société civile de moyens (SCM)</MenuItem>
                    <MenuItem value="SCPI" onClick={(e)=>props.handleSelectChange(e)} id={"parts sociales"} >Société civile de placement immobilier (SCPI)</MenuItem>
                    <MenuItem value="SCP" onClick={(e)=>props.handleSelectChange(e)} id={"parts sociales"} >Société civile professionnelle (SCP)</MenuItem>

                  <ListSubheader>Constitution d'une société libéral</ListSubheader>                  
                    <MenuItem value="SELARL" onClick={(e)=>props.handleSelectChange(e)} id={"parts sociales"} >Société d'exercice libéral a responsabilité limitée (SELARL)</MenuItem>
                    <MenuItem value="SELEURL" onClick={(e)=>props.handleSelectChange(e)} id={"parts sociales"} >Société d'exercice libéral ayant un associé Unique (SELEURL)</MenuItem>
                    <MenuItem value="SELAFA" onClick={(e)=>props.handleSelectChange(e)} id={"actions"} >Société d'exercice libéral sous forme anonyme (SELAFA)</MenuItem>
                    <MenuItem value="SELAS" onClick={(e)=>props.handleSelectChange(e)} id={"actions"} >Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                    <MenuItem value="SELASU" onClick={(e)=>props.handleSelectChange(e)} id={"actions"} >Société d'exercice libéral par actions simplifiée unipersonnelle (SELASU)</MenuItem>
                    <MenuItem value="SELCA" onClick={(e)=>props.handleSelectChange(e)} id={"actions"} >Société d'exercice libéral en commandite par Actions (SELCA)</MenuItem>
                    <MenuItem value="SCEA" onClick={(e)=>props.handleSelectChange(e)} id={"parts sociales"} >Société civile d'exploitation agricole (SCEA)</MenuItem>
                    <MenuItem value="EARL" onClick={(e)=>props.handleSelectChange(e)} id={"parts sociales"} >Exploitation agricole à responsabilité limitée (EARL)</MenuItem>
                    <MenuItem value="GAEC" onClick={(e)=>props.handleSelectChange(e)} id={"parts sociales"} >Groupement agricole d'exploitation en commun (GAEC)</MenuItem>
                    <MenuItem value="GFA" onClick={(e)=>props.handleSelectChange(e)} id={"parts sociales"} >Groupement foncier agricole (GFA)</MenuItem>
                    <MenuItem value="EIRL" onClick={(e)=>props.handleSelectChange(e)} id={"parts sociales"} >Entrepreneur Individuel à Responsabilité Limité</MenuItem>
                    <MenuItem value="SPFPLAS" onClick={(e)=>props.handleSelectChange(e)} id={"actions"}>Société de Participations Financières de Profession Libérale d'Avocats Simplifiée (SPFPLAS)</MenuItem>
                  
                </Select>
              </FormControl>
             {/*  <TextField
                hiddenLabel
                size="small"
                className='w-full'
                id="forme_juridique_societe" 
                name="forme_juridique_societe"
                value={props.formData.forme_juridique_societe} 
                onChange={props.handleChange}  
                error={!props.formData.forme_juridique_societe  && props.isSubmitted?true:false}
              /> */}
            </div>
          </div>

          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>

              <FormControl size="small" className='w-full'>
                <OutlinedInput
                  id="outlined-adornment-weight"
                  endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  name="capital_societe"
                  value={props.formData.capital_societe} 
                  onChange={props.handleChange}  
                  error={!props.formData.capital_societe  && props.isSubmitted?true:false}
                />
              </FormControl>

              <StyledTooltip title="Montant du capital mentionné dans les statuts.">
                <div className='p-0 m-0 w-auto'>
                  <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                </div>
              </StyledTooltip>

            </div>
          </div>
          <div className='grid grid-cols-12 mt-3 sm:mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="address_societe"
                value={props.formData.address_societe} 
                onChange={props.handleChange}  
                error={!props.formData.address_societe  && props.isSubmitted?true:false}
              />
            </div>
          </div>

          <div className='grid grid-cols-12 mt-3 sm:mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse suite</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="address_suite_societe"
                value={props.formData.address_suite_societe} 
                onChange={props.handleChange}  
                // error={!props.formData.address_societe  && props.isSubmitted?true:false}
              />
            </div>
          </div>

          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="code_postal"  maxLength="5"
                value={props.formData.code_postal} 
                onChange={(e)=>{props.handleChange(e)}}  
                error={!props.formData.code_postal  && props.isSubmitted?true:false}
              />
            </div>
          </div>
          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              {/* <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="ville"
                value={props.formData.ville} 
                onChange={props.handleChange}  
                error={!props.formData.ville  && props.isSubmitted?true:false}
              /> */}
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Sélectionnez"
                  name="ville_id"
                  value={props.formData.ville_id}                   
                  onChange={props.handleChange}  
                  error={!props.formData.ville  && props.isSubmitted?true:false}
                  
                >
                  {
                    props.formData.villeArr.map((cityVal,cityIndex)=>{
                      return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                    })
                  }

                </Select>
              </FormControl>
            </div>
          </div>
          <div className='grid grid-cols-12 mt-6 items-center'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Greffe *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full'
                name="greffe" 
                value={props.formData.greffe} 
                onChange={props.handleChange} 
                error={!props.formData.greffe  && props.isSubmitted?true:false}
              />
            </div>
          </div>
        </div>
        
      <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Cédant</h2>
          {props.La_gerance.map((val,key)=>{
            return <div key={val.index} index={val.index}  >   
              <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Personne *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-9 md:mr-10 inline-flex items-center gap-2'>
                  <FormControl size="small" className='w-full'>
                    <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id={val.index}
                      label="Sélectionnez"
                      name="Personne_typecendant"
                      value={val.Personne_typecendant} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"lagrance")}}  
                      error={!val.Personne_typecendant  && props.isSubmitted?true:false}
                      index={val.index}
                    >
                      <MenuItem value={"PERSONNE_PHYSIQUE"}>Personne physique</MenuItem>
                      <MenuItem value={"PERSONNE_MORALE"}>Personne morale</MenuItem>

                    </Select>
                  </FormControl>
                  {/* <StyledTooltip title="La personne physique désigne un individu. La personne morale, elle, est une entité juridique.">
                    <div className='p-0 m-0 w-auto'>
                      <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer  hover:fill-secondary' />
                    </div>
                  </StyledTooltip> */}
                 {/*  {val.index >0?
                  <div className='p-0 m-0 w-auto'>
                      <RemoveCircleRoundedIcon fontSize='large' className='text-3xl  cursor-pointer  fill-secondary' onClick={(e)=>{props.removeForm(val.index)}} />
                  </div>
                  :""} */}
                </div>
                
              </div>
              <div className={val.Personne_typecendant === ""?"hidden":""}>
                <div className={val.Personne_typecendant === "PERSONNE_MORALE"?"":"hidden"}>      
                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>N° SIREN *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="rcs_cendant"
                        value={val.rcs_cendant} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"lagrance")}}   
                        error={!val.rcs_cendant  && props.isSubmitted?true:false}
                        index={val.index}
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Dénomination *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="denomination_morale"
                        value={val.denomination_morale} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"lagrance")}}  
                        error={!val.denomination_morale  && props.isSubmitted?true:false}
                        index={val.index}
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-12 items-start mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Forme juridique *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sélectionnez"
                          name="forme_juridique_morale"
                          value={val.forme_juridique_morale} 
                          onChange={(e)=>{props.handleChangeAddForm(e,val.index,"lagrance")}}    
                          error={!val.forme_juridique_morale  && props.isSubmitted?true:false}
                          index={val.index}
                        >
                          <MenuItem value={"SAS"}>Société par actions simplifiée (SAS)</MenuItem>
                          <MenuItem value={"SAS à capital variable"}>Société par actions simplifiée à capital variable</MenuItem>
                          <MenuItem value={"SASU"}>Société par actions simplifiée unipersonnelle (SASU)</MenuItem>
                          <MenuItem value={"SASU à capital variable"}>Société par actions simplifiée unipersonnelle à capital variable</MenuItem>
                          <MenuItem value={"SARL"}>Société à responsabilité limitée (SARL)</MenuItem>
                          <MenuItem value={"SARL à capital variable"}>Société à responsabilité limitée à capital variable</MenuItem>
                          <MenuItem value={"SARLU"}>Société à responsabilité limitée à associé unique (SARLU)</MenuItem>
                          <MenuItem value={"SARLU à capitale variable"}>Société à responsabilité limitée à associé unique à capitale variable</MenuItem>
                          <MenuItem value={"SCI"}>Société civile immobilière (SCI)</MenuItem>
                          <MenuItem value={"SCI à capital variable"}>Société civile immobilière à capital variable</MenuItem>
                          <MenuItem value={"SC"}>Société civile</MenuItem>
                          <MenuItem value={"SA"}>Société anonyme (SA)</MenuItem>
                          <MenuItem value={"SA à capital variable"}>Société anonyme à capital variable</MenuItem>
                          <MenuItem value={"SNC"}>Société en nom collectif (SNC)</MenuItem>
                          <MenuItem value={"SNC à capital variable"}>Société en nom collectif à capital variable</MenuItem>
                          <MenuItem value={"SCM"}>Société civile de moyens (SCM)</MenuItem>
                          <MenuItem value={"SCP"}>Société civile professionnelle (SCP)</MenuItem>
                          <MenuItem value={"SCPI"}>Société civile de placement immobilier (SCPI)</MenuItem>
                          <MenuItem value={"SCCV"}>Société civile immobilière de construction vente (SCCV ou SCICV)</MenuItem>
                          <MenuItem value={"SELAFA"}>Société d'exercice libéral sous forme anonyme (SELAFA)</MenuItem>
                          <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                          <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                          <MenuItem value={"SELCA"}>Société d'exercice libéral en commandite par Actions (SELCA)</MenuItem>
                          <MenuItem value={"SELARL"}>Société d'exercice libéral a responsabilité limitée (SELARL)</MenuItem>
                          <MenuItem value={"SELEURL"}>Société d'exercice libéral ayant un associé Unique (SELEURL)</MenuItem>
                          <MenuItem value={"SELASU"}>Société d'exercice libéral par actions simplifiée unipersonnelle (SELASU)</MenuItem>
                          <MenuItem value={"SCEA"}>Société civile d'exploitation agricole (SCEA)</MenuItem>
                          <MenuItem value={"EARL"}>Exploitation agricole à responsabilité limitée (EARL)</MenuItem>
                          <MenuItem value={"GAEC"}>Groupement agricole d'exploitation en commun (GAEC)</MenuItem>
                          <MenuItem value={"GFA"}>Groupement foncier agricole (GFA)</MenuItem>
                          <MenuItem value={"EIRL"}>Entrepreneur Individuel à Responsabilité Limité</MenuItem>
                          <MenuItem value={"SPFPLAS"}>Société de Participations Financières de Profession Libérale d'Avocats Simplifiée (SPFPLAS)</MenuItem>
                        </Select>
                      </FormControl>
                      
                    </div>
                  </div>
                  <div  className={"grid grid-cols-12 items-center mt-6"} >
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>
                      
                      <FormControl size="small" className='w-full'>
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            'aria-label': 'weight',
                          }}
                          name="capital_morale"
                          value={val.capital_morale} 
                          onChange={(e)=>{props.handleChangeAddForm(e,val.index,"lagrance")}}   
                          error={!val.capital_morale  && props.isSubmitted?true:false}
                          index={val.index}
                        />
                      </FormControl>

                      <StyledTooltip title="Montant du capital mentionné dans les statuts.">
                        <div className='p-0 m-0 w-auto'>
                          <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                        </div>
                      </StyledTooltip>

                    </div>
                  </div>
                </div> 
                <div className={val.Personne_typecendant === "PERSONNE_PHYSIQUE"?"":"hidden"}>
                  <div className='grid grid-cols-12 items-start mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sélectionnez"
                          name="civilite_cendant"
                          value={val.civilite_cendant} 
                          onChange={(e)=>{props.handleChangeAddForm(e,val.index,"lagrance")}}   
                          error={!val.civilite_cendant  && props.isSubmitted?true:false}
                          index={val.index}
                          
                        >
                          <MenuItem value={"Mme"}>Madame</MenuItem>
                          <MenuItem value={"M."}>Monsieur</MenuItem>

                        </Select>
                      </FormControl>
                      
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="nom_cendant"
                        value={val.nom_cendant} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"lagrance")}}   
                        error={!val.nom_cendant  && props.isSubmitted?true:false}
                        index={val.index}
                      />
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="prenom_cendant" 
                        value={val.prenom_cendant} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"lagrance")}}  
                        error={!val.prenom_cendant  && props.isSubmitted?true:false}
                        index={val.index}
                      />
                    </div>
                  </div>
                </div>
                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="address_morale" 
                      value={val.address_morale} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"lagrance")}}   
                      error={!val.address_morale  && props.isSubmitted?true:false}
                      index={val.index}
                    />
                  </div>
                </div>
                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Complément adresse</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="adresssuite_morale"
                      value={val.adresssuite_morale} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"lagrance")}}  
                      index={val.index}
                    />
                  </div>
                </div>
                <div className={val.Personne_typecendant === "PERSONNE_PHYSIQUE"?"grid grid-cols-12 mt-6 items-center":"grid-cols-12 mt-6 items-center hidden"} >
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Résidant à l’étranger</label>
                  
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <FormControl size="small" className='w-full'>
                      <InputLabel id="resident_etranger_physique_morale-label">Sélectionnez</InputLabel>
                      <Select
                        labelId="demo-resident_etranger_physique_morale-label"
                        id="resident_etranger_physique_morale"
                        label="resident_etranger_physique_morale"
                        name="resident_etranger_physique_morale"
                        value={`${val.resident_etranger_physique_morale}`}                         
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"lagrance")}}   
                        index={val.index}
                      >
                        <MenuItem value={"Non"} >Non</MenuItem>
                        <MenuItem value={"Oui"} >Oui</MenuItem>

                      </Select>
                    </FormControl>
                  </div>
                </div>

                <div className={val.resident_etranger_physique_morale==="Non"||  val.Personne_typecendant === "PERSONNE_MORALE"?'grid grid-cols-12 mt-6 items-center':'hidden grid-cols-12 mt-6 items-center'} >
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="code_postal_morale"
                      value={val.code_postal_morale} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"lagrance")}}  
                      error={!val.code_postal_morale  && props.isSubmitted?true:false}
                      index={val.index}
                    />
                  </div>
                </div>

                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className={val.Personne_typecendant === "PERSONNE_PHYSIQUE" && val.resident_etranger_physique_morale=="Oui"?'w-full':'hidden w-full'}
                      name="ville_morale"
                      value={val.ville_morale} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"lagrance")}}  
                      error={!val.ville_morale  && props.isSubmitted?true:false}
                      index={val.index}
                    />
                    <FormControl size="small" className={val.Personne_typecendant === "PERSONNE_PHYSIQUE" && val.resident_etranger_physique_morale=="Oui"?'hidden w-full':' w-full'} >
                      <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Sélectionnez"                       
                        name="ville_id_morale"
                        value={val.ville_id_morale} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"lagrance")}}  
                        error={!val.ville_morale  && props.isSubmitted?true:false}
                        index={val.index}
                      >
                        {
                          val.ville_moraleArr.map((cityVal,cityIndex)=>{
                            return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                          })
                        }

                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className={val.Personne_typecendant === "PERSONNE_PHYSIQUE" && val.resident_etranger_physique_morale==="Oui"?'grid grid-cols-12 mt-6 items-center':'hidden grid-cols-12 mt-6 items-center'}>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Pays *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    className='w-full'
                    name="pays_morale"
                    value={val.pays_morale} 
                    onChange={(e)=>{props.handleChangeAddForm(e,val.index,"lagrance")}}  
                    error={!val.pays_morale  && props.isSubmitted?true:false}
                    index={val.index}
                  />
                </div>
                </div>
                <div className={val.Personne_typecendant === "PERSONNE_MORALE"?"grid grid-cols-12 mt-6 items-center":"grid-cols-12 mt-6 items-center hidden"} >
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Greffe </label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="greffe_morale"
                      value={val.greffe_morale} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"lagrance")}}   
                      error={!val.greffe_morale  && props.isSubmitted?true:false}
                      index={val.index}
                    />
                  </div>
                </div>
                <div className={val.Personne_typecendant === "PERSONNE_MORALE"?"":"hidden"}>
                  <div className='px-0 group flex items-center gap-2 text-secondary mt-12 '>                   
                    <span className='text-base font-normal'>Représentée par</span>
                  </div>
                  <div className='grid grid-cols-12 items-start mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité </label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sélectionnez"
                          name="representation_morale"
                          value={val.representation_morale} 
                          onChange={(e)=>{props.handleChangeAddForm(e,val.index,"lagrance")}}                            
                          index={val.index}
                          
                        >
                          <MenuItem value={"Mme"}>Madame</MenuItem>
                          <MenuItem value={"M."}>Monsieur</MenuItem>

                        </Select>
                      </FormControl>
                      
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="representation_nom_morale" 
                        value={val.representation_nom_morale} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"lagrance")}}  
                        error={!val.representation_nom_morale  && props.isSubmitted?true:false}
                        index={val.index}
                      />
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="representation_prenom_morale" 
                        value={val.representation_prenom_morale} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"lagrance")}}  
                        error={!val.representation_prenom_morale  && props.isSubmitted?true:false}
                        index={val.index}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Divider className='mb-6 mt-6' variant="middle" />
            </div> 
          })}  
          {/* <div className='px-0 group flex items-center gap-2 text-primary hover:text-secondary mt-12 cursor-pointer ' onClick={(e)=> props.addForm(e,"lagrance")}>
            <BsPlusCircle className='text-3xl ' />
            <span className='text-base font-normal'>Ajouter un gérant</span>
          </div> */}        
      </div>     
      <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
          <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Bénéficiaire</h2>
          {props.Benefiser.map((val,key)=>{
            return <div key={val.index} index={val.index}  >   
              <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Personne *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-9 md:mr-10 inline-flex items-center gap-2'>
                  <FormControl size="small" className='w-full'>
                    <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id={val.index}
                      label="Sélectionnez"
                      name="Personne_benefiser"
                      value={val.Personne_benefiser} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Benefiser")}}  
                      error={!val.Personne_benefiser  && props.isSubmitted?true:false}
                      index={val.index}
                    >
                      <MenuItem value={"PERSONNE_PHYSIQUE"}>Personne physique</MenuItem>
                      <MenuItem value={"PERSONNE_MORALE"}>Personne morale</MenuItem>

                    </Select>
                  </FormControl>
                  {/* <StyledTooltip title="La personne physique désigne un individu. La personne morale, elle, est une entité juridique.">
                    <div className='p-0 m-0 w-auto'>
                      <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer  hover:fill-secondary' />
                    </div>
                  </StyledTooltip> */}
                 {/*  {val.index >0?
                  <div className='p-0 m-0 w-auto'>
                      <RemoveCircleRoundedIcon fontSize='large' className='text-3xl  cursor-pointer  fill-secondary' onClick={(e)=>{props.removeForm(val.index)}} />
                  </div>
                  :""} */}
                </div>
                
              </div>
              <div className={val.Personne_benefiser === ""?"hidden":""}>
                <div className={val.Personne_benefiser === "PERSONNE_MORALE"?"":"hidden"}>      
                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>N° SIREN *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="rcs_benefiser"
                        value={val.rcs_benefiser} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Benefiser")}}   
                        error={!val.rcs_benefiser  && props.isSubmitted?true:false}
                        index={val.index}
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Dénomination *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="denomination_benefiser"
                        value={val.denomination_benefiser} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Benefiser")}}  
                        error={!val.denomination_benefiser  && props.isSubmitted?true:false}
                        index={val.index}
                      />
                    </div>
                  </div>
                  <div className='grid grid-cols-12 items-start mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Forme juridique *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sélectionnez"
                          name="forme_jurdique_benefiser"
                          value={val.forme_jurdique_benefiser} 
                          onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Benefiser")}}    
                          error={!val.forme_jurdique_benefiser  && props.isSubmitted?true:false}
                          index={val.index}
                        >
                          <MenuItem value={"SAS"}>Société par actions simplifiée (SAS)</MenuItem>
                          <MenuItem value={"SAS à capital variable"}>Société par actions simplifiée à capital variable</MenuItem>
                          <MenuItem value={"SASU"}>Société par actions simplifiée unipersonnelle (SASU)</MenuItem>
                          <MenuItem value={"SASU à capital variable"}>Société par actions simplifiée unipersonnelle à capital variable</MenuItem>
                          <MenuItem value={"SARL"}>Société à responsabilité limitée (SARL)</MenuItem>
                          <MenuItem value={"SARL à capital variable"}>Société à responsabilité limitée à capital variable</MenuItem>
                          <MenuItem value={"SARLU"}>Société à responsabilité limitée à associé unique (SARLU)</MenuItem>
                          <MenuItem value={"SARLU à capitale variable"}>Société à responsabilité limitée à associé unique à capitale variable</MenuItem>
                          <MenuItem value={"SCI"}>Société civile immobilière (SCI)</MenuItem>
                          <MenuItem value={"SCI à capital variable"}>Société civile immobilière à capital variable</MenuItem>
                          <MenuItem value={"SC"}>Société civile</MenuItem>
                          <MenuItem value={"SA"}>Société anonyme (SA)</MenuItem>
                          <MenuItem value={"SA à capital variable"}>Société anonyme à capital variable</MenuItem>
                          <MenuItem value={"SNC"}>Société en nom collectif (SNC)</MenuItem>
                          <MenuItem value={"SNC à capital variable"}>Société en nom collectif à capital variable</MenuItem>
                          <MenuItem value={"SCM"}>Société civile de moyens (SCM)</MenuItem>
                          <MenuItem value={"SCP"}>Société civile professionnelle (SCP)</MenuItem>
                          <MenuItem value={"SCPI"}>Société civile de placement immobilier (SCPI)</MenuItem>
                          <MenuItem value={"SCCV"}>Société civile immobilière de construction vente (SCCV ou SCICV)</MenuItem>
                          <MenuItem value={"SELAFA"}>Société d'exercice libéral sous forme anonyme (SELAFA)</MenuItem>
                          <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                          <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                          <MenuItem value={"SELCA"}>Société d'exercice libéral en commandite par Actions (SELCA)</MenuItem>
                          <MenuItem value={"SELARL"}>Société d'exercice libéral a responsabilité limitée (SELARL)</MenuItem>
                          <MenuItem value={"SELEURL"}>Société d'exercice libéral ayant un associé Unique (SELEURL)</MenuItem>
                          <MenuItem value={"SELASU"}>Société d'exercice libéral par actions simplifiée unipersonnelle (SELASU)</MenuItem>
                          <MenuItem value={"SCEA"}>Société civile d'exploitation agricole (SCEA)</MenuItem>
                          <MenuItem value={"EARL"}>Exploitation agricole à responsabilité limitée (EARL)</MenuItem>
                          <MenuItem value={"GAEC"}>Groupement agricole d'exploitation en commun (GAEC)</MenuItem>
                          <MenuItem value={"GFA"}>Groupement foncier agricole (GFA)</MenuItem>
                          <MenuItem value={"EIRL"}>Entrepreneur Individuel à Responsabilité Limité</MenuItem>
                          <MenuItem value={"SPFPLAS"}>Société de Participations Financières de Profession Libérale d'Avocats Simplifiée (SPFPLAS)</MenuItem>
                        </Select>
                      </FormControl>
                      
                    </div>
                  </div>
                  <div  className={"grid grid-cols-12 items-center mt-6"} >
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>
                      
                      <FormControl size="small" className='w-full'>
                        <OutlinedInput
                          id="outlined-adornment-weight"
                          endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                          aria-describedby="outlined-weight-helper-text"
                          inputProps={{
                            'aria-label': 'weight',
                          }}
                          name="capital_benefiser"
                          value={val.capital_benefiser} 
                          onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Benefiser")}}   
                          error={!val.capital_benefiser  && props.isSubmitted?true:false}
                          index={val.index}
                        />
                      </FormControl>

                      <StyledTooltip title="Montant du capital mentionné dans les statuts.">
                        <div className='p-0 m-0 w-auto'>
                          <BsFillQuestionCircleFill className='text-2xl fill-primary cursor-pointer hover:fill-secondary' />
                        </div>
                      </StyledTooltip>

                    </div>
                  </div>
                </div> 
                <div className={val.Personne_benefiser === "PERSONNE_PHYSIQUE"?"":"hidden"}>
                  <div className='grid grid-cols-12 items-start mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sélectionnez"
                          name="civilite_physique_benefiser"
                          value={val.civilite_physique_benefiser} 
                          onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Benefiser")}}   
                          error={!val.civilite_physique_benefiser  && props.isSubmitted?true:false}
                          index={val.index}
                          
                        >
                          <MenuItem value={"Mme"}>Madame</MenuItem>
                          <MenuItem value={"M."}>Monsieur</MenuItem>

                        </Select>
                      </FormControl>
                      
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="nom_physique_benefiser"
                        value={val.nom_physique_benefiser} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Benefiser")}}   
                        error={!val.nom_physique_benefiser  && props.isSubmitted?true:false}  
                        index={val.index}
                      />
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="prenom_physique_benefiser" 
                        value={val.prenom_physique_benefiser} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Benefiser")}}  
                        error={!val.prenom_physique_benefiser  && props.isSubmitted?true:false}
                        index={val.index}
                      />
                    </div>
                  </div>
                </div>
                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="adresse_benefiser" 
                      value={val.adresse_benefiser} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Benefiser")}}   
                      error={!val.adresse_benefiser  && props.isSubmitted?true:false}
                      index={val.index}
                    />
                  </div>
                </div>
                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Complément adresse</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="adresse_suite_benefiser"
                      value={val.adresse_suite_benefiser} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Benefiser")}}  
                      index={val.index}
                    />
                  </div>
                </div>
                <div className={val.Personne_benefiser === "PERSONNE_PHYSIQUE"?"grid grid-cols-12 mt-6 items-center":"grid-cols-12 mt-6 items-center hidden"} >
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Résidant à l’étranger</label>
                  
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <FormControl size="small" className='w-full'>
                      <InputLabel id="resident_etranger_physique_benefiser-label">Sélectionnez</InputLabel>
                      <Select
                        labelId="demo-resident_etranger_physique_benefiser-label"
                        id="resident_etranger_physique_benefiser"
                        label="resident_etranger_physique_benefiser"
                        name="resident_etranger_physique_benefiser"
                        value={`${val.resident_etranger_physique_benefiser}`}                         
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Benefiser")}}   
                        index={val.index}
                      >
                        <MenuItem value={"Non"} >Non</MenuItem>
                        <MenuItem value={"Oui"} >Oui</MenuItem>

                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className={val.resident_etranger_physique_benefiser==="Non"|| val.Personne_benefiser === "PERSONNE_MORALE"?'grid grid-cols-12 mt-6 items-center':'hidden grid-cols-12 mt-6 items-center'} >
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="code_postal_benefiser"
                      value={val.code_postal_benefiser} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Benefiser")}}  
                      error={!val.code_postal_benefiser  && props.isSubmitted?true:false}
                      index={val.index}
                    />
                  </div>
                </div>

                <div className='grid grid-cols-12 mt-6 items-center'>
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville *</label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    

                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className={val.Personne_benefiser === "PERSONNE_PHYSIQUE" && val.resident_etranger_physique_benefiser=="Oui"?'w-full':'hidden w-full'}
                      name="ville_benefiser"
                      value={val.ville_benefiser} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Benefiser")}}  
                      error={!val.ville_benefiser  && props.isSubmitted?true:false}
                      index={val.index}
                    />
                    <FormControl size="small" className={val.Personne_benefiser === "PERSONNE_PHYSIQUE" && val.resident_etranger_physique_benefiser=="Oui"?'hidden w-full':' w-full'} >
                      <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Sélectionnez"                       
                        name="ville_id_benefiser"
                        value={val.ville_id_benefiser} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Benefiser")}}  
                          error={!val.ville_benefiser  && props.isSubmitted?true:false}
                          index={val.index}
                      >
                        {
                          val.ville_benefiserArr.map((cityVal,cityIndex)=>{
                            return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                          })
                        }

                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className={val.Personne_benefiser === "PERSONNE_PHYSIQUE" && val.resident_etranger_physique_benefiser==="Oui"?'grid grid-cols-12 mt-6 items-center':'hidden grid-cols-12 mt-6 items-center'}>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Pays *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    className='w-full'
                    name="pays_benefiser"
                    value={val.pays_benefiser} 
                    onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Benefiser")}}  
                    error={!val.pays_benefiser  && props.isSubmitted?true:false}
                    index={val.index}
                  />
                </div>
              </div>
                <div className={val.Personne_benefiser === "PERSONNE_MORALE"?"grid grid-cols-12 mt-6 items-center":"grid-cols-12 mt-6 items-center hidden"} >
                  <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Greffe </label>
                  <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className='w-full'
                      name="greffe_benefiser"
                      value={val.greffe_benefiser} 
                      onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Benefiser")}}   
                      error={!val.greffe_benefiser  && props.isSubmitted?true:false}
                      index={val.index}
                    />
                  </div>
                </div>
                <div className={val.Personne_benefiser === "PERSONNE_MORALE"?"":"hidden"}>
                  <div className='px-0 group flex items-center gap-2 text-secondary mt-12 '>                   
                    <span className='text-base font-normal'>Représentée par</span>
                  </div>
                  <div className='grid grid-cols-12 items-start mt-6'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité </label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                      <FormControl size="small" className='w-full'>
                        <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label="Sélectionnez"
                          name="representation_civilite_benefiser"
                          value={val.representation_civilite_benefiser} 
                          onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Benefiser")}}                            
                          index={val.index}
                          
                        >
                          <MenuItem value={"Mme"}>Madame</MenuItem>
                          <MenuItem value={"M."}>Monsieur</MenuItem>

                        </Select>
                      </FormControl>
                      
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="representation_nom_benefiser" 
                        value={val.representation_nom_benefiser} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Benefiser")}}  
                        error={!val.representation_nom_benefiser  && props.isSubmitted?true:false}
                        index={val.index}
                      />
                    </div>
                  </div>

                  <div className='grid grid-cols-12 mt-6 items-center'>
                    <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom *</label>
                    <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                      <TextField
                        hiddenLabel
                        id="outlined-size-small"
                        size="small"
                        className='w-full'
                        name="representation_prenom_benefiser" 
                        value={val.representation_prenom_benefiser} 
                        onChange={(e)=>{props.handleChangeAddForm(e,val.index,"Benefiser")}}  
                        error={!val.representation_prenom_benefiser  && props.isSubmitted?true:false}
                        index={val.index}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <Divider className='mb-6 mt-6' variant="middle" />
            </div> 
          })}  
          {/* <div className='px-0 group flex items-center gap-2 text-primary hover:text-secondary mt-12 cursor-pointer ' onClick={(e)=> props.addForm(e,"lagrance")}>
            <BsPlusCircle className='text-3xl ' />
            <span className='text-base font-normal'>Ajouter un gérant</span>
          </div> */}        
      </div>        
      <div className={"mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6"} >
        <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Nombre de parts/actions</h2>
        <div className='grid grid-cols-12 items-center mt-6'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nombre de parts ou actions sociales *</label>
          {/* <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'> */}
          <div className='col-span-12 sm:col-span-9 md:col-span-8  inline-flex items-center gap-2'>

            <FormControl size="small" className='w-full'>
              <OutlinedInput
                id="outlined-adornment-weight"
                // endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  'aria-label': 'weight',
                }}
                name="nomber_departs"
                onChange={props.handleChange}  
                value={props.formData.nomber_departs}
                error={!props.formData.nomber_departs  && props.isSubmitted?true:false}
              />
            </FormControl>
          </div>
        </div>

        <div  className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Montant de la cession*</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
          <FormControl size="small" className='w-full'>
              <OutlinedInput
                id="outlined-adornment-weight"
                endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  'aria-label': 'weight',
                }}
                name="motantdelacession"
                onChange={props.handleChange}  
                value={props.formData.motantdelacession}
                error={!props.formData.motantdelacession  && props.isSubmitted?true:false}
              />
            </FormControl>
          </div>
        </div>
      </div>            
    </div>
    </>
  )
}
