import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import { MdCircle } from "react-icons/md";
export default function LesFormulaires() {
  useEffect(() => {
    localStorage.removeItem("fromAdmin");
    window.scrollTo(0, 0);
  }, []);

  const CommentData = [
    {
      heading: "Création de société ",
      LinkItem: [
        {
          name: "SARL",
          link: "/nos-formulaires/constitution-d’une-société-commerciale/sarl",
        },
        {
          name: "EURL",
          link: "/nos-formulaires/Constitution-d’une-société-commerciale/eurl",
        },
        {
          name: "SNC",
          link: "/nos-formulaires/Constitution-d’une-société-commerciale/snc",
        },
        {
          name: "SASU",
          link: "/nos-formulaires/Constitution-d’une-société-commerciale/sasu",
        },
        {
          name: "SAS",
          link: "/nos-formulaires/Constitution-d’une-société-commerciale/sas",
        },
        {
          name: "SA",
          link: "/nos-formulaires/Constitution-d’une-société-commerciale/sa",
        },
      ],
    },
    {
      heading: "Modification de société",
      LinkItem: [
        {
          name: "Modifications multiples",
          link: `/nos-formulaires/Modification-de-société/modifications-multiples`,
        },
        {
          name: `Modification d'objet social`,
          link: `/nos-formulaires/Modification-de-société/changement-objet-social`,
        },
        {
          name: "Avis de modification de capital",
          link: `/nos-formulaires/Modification-de-société/modification-capital-social`,
        },
        {
          name: "Transfert de siège social dans le département",
          link: `/nos-formulaires/Modification-de-société/transfert-siege-meme-departement`,
        },
        {
          name: "Transfert de siège social hors département (départ)",
          link: `/nos-formulaires/Modification-de-société/transfert-siege-hors-departement-depart`,
        },
      ],
    },
    {
      heading: "Autres",
      LinkItem: [
        {
          name: "Avis de dissolution sans liquidation TUP",
          link: `/nos-formulaires/Cessation-d’activité/dissolution-sans-liquidation`,
        },
        {
          name: "Avis de dissolution anticipée",
          link: `/nos-formulaires/Cessation-d’activité/dissolution-anticipee`,
        },
        {
          name: "Changement de dénomination",
          link: `/nos-formulaires/Modification-de-société/changement-denomination`,
        },
        {
          name: "Cession de parts sociales",
          link: `/nos-formulaires/Modification-de-société/cession-parts-sociales`,
        },
        {
          name: "Transformation d’une SARL en SAS",
          link: `/nos-formulaires/Modification-de-société/transformation-sarl-en-sas`,
        },
        {
          name: "Annonce rectificative",
          link: `/nos-formulaires/Additifs-rectificatifs/rectificatif`,
        },
      ],
    },
  ];
  return (
    <>
      <div className="md:max-w-[1477px] relative w-full h-full m-auto  py-14  px-6 sm:px-14 ">
        <div className="flex items-center justify-start gap-4 w-full m-auto">
          <svg
            width="72"
            height="72"
            className="w-14 sm:w-16 xl:w-auto"
            viewBox="0 0 72 72"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M52.9373 59.5C52.7165 61.253 51.9195 62.8942 50.6569 64.1569C49.1566 65.6571 47.1217 66.5 45 66.5H13.5C11.3783 66.5 9.34344 65.6571 7.84315 64.1569C6.34285 62.6566 5.5 60.6217 5.5 58.5V27C5.5 24.8783 6.34285 22.8434 7.84315 21.3431C9.10577 20.0805 10.747 19.2835 12.5 19.0627V21.5916C11.4131 21.7926 10.4035 22.3183 9.61091 23.1109C8.57946 24.1424 8 25.5413 8 27V58.5C8 59.9587 8.57946 61.3576 9.61091 62.3891C10.6424 63.4205 12.0413 64 13.5 64H45C46.4587 64 47.8576 63.4205 48.8891 62.3891C49.6817 61.5965 50.2074 60.5869 50.4084 59.5H52.9373Z"
              fill="#1CBE93"
              stroke="#1CBE93"
              strokeWidth="2"
            />
            <path
              d="M27 8C25.5413 8 24.1424 8.57946 23.1109 9.61091C22.0795 10.6424 21.5 12.0413 21.5 13.5V45C21.5 46.4587 22.0795 47.8576 23.1109 48.8891C24.1424 49.9205 25.5413 50.5 27 50.5H58.5C59.9587 50.5 61.3576 49.9205 62.3891 48.8891C63.4205 47.8576 64 46.4587 64 45V13.5C64 12.0413 63.4205 10.6424 62.3891 9.61091C61.3576 8.57946 59.9587 8 58.5 8H27ZM27 5.5H58.5C60.6217 5.5 62.6566 6.34285 64.1569 7.84315C65.6571 9.34344 66.5 11.3783 66.5 13.5V45C66.5 47.1217 65.6571 49.1566 64.1569 50.6569C62.6566 52.1571 60.6217 53 58.5 53H27C24.8783 53 22.8434 52.1571 21.3431 50.6569C19.8429 49.1566 19 47.1217 19 45V13.5C19 11.3783 19.8429 9.34344 21.3431 7.84315C22.8434 6.34285 24.8783 5.5 27 5.5Z"
              fill="#1CBE93"
              stroke="#1CBE93"
              strokeWidth="2"
            />
          </svg>
          <h2 className="text-2xl xl:text-3xl font-black text-primary">
            Les formulaires les + utilisés
          </h2>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 justify-center md:justify-between lg:grid-cols-3 lg:justify-center  gap-6 xl:gap-12    mt-12">
          {CommentData.map((items, index) => {
            return (
              <div
                className="lesFor max-w-[454px] m-auto w-full h-full sm:min-h-[415px] p-4"
                key={index}
              >
                <div className="px-4 py-3 flex items-center">
                  <h3 className="text-lg sm:text-xl font-bold text-primary ">
                    {items.heading}{" "}
                  </h3>
                </div>

                <ul className="px-4 py-2 sm:py-6 flex flex-col gap-6 h-max">
                  {items.LinkItem.map((sublist, i) => {
                    return (
                      <li key={i}>
                        <Link
                          to={sublist.link}
                          className="flex gap-3 items-baseline text-[15px] font-light text-primary hover:font-bold cursor-pointer transition-all duration-300"
                        >
                          <div
                            style={{
                              width: "15px",
                              height: "15px",
                              marginTop: "4px",
                            }}
                          >
                            <MdCircle
                              style={{
                                color: "#1CBE93",
                                width: "15px",
                                height: "15px",
                              }}
                            />
                          </div>
                          {sublist.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
