/* eslint-disable no-useless-computed-key */
import React, { useState,useEffect } from 'react'
import axios from 'axios';
import { Link,useNavigate,useLocation,useHistory ,useParams } from 'react-router-dom'
import truck from '../../assets/truck.svg'
import Facture from '../../assets/Facture.svg'

import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import CreateAddressPopup from '../../component/createAddressPopup';
import { userActions } from '../../_actions';
import ToastMessageComp from '../../component/ToastMessageComp';
import { isValidEmail,isNumericValue } from '../../_helpers';


export default function CompleteAddress(props) {
  
  const alert = useSelector(state => state.alert);
  const dispatch = useDispatch(); 
  let navigate = useNavigate();
  let { ads,adsname,id } = useParams();
  const currentUserGet = useSelector(state => state.authentication.user);
  const announceData = useSelector(state => state.announce.announce);
  const [currentUser, setCurrentUser] = useState(currentUserGet);
 
  const [addresopen, setAddressOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [addressData, setAddressData] = useState({
    "full_name":"",
    "phone_number":"",
    "address_title":"",
    "address":"",
    "address_additional":"",
    "postal_code":"",
    "city":"",
    "city_id":"",
    "user_id":currentUser && currentUser.data?currentUser.data.id:"",
    "announce_id":id
  });
  // console.log("userSelectedAddress",props.userSelectedAddress)
  useEffect(() => {
    window.scrollTo(0, 0)

    /* if(id && announceData && announceData.data && announceData.data.billing_address_json){
      let billing_address_json_parse  = JSON.parse(announceData.data.billing_address_json);
      
      props.setUserSelectedAddress(userSelectedAddress => ({ ...userSelectedAddress, ["billing_address_type"]:billing_address_json_parse.billing_address_type, ["announce_id"]: id ,["billing_address_denomination"]: billing_address_json_parse.billing_address_denomination ,["billing_address_civility"]: billing_address_json_parse.billing_address_civility ,["billing_address_nom"]: billing_address_json_parse.billing_address_nom ,["billing_address_prenom"]:billing_address_json_parse.billing_address_prenom ,["billing_address_address"]:billing_address_json_parse.billing_address_address ,["billing_address_code_postal"]: billing_address_json_parse.billing_address_code_postal,["billing_address_ville"]:billing_address_json_parse.billing_address_ville,["billing_address_ville_id"]: billing_address_json_parse.billing_address_ville_id,["billing_address_villeArr"]:billing_address_json_parse.billing_address_villeArr,["email"]:billing_address_json_parse.email,["telephone"]:billing_address_json_parse.telephone }));
    } */
    if(currentUser && currentUser.data){
      props.setUserSelectedAddress(userSelectedAddress => ({ ...userSelectedAddress, ["announce_id"]: id, ["billing_address_type"]: "account_address" ,["billing_address_denomination"]: currentUser && currentUser.data?currentUser.data.raisosociale:"" ,["billing_address_civility"]: currentUser && currentUser.data?currentUser.data.civil:"" ,["billing_address_nom"]: currentUser && currentUser.data?currentUser.data.firstname:"" ,["billing_address_prenom"]: currentUser && currentUser.data?currentUser.data.lastname:"" ,["billing_address_address"]: currentUser && currentUser.data?currentUser.data.address:"" ,["billing_address_code_postal"]: currentUser && currentUser.data?currentUser.data.postalcode:"",["billing_address_ville"]: currentUser && currentUser.data?currentUser.data.ville:"",["billing_address_ville_id"]: currentUser && currentUser.data?currentUser.data.ville_id:"",["billing_address_villeArr"]: currentUser && currentUser.data&& currentUser.data.villeArr?JSON.parse(currentUser.data.villeArr):[] }));
    }else{
      props.setUserSelectedAddress(userSelectedAddress => ({ ...userSelectedAddress, ["announce_id"]: id }));
    }

    
  }, [])
  
  useEffect(() => {

    if(currentUser && currentUser.billing_address_option && currentUser.billing_address_option.length>0 && (adsname === "additif" ||  adsname === "rectificatif" || adsname === "cession-droit-bail" ||  adsname === "apport-fond-commerce" ||  adsname === "cession-fond-commerce" ||  adsname === "fin-location-gerance"  ||  adsname === "location-gerance"  ||  adsname === "dissolution-sans-liquidation"  ||  adsname === "cloture-liquidation"  )){
      let filterAddressOption = currentUser.billing_address_option.filter((optionVal)=>optionVal.value !== "customer_address");  
      currentUser.billing_address_option= filterAddressOption;
    }
    
  }, [currentUser && currentUser.billing_address_option && currentUser.billing_address_option.length>0])
  
  const changeAddress=(e,addressType)=>{
    if(addressType === "billing_address_type"){
      if(e.target.value === "account_address"){
        props.setUserSelectedAddress(userSelectedAddress => ({ ...userSelectedAddress, [e.target.name]: e.target.value, ["announce_id"]: id ,["billing_address_denomination"]: currentUser && currentUser.data?currentUser.data.raisosociale:"" ,["billing_address_civility"]: currentUser && currentUser.data?currentUser.data.civil:"" ,["billing_address_nom"]: currentUser && currentUser.data?currentUser.data.firstname:"" ,["billing_address_prenom"]: currentUser && currentUser.data?currentUser.data.lastname:"" ,["billing_address_address"]: currentUser && currentUser.data?currentUser.data.address:"" ,["billing_address_code_postal"]: currentUser && currentUser.data?currentUser.data.postalcode:"",["billing_address_ville"]: currentUser && currentUser.data?currentUser.data.ville:"",["billing_address_ville_id"]: currentUser && currentUser.data?currentUser.data.ville_id:"",["billing_address_villeArr"]: currentUser && currentUser.data&& currentUser.data.villeArr?JSON.parse(currentUser.data.villeArr):[] }));
  
      }else if(e.target.value === "customer_address"){
       /*  console.log("announceData",announceData)
        console.log("announceData.data.votre_annonce",JSON.parse(announceData.data.votre_annonce)) */
        let votre_annonce_parse  = JSON.parse(announceData.data.votre_annonce);
        let billing_address_address  = votre_annonce_parse.adresse?votre_annonce_parse.adresse:votre_annonce_parse.address_societe + ", "+ (votre_annonce_parse.adresse_suite?votre_annonce_parse.adresse_suite:(votre_annonce_parse.adresse_suite?votre_annonce_parse.address_suite_societe:"")) ;
        

        props.setUserSelectedAddress(userSelectedAddress => ({ ...userSelectedAddress, [e.target.name]: e.target.value, ["announce_id"]: id ,["billing_address_denomination"]: votre_annonce_parse.denomination?votre_annonce_parse.denomination:(votre_annonce_parse.denomination_societe?votre_annonce_parse.denomination_societe:"") ,["billing_address_civility"]: "" ,["billing_address_nom"]: "" ,["billing_address_prenom"]: "" ,["billing_address_address"]:billing_address_address ,["billing_address_code_postal"]: votre_annonce_parse.code_postal,["billing_address_ville"]:votre_annonce_parse.ville,["billing_address_ville_id"]: votre_annonce_parse.ville_id,["billing_address_villeArr"]:votre_annonce_parse.villeArr }));
  
      }else if(e.target.value === "another_address"){
        // console.log("announceData",announceData)
        if(id && announceData && announceData.data && announceData.data.billing_address_json){
          let billing_address_json_parse  = JSON.parse(announceData.data.billing_address_json);
          
          props.setUserSelectedAddress(userSelectedAddress => ({ ...userSelectedAddress, [e.target.name]:e.target.value, ["announce_id"]: id ,["billing_address_denomination"]: billing_address_json_parse.billing_address_denomination ,["billing_address_civility"]: billing_address_json_parse.billing_address_civility ,["billing_address_nom"]: billing_address_json_parse.billing_address_nom ,["billing_address_prenom"]:billing_address_json_parse.billing_address_prenom ,["billing_address_address"]:billing_address_json_parse.billing_address_address ,["billing_address_code_postal"]: billing_address_json_parse.billing_address_code_postal,["billing_address_ville"]:billing_address_json_parse.billing_address_ville,["billing_address_ville_id"]: billing_address_json_parse.billing_address_ville_id,["billing_address_villeArr"]:billing_address_json_parse.billing_address_villeArr,["email"]:billing_address_json_parse.email,["telephone"]:billing_address_json_parse.telephone }));

        }else{
          
          props.setUserSelectedAddress(userSelectedAddress => ({ ...userSelectedAddress, [e.target.name]: e.target.value, ["announce_id"]: id ,["billing_address_denomination"]: "" ,["billing_address_civility"]: "" ,["billing_address_nom"]: "" ,["billing_address_prenom"]: "" ,["billing_address_address"]: "" ,["billing_address_code_postal"]: "",["billing_address_ville"]: "",["billing_address_ville_id"]: "",["billing_address_villeArr"]: [] }));
        }
      }
    }else{
      
      if(e.target.name ===  "billing_address_ville_id"){
        let fillerCity = props.userSelectedAddress.billing_address_villeArr.filter((cityVal)=>cityVal.id===e.target.value);
        // console.log("fillerCity",fillerCity)
        if(fillerCity.length>0){
          props.setUserSelectedAddress(userSelectedAddress => ({ ...userSelectedAddress,  'billing_address_ville_id':e.target.value, "billing_address_ville": fillerCity[0].city, ["announce_id"]: id }));
        }       

      }else{
        props.setUserSelectedAddress(userSelectedAddress => ({ ...userSelectedAddress, [e.target.name]: e.target.value, ["announce_id"]: id }));

      }
      
      if(e.target.name === "billing_address_code_postal"){
        const getData = setTimeout(async() => {  
          props.setUserSelectedAddress(userSelectedAddress => ({ ...userSelectedAddress, 'billing_address_ville_id': "", "billing_address_ville": "", 'billing_address_villeArr': [] })); 
          if(e.target.value.length>0){
            const response = await axios.get(`${process.env.REACT_APP_API_URL}api/checkzipcode/${e.target.value}`);
           
            if( response.data &&  response.data.data){           
              props.setUserSelectedAddress(userSelectedAddress => ({ ...userSelectedAddress, "billing_address_ville": response.data.data.city, "billing_address_villeArr": response.data.data.cityArr, "billing_address_ville_id": response.data.data.id  })); 
            }
          }      
        }, 1000)
        return () => clearTimeout(getData);
      }
    }
    
    /* // let filteraddress = currentUser.user_address.filter((address)=>address.id===e.target.value); 
    if(filteraddress.length>0){
      props.setUserSelectedAddress(userSelectedAddress => ({ ...userSelectedAddress, [addressType]: e.target.value, ["announce_id"]: id }));
    }  */  
    
    
  }
  const handleClickOpen = () => {
    setAddressOpen(true);
    setAddressData({
      "full_name":"",
      "phone_number":"",
      "address_title":"",
      "address":"",
      "address_additional":"",
      "postal_code":"",
      "city":"",
      "city_id":"",
      "user_id":currentUser && currentUser.data?currentUser.data.id:"",
      "announce_id":id
    })
  };
  const handleClose = () => {
    setAddressOpen(false);
  };
  const handleChangeAddress = (e) => {     
    const {name, value}=e.target;  
    

    if(name === "postal_code"){
      setAddressData(addressData => ({ ...addressData, "postal_code": value})); 
      const getData = setTimeout(async() => {        
        setAddressData(addressData => ({ ...addressData, "city": "", "city_id": ""  }));  
        if(value.length>0){
          const response = await axios.get(`${process.env.REACT_APP_API_URL}api/checkzipcode/${value}`);
         
          if( response.data &&  response.data.data){           
            setAddressData(addressData => ({ ...addressData, "city": response.data.data.city, "city_id": response.data.data.id  })); 
          }
        }      
      }, 1000)
      return () => clearTimeout(getData);
    }else{
      setAddressData(addresData => ({ ...addresData, [name]: value }));
    }
    
  };
  const handleSubmitAddress = (e) => {     
    e.preventDefault();
      setSuccessful(false);
      setIsSubmitted(true);
      if (addressData.full_name && addressData.phone_number && addressData.address_title && addressData.address && addressData.postal_code && addressData.city ) {
        dispatch(userActions.addAddress(addressData)).then((user_address) => {
          setSuccessful(true); 
          setCurrentUser(currentUser => ({ ...currentUser, "user_address": user_address.user_address  }));
          if(user_address.sucess === true){               
            setAddressOpen(false); 
          }else{
            setAddressOpen(true);   
          }
          
         /*  setTimeout(() => {
            navigate("/login");            
          }, 2000); */
        })
        .catch(() => {
          setSuccessful(true);         
        });
      }
  };
  return (
    <>
      {addresopen?
      <CreateAddressPopup handleClose={handleClose} handleClickOpen={handleClickOpen} handleSubmitAddress={handleSubmitAddress} addresopen={addresopen} addressData={addressData} handleChangeAddress={handleChangeAddress} isSubmitted={isSubmitted} />
      :""}
       {successful?
      <ToastMessageComp  message={alert.message} type={alert.type} />
      :""}
      <div>
        <div className='py-6 px-3 sm:px-6' >
          <h1 className='text-lg sm:text-2xl font-black text-primary'>Adresse {/* de livraison et */} de facturation</h1>
        </div>
        <div className='sm:my-6  grid sm:grid-cols-1 gap-6 sm:gap-12 py-0 sm:py-6 px-3 sm:px-6'>
          {/* <div className='border border-[#DEE3E2] rounded-[10px] p-5'>
            <div className='flex gap-2 items-center'>
              <img src={truck} alt="truck" />
              <h3 className='text-base font-bold text-primary'>Livraison</h3>
            </div>
            <div className=' mt-6 mb-4 block'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Sélectionnez"
                  name='delivery_address'
                  value={props.userSelectedAddress.delivery_address?props.userSelectedAddress.delivery_address.id:""}
                  onChange={(e)=>changeAddress(e,"delivery_address")}
                  error={!props.userSelectedAddress.delivery_address?true:false}
                >
                  {                    
                    currentUser && currentUser.user_address?
                      currentUser.user_address.map((val,key)=>{
                        return <MenuItem value={val.id}>{val.address_title}</MenuItem>;
                      })
                    :""
                  }
                  

                </Select>
              </FormControl>
            </div>
            {
            props.userSelectedAddress.delivery_address ?              
              <>
              <p className='text-base font-normal text-gray '>{props.userSelectedAddress.delivery_address.address_title}</p>
              <p className='text-base font-normal text-gray '>{props.userSelectedAddress.delivery_address.full_name} </p>
              <p className='text-base font-normal text-gray '>{props.userSelectedAddress.delivery_address.phone_number} </p>
              <p className='text-base font-normal text-gray '>{props.userSelectedAddress.delivery_address.address} </p>
              <p className='text-base font-normal text-gray '>{props.userSelectedAddress.delivery_address.postal_code} {props.userSelectedAddress.delivery_address.city} </p>
              <h6 className='mt-5 text-gray text-base font-normal uppercase'>MODIFIER MON ADRESSE</h6>
              </>
            :""}
          </div> */}
          <div className='border border-[#DEE3E2] rounded-[10px] p-5'>
            <div className='flex gap-2 items-center'>
              <img src={Facture} alt="Facture" />
              <h3 className='text-base font-bold text-primary'>Facturation</h3>
            </div>
            <div className=' mt-6 mb-4 block'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Sélectionnez"
                  name="billing_address_type"
                  value={props.userSelectedAddress.billing_address_type?props.userSelectedAddress.billing_address_type:""}
                  onChange={(e)=>changeAddress(e,"billing_address_type")}
                  error={!props.userSelectedAddress.billing_address_type?true:false} 
                >
                   {/* {                    
                    currentUser && currentUser.user_address?
                      currentUser.user_address.map((val,key)=>{
                        return <MenuItem value={val.id}>{val.address_title}</MenuItem>;
                      })
                    :""
                  }  */} 

                  {                    
                    currentUser && currentUser.billing_address_option?
                      currentUser.billing_address_option.map((val,key)=>{
                        return <MenuItem value={val.value}>{val.name}</MenuItem>;
                      })
                    :""
                  }                 

                </Select>
              </FormControl>
            </div>
            {              
              // props.userSelectedAddress.shipping_address ?              
              <>
            <div className='py-0 sm:py-0 px-0 sm:px-0 '>
              
              <div className='grid grid-cols-12 items-center mt-3'>
                <label className='col-span-12 sm:col-span-2 text-sm font-normal text-gray'>Dénomination</label>
                <div className='col-span-12 sm:col-span-7 inline-flex items-center gap-2'>
                  {/* {props.userSelectedAddress.billing_address_type ==="customer_address" ? props.userSelectedAddress.billing_address_denomination:" "} */}
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                   // className={(props.userSelectedAddress.billing_address_type==="account_address" || props.userSelectedAddress.billing_address_type==="another_address")?' outline-secondary focus:outline-secondary mb-3 ml-2 w-full':"hidden"}
                   className='outline-secondary focus:outline-secondary mb-3 ml-2 w-full'
                    name='billing_address_denomination'
                    value={props.userSelectedAddress.billing_address_denomination}
                    onChange={(e)=>changeAddress(e,"billing_address_denomination")}
                    // error={!props.userSelectedAddress.billing_address_denomination?true:false} 
                  />
                </div>
              </div>
              {
                props.userSelectedAddress.billing_address_type ==="account_address" || props.userSelectedAddress.billing_address_type==="another_address" ?
                <>
                <div className='grid grid-cols-12 items-center mt-3'>
                  <label className='col-span-12 sm:col-span-2 text-sm font-normal text-gray'>Civilité</label>
                  <div className='col-span-12 sm:col-span-7 inline-flex items-center gap-2'>
                    {props.userSelectedAddress.billing_address_type==="customer_address" ?props.userSelectedAddress.billing_address_civility:""}
                    {/* <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className={(props.userSelectedAddress.billing_address_type==="account_address" || props.userSelectedAddress.billing_address_type==="another_address")?' outline-secondary focus:outline-secondary mb-3 ml-2 w-full':"hidden"}
                      name='billing_address_civility'
                      value={props.userSelectedAddress.billing_address_civility}
                      onChange={(e)=>changeAddress(e,"billing_address_civility")}
                    /> */}
                    <Select
                        labelId="Civilite"
                        label="Civilité"
                        name="billing_address_civility"
                        className={(props.userSelectedAddress.billing_address_type==="account_address" || props.userSelectedAddress.billing_address_type==="another_address")?' outline-secondary focus:outline-secondary mb-3 ml-2 w-full':"hidden"}
                        fullWidth
                        value={props.userSelectedAddress.billing_address_civility}
                        onChange={(e)=>changeAddress(e,"billing_address_civility")}
                      >
                      <MenuItem value={"M"}>M</MenuItem>
                      <MenuItem value={"MME"}>MME</MenuItem>
                    </Select>
                  </div>
                </div>
                <div className='grid grid-cols-12 items-center mt-3'>
                  <label className='col-span-12 sm:col-span-2 text-sm font-normal text-gray'>Nom</label>
                  <div className='col-span-12 sm:col-span-7 inline-flex items-center gap-2'>
                    {props.userSelectedAddress.billing_address_type==="customer_address" ?props.userSelectedAddress.billing_address_nom:""}
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className={(props.userSelectedAddress.billing_address_type==="account_address" || props.userSelectedAddress.billing_address_type==="another_address")?' outline-secondary focus:outline-secondary mb-3 ml-2 w-full':"hidden"}
                      name='billing_address_nom'
                      value={props.userSelectedAddress.billing_address_nom}
                      onChange={(e)=>changeAddress(e,"billing_address_nom")}
                     // error={!props.userSelectedAddress.billing_address_nom?true:false} 
                    />
                  </div>
                </div>
                <div className='grid grid-cols-12 items-center mt-3'>
                  <label className='col-span-12 sm:col-span-2 text-sm font-normal text-gray'>Prénom</label>
                  <div className='col-span-12 sm:col-span-7 inline-flex items-center gap-2'>
                    {props.userSelectedAddress.billing_address_type==="customer_address" ?props.userSelectedAddress.billing_address_prenom:""} 
                    <TextField
                      hiddenLabel
                      id="outlined-size-small"
                      size="small"
                      className={(props.userSelectedAddress.billing_address_type==="account_address" || props.userSelectedAddress.billing_address_type==="another_address")?' outline-secondary focus:outline-secondary mb-3 ml-2 w-full':"hidden"}
                      name='billing_address_prenom'
                      value={props.userSelectedAddress.billing_address_prenom}
                      onChange={(e)=>changeAddress(e,"billing_address_prenom")}
                      // error={!props.userSelectedAddress.billing_address_prenom?true:false} 
                    />
                  </div>
                </div>
                </>
                :""}
              <div className='grid grid-cols-12 items-center mt-3'>
                <label className='col-span-12 sm:col-span-2 text-sm font-normal text-gray'>Adresse</label>
                <div className='col-span-12 sm:col-span-7 inline-flex items-center gap-2'>
                  {/* { props.userSelectedAddress.billing_address_type==="customer_address" ? props.userSelectedAddress.billing_address_address:" "}  */}
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    // className={(props.userSelectedAddress.billing_address_type==="account_address" || props.userSelectedAddress.billing_address_type==="another_address")?' outline-secondary focus:outline-secondary mb-3 ml-2 w-full':"hidden"}
                    className='outline-secondary focus:outline-secondary mb-3 ml-2 w-full'
                    name='billing_address_address'
                    value={props.userSelectedAddress.billing_address_address}
                    onChange={(e)=>changeAddress(e,"billing_address_address")}
                    error={!props.userSelectedAddress.billing_address_address?true:false} 
                  />
                </div>
              </div>
              <div className='grid grid-cols-12 items-center mt-3'>
                <label className='col-span-12 sm:col-span-2 text-sm font-normal text-gray'>Code postal</label>
                <div className='col-span-12 sm:col-span-7 inline-flex items-center gap-2'>
                  {/* {props.userSelectedAddress.billing_address_type==="customer_address" ?props.userSelectedAddress.billing_address_code_postal:""} */}
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    //className={(props.userSelectedAddress.billing_address_type==="account_address" || props.userSelectedAddress.billing_address_type==="another_address")?' outline-secondary focus:outline-secondary mb-3 ml-2 w-full':"hidden"}
                    className='outline-secondary focus:outline-secondary mb-3 ml-2 w-full'
                    name='billing_address_code_postal'
                    value={props.userSelectedAddress.billing_address_code_postal}
                    onChange={(e)=>changeAddress(e,"billing_address_code_postal")}
                    error={!props.userSelectedAddress.billing_address_code_postal?true:false} 
                  />
                </div>
              </div>
              <div className='grid grid-cols-12 items-center mt-3'>
                <label className='col-span-12 sm:col-span-2 text-sm font-normal text-gray'>Ville</label>
                <div className='col-span-12 sm:col-span-7 inline-flex items-center gap-2'>
                  {/* {props.userSelectedAddress.billing_address_type==="customer_address" ?props.userSelectedAddress.billing_address_ville:""}  */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Sélectionnez"
                   // className={(props.userSelectedAddress.billing_address_type==="account_address" || props.userSelectedAddress.billing_address_type==="another_address")?' mb-3 ml-2 w-full':"hidden"}
                    className=' mb-3 ml-2 w-full'
                    name="billing_address_ville_id"
                    value={props.userSelectedAddress.billing_address_ville_id?props.userSelectedAddress.billing_address_ville_id:""}
                    onChange={(e)=>changeAddress(e,"billing_address_ville_id")}
                    error={!props.userSelectedAddress.billing_address_ville_id?true:false} 
                  >
                    
                    {                    
                      props.userSelectedAddress.billing_address_villeArr?
                      props.userSelectedAddress.billing_address_villeArr.map((val,key)=>{
                          return <MenuItem value={val.id}>{val.city}</MenuItem>;
                        })
                      :""
                    }                 

                  </Select>
                </div>
              </div>
            </div>
            </>
            /* :"" */}
            
          </div>
        </div>
        {/* <div className='px-5 mt-2 '>
          <button onClick={handleClickOpen} className='text-center text-base font-normal outline-thColor max-w-[250px] text-thColor transition-all duration-300 border border-thColor w-full py-2 rounded-md px-4
          hover:bg-secondary hover:text-white hover:border-secondary'>
            Ajouter une adresse
          </button>
        </div> */}

      </div>
      <div className='mt-8 sm:mt-0 '>
        <div className='sm:py-6 px-3 sm:px-6' >
          <h1 className='text-lg font-bold text-primary pb-4'>Vérifiez vos informations personnelles</h1>
          <p className='text-[15px] font-medium text-gray'>Pourquoi renseigner un numéro de téléphone et un e-mail valide ?</p>
          <p className='max-w-[804px] text-[15px] font-normal text-gray'>Une adresse e-mail valide est indispensable pour vous envoyer votre attestation au format numérique et pour le suivi de votre dossier. Nos formalistes effectuent une relecture de vos informations et vous contacte par téléphone en cas d’erreur.</p>
          <p className='text-[15px] font-medium text-gray my-3'>Nous ne divulguerons jamais vos informations.</p>
        </div>

        <div className='py-0 sm:py-6 px-3 sm:px-6  '>
          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-2 text-sm font-normal text-gray'>Email</label>
            <div className='col-span-12 sm:col-span-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full outline-secondary focus:outline-secondary'
                name='email'
                value={props.userSelectedAddress.email}
                onChange={(e)=>props.setUserSelectedAddress(userSelectedAddress => ({ ...userSelectedAddress, ["email"]: e.target.value }))}
                error={(!props.userSelectedAddress.email) || (!isValidEmail(props.userSelectedAddress.email))?true:false} 
              />
            </div>
          </div>
          <div className='grid grid-cols-12 items-center mt-6'>
            <label className='col-span-12 sm:col-span-2 text-sm font-normal text-gray'>Téléphone</label>
            <div className='col-span-12 sm:col-span-7 inline-flex items-center gap-2'>
              <TextField
                hiddenLabel
                id="outlined-size-small"
                size="small"
                className='w-full outline-secondary focus:outline-secondary'
                name='userTelephone'
                value={props.userSelectedAddress.telephone !=="null"?props.userSelectedAddress.telephone:""}
                onChange={(e)=>props.setUserSelectedAddress(userSelectedAddress => ({ ...userSelectedAddress, ["telephone"]: e.target.value }))}  
                error={(!props.userSelectedAddress.telephone) || (!isNumericValue(props.userSelectedAddress.telephone))?true:false}           
              />
            </div>
          </div>
          {/* <div className='flex justify-end px-5 mt-3'>
            <button className='mt-5 text-center text-lg 3xl:text-xl font-extrabold outline-thColor text-white transition-all duration-300 border border-thColor w-full py-2 rounded-md px-4 max-w-[180px] bg-thColor
          hover:bg-secondary hover:text-white hover:border-secondary'>
              Etape suivante
            </button>
          </div> */}
        </div>

      </div>
    </>
  )
}
