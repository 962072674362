/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-computed-key */
import React, { useState,useEffect,useRef } from 'react'
import { Link,useNavigate,useLocation,useHistory ,useParams,useSearchParams,Navigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import { getDetailsBySiren } from '../../../_helpers';
import moment from 'moment'
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel'; 
import Typography from '@mui/material/Typography';
import Breadcrumb from '../../../component/Breadcrumb'
import Express from '../FormComponent/Express';
import Procedures from '../FormComponent/Procedures';
import PaymentSideBlock from '../FormComponent/PaymentSideBlock';
import { Seleurl} from '../adsFormComp';
import FormSummary from '../FormSummary';
import CompleteAddress from '../CompleteAddress';
import PaymentForm from '../PaymentForm'
import PaymentSecond from '../PaymentSecond'
import Footer from '../../../component/Footer';
import FormSuccess from '../FormSuccess';
import axios from 'axios';
import { announceActions,alertActions } from '../../../_actions';
import ToastMessageComp from '../../../component/ToastMessageComp';
import { announceConstants } from '../../../_constants';
import PageLoaderComp from '../../../component/pageLoaderComp';

import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from '@stripe/stripe-js';
import { isValidEmail,isNumericValue } from '../../../_helpers';
import { Ads_fullname } from '../../../_helpers/ads_fullname';

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`, {
  locale: 'fr' // Set the locale to French
});

export function SeleurlStepperForm() {
 
  const appearance = {
    theme: 'stripe',
  };
 
  const [loading, setLoading] = useState(false);
  const [loadingFullpage, setLoadingFullpage] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [options, setOptions] = useState( {
    clientSecret,
    appearance,
  });
  const [queryParameters] = useSearchParams()
  const childRef = useRef();
  const announceIdRef = useRef("");
  const steps = ['Remplir le formulaire', 'Visualiser le récapitulatif', 'Adresse de facturation', 'Procéder au paiement', 'succès'];
  const alert = useSelector(state => state.alert);
  const dispatch = useDispatch(); 
  const location = useLocation(); 
  let navigate = useNavigate();
  let { ads,adsname,id } = useParams();
  
  const currentUser = useSelector(state => state.authentication.user);
  const announceData = useSelector(state => state.announce.announce);
  const [isCheckedSerenity,setIscheckedSerenity]=useState(false)
  const [is_general_conditions_sale,setis_general_conditions_sale]=useState(false)
  const [general_conditions_sale_msg,setgeneral_conditions_sale_msg]=useState("")
  const [announceDetails,setAnnounceDetails]=useState("")
  const [AdsFullName,setAdsFullName]=useState("")
  const [La_gerance, setLa_gerance] = useState([{
    user_id:currentUser&&currentUser.data?currentUser.data.id:"",
    index:0,
    type_personne_lagrance:"",
    siren_lagrance:"",
    denomination_lagrance:"",
    forme_juridiquelagrance:"",
    capital_lagrance:"",
    civilite_lagrance:"",
    nomlagrance:"",
    prenomlagrance:"",
    adresselagrance:"",
    adresse_suitelagrance:"",
    resident_etrangerlagrancephysic:"Non",
    code_postallagrance:"",
    villelagrance:"",
    villelagranceArr:[],
    ville_id_lagrance:"",
    greffelagrance:"",
    payslagrance:"",
  }]);
  const [formData,setFormData]= useState({
    "announce_id":id?id:"",title:(ads.split("-")).join(" "),add_type_full:AdsFullName, add_type:adsname?adsname:"",form_type:adsname?adsname:"",type_acte:"",nom_famille:"",officiant_to:"",date_acte:"",denomination:"",sigle:"",enseigne_commerciale:"",forme_juridique:"",objet:"",capital:"",capital_initial:"",capital_min:"",capital_max:"",duree:"",adresse:"",adresse_suite:"",code_postal:"",ville:"",villeArr:[],ville_id:"",greffe:"",La_gerance:La_gerance });

  const [isSubmitted, setSubmitted] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [successful, setSuccessful] = useState(false);
  const [userSelectedAddress, setUserSelectedAddress] = useState({
    "announce_id":id,
    "user_id":currentUser && currentUser.data?currentUser.data.id:"",
    "delivery_address":currentUser && currentUser.user_address?currentUser.user_address[0]:"",
    "shipping_address":currentUser && currentUser.user_address?currentUser.user_address[0]:"",
    "email":currentUser && currentUser.data?currentUser.data.email:"",
    "telephone":currentUser && currentUser.data?currentUser.data.telephone:"",
    "billing_address_type":"account_address",
    "billing_address_denomination":currentUser && currentUser.data?currentUser.data.raisosociale:"",
    "billing_address_civility":currentUser && currentUser.data?currentUser.data.civil:"",
    "billing_address_nom":currentUser && currentUser.data?currentUser.data.firstname:"",
    "billing_address_prenom":currentUser && currentUser.data?currentUser.data.lastname:"",
    "billing_address_address":currentUser && currentUser.data?currentUser.data.adresse:"",
    "billing_address_code_postal":currentUser && currentUser.data?currentUser.data.postalcode:"",
    "billing_address_ville":currentUser && currentUser.data?currentUser.data.ville:"",
    "billing_address_ville_id":currentUser && currentUser.data?currentUser.data.ville_id:"",
    "billing_address_villeArr":currentUser && currentUser.data&& currentUser.data.villeArr?JSON.parse(currentUser.data.villeArr):[],
  });
  
  const [fromAdmin,setFromAdmin]=useState(localStorage.getItem("fromAdmin"));
  const [payamentPrice,sePayamentPrice]=useState(0);
  const [paymentData,setPaymentData]=useState("");
 
  useEffect(() => {
    window.scrollTo(0, 0)
    setAdsFullName(Ads_fullname(adsname));
    if(id){
      let announce = {
        "data":[],
        "announce_id":id 
      }
      dispatch({ type: announceConstants.CREATE_SUCCESS, announce});
    }
    if(localStorage.getItem("activeStep")){
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
      setActiveStep(parseInt(localStorage.getItem("activeStep")))
      // setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped); 
    }
    if(!queryParameters.get("activstep") && id){
      // setActiveStep(1);
      getAnnounceDetails(id);
    }
    
  }, [])
 
  useEffect(() => { 
    if(announceDetails){
      let announceDetailsGet = announceDetails.data;
      let La_geranceGet =announceDetailsGet.votre_annonce_json.La_gerance || [];
      if(La_geranceGet.length>=0){
        La_geranceGet.map((val,key)=>{
          val.user_id=currentUser&&currentUser.data?currentUser.data.id:"";
          return val;
        })
      }
      announceDetailsGet.votre_annonce_json.La_gerance=La_geranceGet;
      announceDetailsGet.votre_annonce_json.announce_id=id?id:"";
      announceDetailsGet.votre_annonce_json.title=ads?(ads.split("-")).join(" "):"";
      setLa_gerance(La_geranceGet);
      setFormData(announceDetailsGet.votre_annonce_json);
    }
  }, [announceDetails])

  useEffect(() => {   
    // // console.log("activeStep",activeStep)
    // // console.log("payamentPrice",payamentPrice)
    if(activeStep ===3 && payamentPrice > 0){
      fetch(process.env.REACT_APP_API_URL+`api/create-payment-intent`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ items: [],announce_id: id,price:payamentPrice/* ,paymentData:paymentData */}),
      })
        .then((res) => res.json())
        .then((data) => {
          // // console.log("data",data)

          setClientSecret(data.clientSecret);
          let clientSecret = data.clientSecret
          setOptions({clientSecret,appearance})
        });
    }    
    
  }, [activeStep,payamentPrice])

  useEffect(() => {  
    if(paymentData && paymentData.data){
      sePayamentPrice(parseFloat(parseFloat(paymentData.data.total)+(isCheckedSerenity?118.80:0)).toFixed(2))
    }
    
  }, [isCheckedSerenity,paymentData])

  useEffect(() => {  
    if(queryParameters.get("activstep")  && id && ( (queryParameters.get("payment_intent") && queryParameters.get("payment_intent_client_secret")) || (currentUser && currentUser.UserProDetails && currentUser.UserProDetails.details && currentUser.UserProDetails.details.payment_plan !== null && currentUser.UserProDetails.details.payment_plan.includes("monthly")) )){
      const getDetails = async () => {
        const details_response = await getAnnounceDetails(id);
        if(details_response.success){
          if(parseInt(details_response.data.data.status) === 1 || parseInt(details_response.data.data.status) === 2){
            let data = {
              "announce_id":id,
              "payment_order_id":moment().format("DDMMY")+"_"+id.padStart(8, '0'),
              "invoice_number":moment().format("DDMMY")+"_"+id.padStart(8, '0'),
              "invoice_date":moment().format("DD-MM-Y"),
              "date_payment":moment().format("DD-MM-Y"),
              "payment_intent":queryParameters.get("payment_intent"),
              "payment_price":details_response.data.data.total,
              "payment_intent_client_secret":queryParameters.get("payment_intent_client_secret"),
            }
            const update_response = await updateAnnouncePayment(data);
            
          }
        }
      };    
      getDetails();
    }
    
  }, [queryParameters.get("activstep"),queryParameters.get("payment_intent"),queryParameters.get("payment_intent_client_secret")])

  useEffect(() => {
    const getData = setTimeout(async() => {
      setFormData(formData => ({ ...formData, ["ville"]: "",["ville_id"]: "",["villeArr"]:[] ,["greffe"]: ""  }));  
      if(formData.code_postal.length>0){
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/checkzipcode/${formData.code_postal}`);        
        if( response.data &&  response.data.data){
          setFormData(formData => ({ ...formData, ["ville"]: response.data.data.city, ["villeArr"]: response.data.data.cityArr,["ville_id"]: response.data.data.id,["greffe"]: response.data.data.area }));
        }
      }      
    }, 1000)
    return () => clearTimeout(getData);
  }, [formData.code_postal])
  console.log("formData",formData)
  function removeForm(index){
    let values = [...La_gerance];
    values.splice(index, 1);
    setLa_gerance(values);
    setFormData(formData => ({ ...formData, ["La_gerance"]: values }));
  }
  function addForm(e,type="lagrance") {
    if(type=== "lagrance"){
      let values = [...La_gerance];
      values.push({
        index:values.length,
        type_personne_lagrance:"",
        siren_lagrance:"",
        denomination_lagrance:"",
        forme_juridiquelagrance:"",
        capital_lagrance:"",
        civilite_lagrance:"",
        nomlagrance:"",
        prenomlagrance:"",
        adresselagrance:"",
        adresse_suitelagrance:"",
        resident_etrangerlagrancephysic:"Non",
        code_postallagrance:"",
        villelagrance:"",
        villelagranceArr:[],
        ville_id_lagrance:"",
        greffelagrance:"",
        payslagrance:"",
      });
      setLa_gerance(values);
      setFormData(formData => ({ ...formData, ["La_gerance"]: values }));
    }
    
    
  }
  async function handleChangeAddForm(e,index,type="lagrance") {
    let { name, value } = e.target;
    if(type=== "lagrance"){
      if(name ==="code_postallagrance"){
        await getCityFromPostal(name,value,index);
      }
      if(name === "siren_lagrance"){
        await getSirenDetailsBySiren(name,value,index,type);
        
      }
      let values = [...La_gerance];

      if(name == "ville_id_lagrance"){
        console.log("name",name)
        let cityArrFilter = values[index].villelagranceArr.filter((val,i)=>val.id==value);
        console.log("cityArrFilter",cityArrFilter);
        values[index]["villelagrance"] = cityArrFilter[0].city;
        values[index]["ville_id_lagrance"] = value;
        setLa_gerance(values);
        setFormData(formData => ({ ...formData, ["La_gerance"]: values }));

      }else{
        values[index][name] = value;
        setLa_gerance(values);
        setFormData(formData => ({ ...formData, ["La_gerance"]: values }));
      }
      
    }    
    
  }
  async function getSirenDetailsBySiren(name,value,index,type="lagrance"){
    const getSiren = setTimeout(async() => {
      if(type=== "lagrance"){
        let values = [...La_gerance];
        values[index]["denomination_lagrance"] = "";
        values[index]["forme_juridiquelagrance"] = "";
        values[index]["capital_lagrance"] = "";
        values[index]["adresselagrance"] = "";
        values[index]["code_postallagrance"] = "";
        values[index]["villelagrance"] = "";
        values[index]["villelagranceArr"] = [];
        values[index]["ville_id_lagrance"] = "";
        values[index]["greffelagrance"] = "";
        setLa_gerance(values);
        setFormData(formData => ({ ...formData, ["La_gerance"]: values }));     
        if(value.length>0){
          let getDetails = await getDetailsBySiren(value);
          // values[index]["villelagrance"] = getDetails.siren;
          values[index]["denomination_lagrance"] = getDetails.denomination;
          values[index]["forme_juridiquelagrance"] = getDetails.forme_juridique;
          values[index]["capital_lagrance"] = getDetails.capital_social;
          values[index]["adresselagrance"] = getDetails.adresse_rcs;
          values[index]["code_postallagrance"] = getDetails.code_postal;
          values[index]["villelagrance"] = getDetails.ville;
          values[index]["ville_id_lagrance"] =getDetails.ville_id;
          values[index]["villelagranceArr"] = getDetails.cityArr;
          values[index]["greffelagrance"] = getDetails.area;
          setLa_gerance(values);
          setFormData(formData => ({ ...formData, ["La_gerance"]: values }));          
        }      
      }
    }, 1000);
    return () => clearTimeout(getSiren);
  }

  async function getCityFromPostal(name,value,index){
    
    const getCity = setTimeout(async() => {
      let values = [...La_gerance];
      values[index]["villelagrance"] = "";
      values[index]["ville_id_lagrance"] = "";
      values[index]["greffelagrance"] = "";
      values[index]["villelagranceArr"] = [];
      setLa_gerance(values);
      setFormData(formData => ({ ...formData, ["La_gerance"]: values }));     
      if(value.length>0){
        const response = await axios.get(`${process.env.REACT_APP_API_URL}api/checkzipcode/${value}`);
        if( response.data &&  response.data.data){          
          values[index]["villelagrance"] = response.data.data.city;
          values[index]["ville_id_lagrance"] = response.data.data.id;
          values[index]["greffelagrance"] = response.data.data.area;
          values[index]["villelagranceArr"] = response.data.data.cityArr;
          setLa_gerance(values);
          setFormData(formData => ({ ...formData, ["La_gerance"]: values }));
        }
      }      
    }, 1000);
    return () => clearTimeout(getCity);
  }
  function handleChange(e) {
    const { name, value } = e.target;
    console.log("name",name)
    console.log("ville_id",value)
    if(name == "ville_id"){
      let formDataGet = {...formData};
      let cityArrFilter = formDataGet.villeArr.filter((val,i)=>val.id==value);
      console.log("handleChange cityArrFilter",cityArrFilter)
      setFormData(formData => ({ ...formData, ["ville"]: cityArrFilter[0].city,["ville_id"]: value }));
    }else{
      setFormData(formData => ({ ...formData, [name]: value }));
    }
    // setFormData(formData => ({ ...formData, [name]: value }));
  }

  function handleChangeDate(e) {
    let newDate = moment(e.$d).format('YYYY/MM/DD');
    setFormData(formData => ({ ...formData, ["date_acte"]: newDate }));
  }
  
  async function submitPaymentDetails() { 
    let valid  = false;
    // setSubmitted(true);
    setLoading(true);
    delete paymentData['data']['billing_address_json'];
    delete paymentData['data']['currentUserDetails'];
    delete paymentData['data']['select_cartdetailJ'];
    delete paymentData['data']['select_cartdetailN'];
    delete paymentData['data']['select_department'];
    delete paymentData['data']['votre_annonce_json'];
    delete paymentData['data']['votre_annonce'];
    delete paymentData['data']['billing_address_json_parse'];
    delete paymentData['data']['user_detail'];
    delete paymentData['data']['votre_html'];
    
    let getResponse =  await dispatch(announceActions.updatePayment({announce_id:id,user_id:currentUser && currentUser.data?currentUser.data.id:"",serenite:isCheckedSerenity,paymentData:paymentData})).then((responseData) => {         
      // // // console.log("responseData",responseData)   
      setLoading(false);     
      return Promise.resolve(responseData);   
      
    })
    .catch((err) => {   
      setLoading(false);      
      return Promise.reject({
          "sucess": false,
          "msg": "Une erreur s'est produite. Veuillez réessayer!",
          "data": "",
          "insertID": ""
      });      
    });
    // setSuccessful(true);
    // // // console.log("getResponse",getResponse);
    // 
    if(getResponse.sucess === true){  
      setLoading(false);      
      valid = true
    }else{
      setLoading(false);
      valid = false
    }
    return valid;
  }

  async function submitUserDetails() { 
    
    let valid  = false;
    // setSubmitted(true);
    if(userSelectedAddress.email && isValidEmail(userSelectedAddress.email) && userSelectedAddress.telephone && isNumericValue(userSelectedAddress.telephone)  && ((userSelectedAddress.billing_address_type === "account_address") || (userSelectedAddress.billing_address_type === "another_address" && userSelectedAddress.billing_address_code_postal  && userSelectedAddress.billing_address_ville) || ( userSelectedAddress.billing_address_type === "customer_address" && (userSelectedAddress.billing_address_denomination  ||  userSelectedAddress.billing_address_address  || userSelectedAddress.billing_address_code_postal  || userSelectedAddress.billing_address_ville)) )  ){
      setLoading(true);
      let getResponse =  await dispatch(announceActions.update_user(userSelectedAddress)).then((responseData) => { 
        return Promise.resolve(responseData); 
      })
      .catch((err) => { 
        setLoading(false);        
        return Promise.reject({
            "sucess": false,
            "msg": "Une erreur s'est produite. Veuillez réessayer!",
            "data": "",
            "insertID": ""
        });      
      });
      // setSuccessful(true);
     
      setLoading(false); 
      if(getResponse.sucess === true){   
           
        valid = true
      }else{
        valid = false
      }
    }
    
    return valid;
  }
  async function handleSubmit() { 
    let valid  = false;  
    setSuccessful(false);
    setSubmitted(true);
    setSubmitted(true);
   
    if ((((formData.type_acte === "ACTE_AUTHENTIQUE" && formData.nom_famille && formData.officiant_to) || formData.type_acte === "ACTE_SSP")  && formData.denomination &&  formData.forme_juridique && formData.capital && formData.adresse && formData.code_postal && formData.ville  && (formData.La_gerance.length>0 && formData.La_gerance[0].type_personne_lagrance))) {  
    
      let checkLaGerance = await Promise.all(formData.La_gerance.map((val,key)=>{
        var status = false;
        if( val.type_personne_lagrance==="PERSONNE_PHYSIQUE" && val.civilite_lagrance  && val.nomlagrance && val.prenomlagrance && val.adresselagrance && ((val.resident_etrangerlagrancephysic=="Non" && val.code_postallagrance && val.villelagrance)|| (val.resident_etrangerlagrancephysic=="Oui" && val.payslagrance && val.villelagrance)) ){
          status= true;
        }else if( val.type_personne_lagrance==="PERSONNE_MORALE" && val.siren_lagrance  && val.denomination_lagrance  && val.forme_juridiquelagrance  && val.capital_lagrance && val.adresselagrance && val.code_postallagrance && val.villelagrance  && val.nomlagrance && val.prenomlagrance ){
          status= true;
        }        
        return status;
      }));      
      valid= !checkLaGerance.includes(false);
    }
    /* console.log("valid",valid) 
    return false;  */  
    if(valid === true){
      setLoading(true);
      
      let getResponse =  await dispatch(announceActions.create(formData)).then((responseData) => { 
        announceIdRef.current = responseData.announce_id;         
        
        setLoading(false);        
        return Promise.resolve(responseData);   
        
      })
      .catch((err) => { 
        
        setLoading(false);          
        return Promise.resolve({
            "sucess": false,
            "msg": "Une erreur s'est produite. Veuillez réessayer!",
            "data": "",
            "insertID": ""
        });      
      });
      setSuccessful(true);
      setLoading(false); 
      
      if(getResponse.sucess === true){        
        valid = true
      }else{
        valid = false
      }
      // 
    }
    
    return valid;
   
}
function setPaymentDataFunc(data){
  setPaymentData(data);
}

  // const handleSkip = () => {
  //   if (!isStepOptional(activeStep)) {
  //     // You probably want to guard against something like this,
  //     // it should never occur unless someone's actively trying to break something.
  //     throw new Error("You can't skip a step that isn't optional.");
  //   }

  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  //   setSkipped((prevSkipped) => {
  //     const newSkipped = new Set(prevSkipped.values());
  //     newSkipped.add(activeStep);
  //     return newSkipped;
  //   });
  // };
  // // // console.log("activeStep",activeStep)
  const handlePayment =async () => {
    
    if(!currentUser){
      navigate("/login");
    }
    /* let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped); */
  };
  function getStepContent(step) {
    switch (step) {
      case 0: 
      return <Seleurl adsname={adsname.toUpperCase()} formData={formData} handleChange={handleChange} handleChangeDate={handleChangeDate} isSubmitted={isSubmitted}  addForm={addForm} La_gerance={La_gerance} handleChangeAddForm={handleChangeAddForm} removeForm={removeForm} />;        
      case 1:
        return <FormSummary  handleBack={handleBack} adsname={adsname.toUpperCase()}  />;
      case 2:
        return <CompleteAddress setUserSelectedAddress={setUserSelectedAddress} userSelectedAddress={userSelectedAddress}   adsname={adsname.toUpperCase()}  />;
      case 3:
        return <PaymentForm setPaymentDataFunc={setPaymentDataFunc} isCheckedSerenity={isCheckedSerenity} setIscheckedSerenity={setIscheckedSerenity} setis_general_conditions_sale={setis_general_conditions_sale} is_general_conditions_sale={is_general_conditions_sale} general_conditions_sale_msg={general_conditions_sale_msg} adsname={adsname.toUpperCase()} />;
      case 4:
       /*  return announceDetails&&announceDetails.data&&parseInt(announceDetails.data.status)===3?<FormSuccess announceDetails={announceDetails} />: activeStep===4?<Elements options={options} stripe={stripePromise}><PaymentSecond ref={childRef} payamentPrice={payamentPrice} handlePayment={handlePayment}  adsname={adsname.toUpperCase()} /> </Elements>:<PaymentSecond ref={childRef} payamentPrice={payamentPrice} handlePayment={handlePayment}  adsname={adsname.toUpperCase()} />; */
        return activeStep===4?<Elements options={options} stripe={stripePromise}><PaymentSecond ref={childRef} payamentPrice={payamentPrice} handlePayment={handlePayment} setLoading={setLoading}  adsname={adsname.toUpperCase()} /> </Elements>:<PaymentSecond ref={childRef} payamentPrice={payamentPrice} handlePayment={handlePayment} setLoading={setLoading} adsname={adsname.toUpperCase()} />;

      default:
        return 'Unknown step';
    }
  }
  
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
 

  const handleSaveAnnonce = async () => {
    setSuccessful(false)
    setLoadingFullpage(true);
    localStorage.setItem("activeStep",activeStep);
    if(!currentUser){
      navigate("/login");
      return;
    }
    try {
      const requestData = {
        announce_id: id,
        user_id: currentUser && currentUser.data?currentUser.data.id:"",
      };
      let post_url = `${process.env.REACT_APP_API_URL}api/announce/save_announce`;

      // Use Axios to make the API request
      const response = await axios.post(post_url, requestData, {
        headers: {
            'Content-Type': 'application/json',
        },
      });
      setLoadingFullpage(false);
      setSuccessful(true)
      if(response.data.sucess === true){
        navigate("/userdashboard/annonces-en-attente");
      }else{
        dispatch(alertActions.error("Une erreur s'est produite. Veuillez réessayer!")); 
      }
      
      // return {success:true,data:response.data}
    } catch (error) {
      setLoadingFullpage(false);
      setSuccessful(true)
      dispatch(alertActions.error("Une erreur s'est produite. Veuillez réessayer!"));
      // // console.log(error); 
      // return {success:false,data:""}     
    }
  }
  const handleNext = async (activeStepGet) => {
    // // console.log("activeStepGet",activeStepGet)
    
    let submitResult = true;
    if(!currentUser && activeStepGet !== 0 && (!localStorage.getItem("fromAdmin"))){
      navigate("/login");
    }
    if(activeStepGet === 0){
      submitResult = await handleSubmit();
      // // console.log("submitResult",submitResult)

    }
    if(activeStepGet === 2){
      submitResult = await submitUserDetails();
      // // // console.log("submitResult",submitResult)

    }
    if(activeStepGet === 3){
      setgeneral_conditions_sale_msg("");
      if(!is_general_conditions_sale){
        setgeneral_conditions_sale_msg("Veuillez accepter les conditions générales de vente")
        return false;
      }
      submitResult = await submitPaymentDetails();
      // // // console.log("submitResult",submitResult)

    }
    if(submitResult){
      setLoading(false);     
      if(currentUser && currentUser.UserProDetails && currentUser.UserProDetails.details && currentUser.UserProDetails.details.payment_plan && currentUser.UserProDetails.details.payment_plan.includes("monthly") && activeStepGet === 3){
        navigate(location.pathname+"?activstep=5");

      }
      
      // else if(localStorage.getItem("fromAdmin") && activeStepGet === 1){        
      //   if(announceData && announceData.data){
      //     const idString = String(announceData.data.id).padStart(8, '0');
      //     let invoice_number = moment().format("DDMMY")+"_"+idString;
      //     let data = {
      //       "announce_id":announceData.data.id,
      //       "payment_order_id":invoice_number,
      //       "invoice_number":invoice_number,
      //       "invoice_date":moment().format("DD-MM-Y"),
      //       "date_payment":moment().format("DD-MM-Y"),
      //       "payment_intent":invoice_number,
      //       "payment_price":announceData.data.total,
      //       "payment_intent_client_secret":"",
      //     }
            
      //     const update_response = await updateAnnouncePayment(data);
      //     dispatch(alertActions.success("Données mises à jour avec succès !!"));
      //     setSuccessful(true)
      //     // dispatch(alertActions.error("Une erreur s'est produite. Veuillez réessayer!"));
      //     setTimeout(() => {
      //       setSuccessful(false)
      //       localStorage.removeItem("fromAdmin")
      //       navigate("/admin/devis");
      //     }, 1000);
         
      //   }

      // }
      
      else{
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
          newSkipped = new Set(newSkipped.values());
          newSkipped.delete(activeStep);
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);  
        if(location.pathname.split('/').length ===4){
          navigate(location.pathname+"/"+announceIdRef.current);
        }
      }
    }else{
      // let newSkipped = skipped;
      // if (isStepSkipped(activeStep)) {
      //   newSkipped = new Set(newSkipped.values());
      //   newSkipped.delete(activeStep);
      // }
      // setActiveStep((prevActiveStep) => prevActiveStep + 1);
      // setSkipped(newSkipped);
    }
    
  };
  const handleBack = () => {
    if(id || announceData.announce_id){
      // // // console.log("location",location);
      // // // console.log("location.pathname",location.pathname.split('/'));  
      setActiveStep((prevActiveStep) => prevActiveStep - 1);   
      if(location.pathname.split('/').length ===4){
        navigate(location.pathname+"/"+announceData.announce_id);
      }
      

    }else{
      setActiveStep((prevActiveStep) => prevActiveStep - 1);

    }
  };
  

  
async function updateAnnouncePayment(bodyData){
  setLoadingFullpage(true);
  try {
    let getResponse =  await dispatch(announceActions.updateSuccessPayment(bodyData)).then((responseData) => {       
      
      if(responseData.sucess == true){
        setAnnounceDetails(responseData);
        let announce = {
          "data":responseData.data,
          "announce_id":bodyData.announce_id 
        }
        dispatch({ type: announceConstants.CREATE_SUCCESS, announce});
      }
      setLoadingFullpage(false);      
      return Promise.resolve(responseData);   
      
    })
    .catch((err) => {
      setLoadingFullpage(false);           
      return Promise.reject({
          "sucess": false,
          "msg": "Une erreur s'est produite. Veuillez réessayer!",
          "data": "",
          "insertID": ""
      });      
    });
   /* return {success:true,data:response.data} */ 
  } catch (error) {
    // // console.log(error); 
    return {success:false,data:""}     
  }
}
  
async function getAnnounceDetails(announce_id){
  setLoadingFullpage(true);
  try {
    const response = await axios.get(`${process.env.REACT_APP_API_URL}api/announce/announce/${announce_id}`);
    
    if(response.data.sucess === true){
       if(!localStorage.getItem("fromAdmin") && (parseInt(response.data.data.status) === 3 || (response.data.data.length<=0))){
       navigate("/");
      }
      let announce = response.data;
      dispatch({ type: announceConstants.UPDATE_SUCCESS, announce });
      setAnnounceDetails(response.data);
    }
    setLoadingFullpage(false);
    return {success:true,data:response.data}
  } catch (error) {
    setLoadingFullpage(false);
    // // console.log(error); 
    return {success:false,data:""}     
  }
}
  
  return (
    <>
     {loadingFullpage?
      <PageLoaderComp />
      :""}
    
     {successful?
      <ToastMessageComp  message={alert.message} type={alert.type} />
      :""}
      
      <div className='bg-mainBg'>
        <div className='md:max-w-[1477px] relative w-full h-full m-auto py-7 px-4 sm:px-14 myForm'>
          <Breadcrumb Accueil="Accueil" page={(ads.split("-")).join(" ")} />
            
          <div className='relative  bg-mainBg my-4 sm:my-12 '>
            <div className='grid grid-cols-12 gap-5  '>
              <div className='mb-8 col-span-12 2lg:col-span-8'>
                <div className='hidden sm:inline-flex bg-white  mb-6 items-center justify-between w-full max-w-[974px] h-[81px] rounded-md xl:px-12  '>
                  <Stepper activeStep={queryParameters.get("activstep")?queryParameters.get("activstep"):activeStep} className='w-full  '>
                    {steps.map((label, index) => {
                      return (
                        <Step key={label}  >
                          <StepLabel>
                            <div className='inline-flex   items-center justify-between w-full'> 
                              <div className='max-w-[118px] text-black-light font-normal'>{label}</div>
                            </div>
                          </StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </div>
                
                <div className='bg-white max-w-[974px] rounded-xl  ' style={{ border: '0.5px solid #DEE3E2' }}>
                  {queryParameters.get("activstep") && parseInt(queryParameters.get("activstep")) === steps.length ? (
                    <>
                      <Typography sx={{ mt: 2, mb: 1 }}>
                        <FormSuccess announceDetails={announceDetails} loadingFullpage={loadingFullpage} />
                      </Typography>
                      
                    </>
                  ) : (
                    <>
                      <Typography  >{getStepContent(activeStep)}</Typography>
                      {
                       <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>                     
                      <button onClick={handleBack} className={activeStep === 0 ?"hidden":"w-48 h-12 m-4 rounded-md bg-thColor font-extrabold text-base sm:text-lg 3xl:text-xl text-white transition-all duration-300 hover:bg-secondary hover:btnShadow"} >
                      Précédent
                      </button>
                      <Box sx={{ flex: '1 1 auto' }} />
                      {/* onClick={() => childRef.current.getAlert()} */}
                      {/* handlePayment() */}
                      <button onClick={handleSaveAnnonce} className={!fromAdmin && activeStep === 1 ?"w-48 h-12 m-4 rounded-md bg-secondary font-extrabold text-base sm:text-lg 3xl:text-xl text-white transition-all duration-300 hover:bg-thColor hover:btnShadow":"hidden"} >
                        Enregistrer
                      </button>
                      <button disabled={loading?true:false} style={{"opacity":loading?"0.7":""}} onClick={()=>activeStep === steps.length - 1 ? childRef.current.handleSubmitCheckout():handleNext(activeStep)} className='w-48 h-12 m-4 rounded-md bg-thColor font-extrabold text-base sm:text-lg 3xl:text-xl text-white transition-all duration-300 hover:bg-secondary hover:btnShadow'>
                          {activeStep === steps.length - 1 ? `Payer ${payamentPrice} €` : activeStep ===0 ?'Obtenir le tarif':'Etape suivante '} 
                          {
                            loading?                         
                            <CircularProgress 
                            size={24}
                            sx={{
                              color: "white",
                              // marginTop: '-10px',
                              /* position: 'absolute',
                              top: '50%',
                              left: '50%',
                              
                              marginLeft: '-12px', */
                            }} />
                        :""}
                      </button>
                    </Box>
                    }
                    </>
                  )}
                </div>
              </div>
              <div className=' col-span-12 2lg:col-span-4  '>
                <Procedures />
                <Express />
                <PaymentSideBlock />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div >
    </>
  );
}


