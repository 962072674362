import React from "react";
// import SideBar from "./SideBar";
import "./Style.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Footer from "../component/Footer";

import { HiOutlineDownload } from "react-icons/hi";
import { Link, Navigate } from "react-router-dom";

import attestation from "../assets/attestation.png";
import facturePdf from "../assets/facturePdf.png";
import { TextField } from "@mui/material";
import SearchHead from "./SearchHead";
import {
  useSelector,
  // useDispatch
} from "react-redux";
import ScreenWrapper from "./ScreenWrapper";

export default function AnnonceLegale() {
  const currentUser = useSelector((state) => state.authentication.user);
  const orders = useSelector((state) => state.orders.devis);
  const [value, setValue] = React.useState("AnnoncePubliée");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // if (!currentUser) {
  //   return <Navigate to="/" />;
  // }
  return (
    <>
      <ScreenWrapper>
        <div className="xl:max-w-[1126px] w-full h-full bg-white rounded-[20px]">
          <div className="mainHead min-h-[80px] sm:min-h-[187px] flex sm:flex-row  flex-col sm:items-center p-3 sm:p-10 sm:justify-between">
            <div>
              <h1 className="text-lg sm:text-xl md:md:text-lg xl:text-2xl  font-black text-white">
                Annonce légale
              </h1>
              <p className="text-lg text-white">n° 20220711-00002763</p>
            </div>
            <div className="w-full sm:w-auto flex justify-end">
              <SearchHead />
            </div>
          </div>

          <div className="w-full mt-6 sm:-mt-11 sm:px-10 px-4 AnnonTab  ">
            <TabContext value={value}>
              <Box>
                <TabList
                  indicatorColor="none"
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  <Tab
                    label="Annonce publiée"
                    value="AnnoncePubliée"
                    className="w-[210px] mb-2 mr-2 sideShadow h-[50px] rounded-[10px] bg-white flex items-center justify-center"
                  />
                  <Tab
                    label="Attestation"
                    value="Attestation"
                    className="w-[210px]  mb-2 mx-2 sideShadow h-[50px] rounded-[10px] bg-white flex items-center justify-center"
                  />
                  <Tab
                    label="Facture"
                    value="Facture"
                    className="w-[210px]  mb-2 mx-2 sideShadow h-[50px] rounded-[10px] bg-white flex items-center justify-center"
                  />
                  <Tab
                    label="Commentaire"
                    value="Commentaire"
                    className="w-[210px]  mb-2 mx-2 sideShadow h-[50px] rounded-[10px] bg-white flex items-center justify-center"
                  />
                </TabList>
              </Box>
              <TabPanel value="AnnoncePubliée">
                <div className="my-12 max-w-[414px] m-auto">
                  <div
                    className=" rounded-[14px] p-5 border border-[#DEE3E2] "
                    style={{ borderRadius: "0px 0px 5px 5px;" }}
                  >
                    <h6 className="text-base font-bold text-primary ">
                      Par acte ssp du 28/07/2022 a été constitué une SARL
                      dénommée :
                    </h6>
                    <h3 className="text-2xl font-bold text-primary text-center my-4">
                      LA FRED
                    </h3>

                    <h5 className="text-base font-bold text-primary  ">
                      Sigle&nbsp;:{" "}
                      <span className="font-normal text-lg text-primary">
                        {" "}
                        LF{" "}
                      </span>
                    </h5>

                    <h5 className="text-base font-bold text-primary  ">
                      Capital&nbsp;:{" "}
                      <span className="font-normal text-lg text-primary">
                        5000 €
                      </span>
                    </h5>

                    <h5 className="text-base font-bold text-primary  ">
                      Objet&nbsp;:{" "}
                      <span className="font-normal text-lg text-primary">
                        Vente de produits cosmétiques
                      </span>
                    </h5>

                    <h5 className="text-base font-bold text-primary  ">
                      Gérant&nbsp;:{" "}
                      <span className="font-normal text-lg text-primary">
                        M. Hugo Delpierre 156 Boulevard de Sébastopol 75003
                        Paris
                      </span>
                    </h5>

                    <h5 className="text-base font-bold text-primary  ">
                      Durée&nbsp;:{" "}
                      <span className="font-normal text-lg text-primary">
                        2 ans à compter de l’immatriculation au RCS de PARIS
                      </span>
                    </h5>
                  </div>
                  <button className="flex items-center justify-center gap-2 mt-5 text-center text-lg font-medium outline-primary text-primary transition-all duration-300 border border-primary w-full py-3 rounded-md hover:bg-secondary hover:text-white hover:border-secondary">
                    <HiOutlineDownload /> Télécharger
                  </button>
                </div>
              </TabPanel>
              <TabPanel value="Attestation">
                <img src={attestation} alt="attestation" className="pt-12" />
              </TabPanel>
              <TabPanel value="Facture">
                <img src={facturePdf} alt="facturePdf" className="pt-12" />
              </TabPanel>
              <TabPanel value="Commentaire">
                <TextField
                  id="outlined-multiline-static"
                  label="Laissez-nous un commentaire sur votre expérience sur Legals."
                  multiline
                  rows={6}
                  sx={{ width: "100%", marginTop: "60px" }}
                />
                <div className="flex items-end w-full justify-end">
                  <button className="max-w-[132px] w-full min-h-[43px] h-full rounded-md mt-5 flex items-center justify-center bg-primary text-lg text-white font-semibold  transition-all duration-300 hover:bg-secondary hover:text-white hover:border-secondary">
                    Envoyer
                  </button>
                </div>
              </TabPanel>
            </TabContext>
          </div>
        </div>
        <Link
          to="/userdashboard/annonces-publiees"
          className="flex items-center justify-center max-w-[200px] gap-2 mt-5 text-center text-lg font-medium outline-primary text-primary transition-all duration-300 border border-primary w-full py-3 rounded-md hover:bg-secondary hover:text-white hover:border-secondary"
        >
          Retour à la liste
        </Link>
      </ScreenWrapper>
      <Footer />
    </>
  );
}
