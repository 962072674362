/* eslint-disable array-callback-return */
import React, {useState, useEffect } from 'react' 
import moment from 'moment';
import dayjs from 'dayjs';
import { BsFillQuestionCircleFill, BsPlusCircle } from 'react-icons/bs'
import { MdEuroSymbol } from 'react-icons/md'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/fr.js';
import { frFR } from '@mui/x-date-pickers/locales';
import Tooltip from '@mui/material/Tooltip';
import { FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select,ListSubheader , TextField ,Divider} from '@mui/material';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';

export  function LocationGerance(props) {
 
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const StyledTooltip = ({ title, children, ...props }) => (
    <Tooltip
      {...props}
      title={title}
      placement="right"
      componentsProps={{
        tooltip: {
          sx: {
            background: '#DEE3E2',
            width: '129px',
            padding: '10px',
            color: '#27295B',
            fontSize: '14px',
            fontWeight: '400'
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );

  return (
    <>
    <div className='' >
      <div className='py-6 px-3 sm:px-6' style={{ borderBottom: '0.5px solid #DEE3E2' }}>
        <h1 className='text-lg sm:text-2xl font-black text-primary'>Annonce légale : {props.adsname}</h1>
      </div>
      <div className='py-3 sm:py-6 px-3 sm:px-6'>
        {/* <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Nature de l'acte</h2> */}
        <div className='grid grid-cols-12 items-center mt-6'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Date de l'acte *</label>
          <div className='col-span-12 sm:col-span-9 mr-7 md:mr-0 md:col-span-7 lg:col-span-4'>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}  >
              <DemoContainer components={['DatePicker']}  >
                {
                  props.formData.date_typeacte?
                  <DatePicker value={dayjs(props.formData.date_typeacte)}   name="date_typeacte"  onChange={(e)=>{props.handleChangeDate(e,"date_typeacte")}} format={"DD/MM/YYYY"}                  
                  />
                  :
                  <DatePicker  name="date_typeacte"  onChange={(e)=>{props.handleChangeDate(e,"date_typeacte")}} format={"DD/MM/YYYY"}                 
                  />
                }
                
              </DemoContainer>
            </LocalizationProvider>
          </div>

        </div>
      </div>
      {/* Le bailleur */}  
      <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
        <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Le bailleur</h2>
        <div >   
          <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Personne *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-9 md:mr-10 inline-flex items-center gap-2'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  label="Sélectionnez"
                  id='Personne_loueur'
                  name="Personne_loueur"
                  value={props.formData.Personne_loueur} 
                  onChange={props.handleChange}  
                  error={!props.formData.Personne_loueur  && props.isSubmitted?true:false}
                >
                  <MenuItem value={"PERSONNE_PHYSIQUE"}>Personne physique</MenuItem>
                  <MenuItem value={"PERSONNE_MORALE"}>Personne morale</MenuItem>

                </Select>
              </FormControl>
              
            </div>
            
          </div>
          <div className={props.formData.Personne_loueur === ""?"hidden":""}>
            <div className={props.formData.Personne_loueur === "PERSONNE_MORALE"?"":"hidden"}>      
              <div className='grid grid-cols-12 mt-6 items-center'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>N° SIREN *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <TextField
                    hiddenLabel
                    size="small"
                    className='w-full'
                    id='candent_rcs'
                    name="candent_rcs"
                    value={props.formData.candent_rcs} 
                    onChange={props.handleChange}  
                    error={!props.formData.candent_rcs  && props.isSubmitted?true:false}
                  />
                </div>
              </div>
              <div className='grid grid-cols-12 mt-6 items-center'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Dénomination *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <TextField
                    hiddenLabel                        
                    size="small"
                    className='w-full'
                    id='denomination'
                    name="denomination"
                    value={props.formData.denomination} 
                    onChange={props.handleChange}  
                    error={!props.formData.denomination  && props.isSubmitted?true:false}
                  />
                </div>
              </div>
              <div className='grid grid-cols-12 items-start mt-6'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Forme juridique *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                  <FormControl size="small" className='w-full'>
                    <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      label="Sélectionnez"
                      id='form_juridique_cendent'
                      name="form_juridique_cendent"
                      value={props.formData.form_juridique_cendent} 
                      onChange={props.handleChange}  
                      error={!props.formData.form_juridique_cendent  && props.isSubmitted?true:false}
                    >
                      <MenuItem value={"SAS"}>Société par actions simplifiée (SAS)</MenuItem>
                      <MenuItem value={"SAS à capital variable"}>Société par actions simplifiée à capital variable</MenuItem>
                      <MenuItem value={"SASU"}>Société par actions simplifiée unipersonnelle (SASU)</MenuItem>
                      <MenuItem value={"SASU à capital variable"}>Société par actions simplifiée unipersonnelle à capital variable</MenuItem>
                      <MenuItem value={"SARL"}>Société à responsabilité limitée (SARL)</MenuItem>
                      <MenuItem value={"SARL à capital variable"}>Société à responsabilité limitée à capital variable</MenuItem>
                      <MenuItem value={"SARLU"}>Société à responsabilité limitée à associé unique (SARLU)</MenuItem>
                      <MenuItem value={"SARLU à capitale variable"}>Société à responsabilité limitée à associé unique à capitale variable</MenuItem>
                      <MenuItem value={"SCI"}>Société civile immobilière (SCI)</MenuItem>
                      <MenuItem value={"SCI à capital variable"}>Société civile immobilière à capital variable</MenuItem>
                      <MenuItem value={"SC"}>Société civile</MenuItem>
                      <MenuItem value={"SA"}>Société anonyme (SA)</MenuItem>
                      <MenuItem value={"SA à capital variable"}>Société anonyme à capital variable</MenuItem>
                      <MenuItem value={"SNC"}>Société en nom collectif (SNC)</MenuItem>
                      <MenuItem value={"SNC à capital variable"}>Société en nom collectif à capital variable</MenuItem>
                      <MenuItem value={"SCM"}>Société civile de moyens (SCM)</MenuItem>
                      <MenuItem value={"SCP"}>Société civile professionnelle (SCP)</MenuItem>
                      <MenuItem value={"SCPI"}>Société civile de placement immobilier (SCPI)</MenuItem>
                      <MenuItem value={"SCCV"}>Société civile immobilière de construction vente (SCCV ou SCICV)</MenuItem>
                      <MenuItem value={"SELAFA"}>Société d'exercice libéral sous forme anonyme (SELAFA)</MenuItem>
                      <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                      <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                      <MenuItem value={"SELCA"}>Société d'exercice libéral en commandite par Actions (SELCA)</MenuItem>
                      <MenuItem value={"SELARL"}>Société d'exercice libéral a responsabilité limitée (SELARL)</MenuItem>
                      <MenuItem value={"SELEURL"}>Société d'exercice libéral ayant un associé Unique (SELEURL)</MenuItem>
                      <MenuItem value={"SELASU"}>Société d'exercice libéral par actions simplifiée unipersonnelle (SELASU)</MenuItem>
                      <MenuItem value={"SCEA"}>Société civile d'exploitation agricole (SCEA)</MenuItem>
                      <MenuItem value={"EARL"}>Exploitation agricole à responsabilité limitée (EARL)</MenuItem>
                      <MenuItem value={"GAEC"}>Groupement agricole d'exploitation en commun (GAEC)</MenuItem>
                      <MenuItem value={"GFA"}>Groupement foncier agricole (GFA)</MenuItem>
                      <MenuItem value={"EIRL"}>Entrepreneur Individuel à Responsabilité Limité</MenuItem>
                      <MenuItem value={"SPFPLAS"}>Société de Participations Financières de Profession Libérale d'Avocats Simplifiée (SPFPLAS)</MenuItem>
                    </Select>
                  </FormControl>
                  
                </div>
              </div>
              <div  className={"grid grid-cols-12 items-center mt-6"} >
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>
                  
                  <FormControl size="small" className='w-full'>
                    <OutlinedInput
                      endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                      id='cendent_capital'
                      name="cendent_capital"
                      value={props.formData.cendent_capital} 
                      onChange={props.handleChange}  
                      error={!props.formData.cendent_capital  && props.isSubmitted?true:false}
                    />
                  </FormControl>
                </div>
              </div>
            </div> 
            <div className={props.formData.Personne_loueur === "PERSONNE_PHYSIQUE"?"":"hidden"}>
              <div className='grid grid-cols-12 items-start mt-6'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                  <FormControl size="small" className='w-full'>
                    <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      label="Sélectionnez"
                      id='civilite_physique_candent'
                      name="civilite_physique_candent"
                      value={props.formData.civilite_physique_candent} 
                      onChange={props.handleChange}  
                      error={!props.formData.civilite_physique_candent  && props.isSubmitted?true:false}
                      
                    >
                      <MenuItem value={"Mme"}>Madame</MenuItem>
                      <MenuItem value={"M."}>Monsieur</MenuItem>

                    </Select>
                  </FormControl>
                  
                </div>
              </div>

              <div className='grid grid-cols-12 mt-6 items-center'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <TextField
                    hiddenLabel
                    size="small"
                    className='w-full'
                    id='nom_physique_candent'
                    name="nom_physique_candent"
                    value={props.formData.nom_physique_candent} 
                    onChange={props.handleChange}  
                    error={!props.formData.nom_physique_candent  && props.isSubmitted?true:false}
                  />
                </div>
              </div>

              <div className='grid grid-cols-12 mt-6 items-center'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <TextField
                    hiddenLabel
                    size="small"
                    className='w-full'
                    id='prenom_physique_candent'
                    name="prenom_physique_candent"
                    value={props.formData.prenom_physique_candent} 
                    onChange={props.handleChange}  
                    error={!props.formData.prenom_physique_candent  && props.isSubmitted?true:false}
                  />
                </div>
              </div>
            </div>
            <div className='grid grid-cols-12 mt-6 items-center'>
              <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse *</label>
              <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                <TextField
                  hiddenLabel
                  size="small"
                  className='w-full'
                  id='cendent_address'
                  name="cendent_address"
                  value={props.formData.cendent_address} 
                  onChange={props.handleChange}  
                  error={!props.formData.cendent_address  && props.isSubmitted?true:false}
                />
              </div>
            </div>
            <div className='grid grid-cols-12 mt-6 items-center'>
              <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Complément adresse</label>
              <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                <TextField
                  hiddenLabel
                  size="small"
                  className='w-full'
                  id='cendent_address_suite'
                  name="cendent_address_suite"
                  value={props.formData.cendent_address_suite} 
                  onChange={props.handleChange}  
                  // error={!props.formData.cendent_address_suite  && props.isSubmitted?true:false}
                />
              </div>
            </div>
            <div className={props.formData.Personne_loueur === "PERSONNE_PHYSIQUE"?"grid grid-cols-12 mt-6 items-center":"grid-cols-12 mt-6 items-center hidden"} >
              <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Résidant à l’étranger *</label>
              
              <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                <FormControl size="small" className='w-full'>
                  <InputLabel id="resident_etranger_physique_candent-label">Sélectionnez</InputLabel>
                  <Select
                    labelId="demo-resident_etranger_physique_candent-label"                        
                    label="resident_etranger_physique_candent"
                    id='resident_etranger_physique_candent'
                    name="resident_etranger_physique_candent"
                    value={props.formData.resident_etranger_physique_candent} 
                    onChange={props.handleChange}  
                    error={!props.formData.resident_etranger_physique_candent  && props.isSubmitted?true:false}
                  >
                    <MenuItem value={"Non"} >Non</MenuItem>
                    <MenuItem value={"Oui"} >Oui</MenuItem>

                  </Select>
                </FormControl>
              </div>
            </div>

            <div className={props.formData.resident_etranger_physique_candent ==="Non"||  props.formData.Personne_loueur === "PERSONNE_MORALE"?'grid grid-cols-12 mt-6 items-center':'hidden grid-cols-12 mt-6 items-center'} >
              <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal *</label>
              <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                <TextField
                  hiddenLabel
                  size="small"
                  className='w-full'
                  id='cendent_code_postal'
                  name="cendent_code_postal"
                  value={props.formData.cendent_code_postal} 
                  onChange={props.handleChange}  
                  error={!props.formData.cendent_code_postal  && props.isSubmitted?true:false}
                />
              </div>
            </div>

            <div className='grid grid-cols-12 mt-6 items-center'>
              <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville *</label>
              <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                <TextField
                  hiddenLabel                      
                  size="small"
                  className={props.formData.Personne_loueur === "PERSONNE_PHYSIQUE" && props.formData.resident_etranger_physique_candent=="Oui" ?'w-full':'hidden w-full'}
                  id='cendent_ville'
                  name="cendent_ville"
                  value={props.formData.cendent_ville} 
                  onChange={props.handleChange}  
                  error={!props.formData.cendent_ville  && props.isSubmitted?true:false}
                />
                <FormControl size="small"  className={props.formData.Personne_loueur === "PERSONNE_PHYSIQUE" && props.formData.resident_etranger_physique_candent=="Oui"?'hidden w-full':' w-full'} >
                    <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      // className={props.formData.Personne_loueur === "PERSONNE_PHYSIQUE" && props.formData.resident_etranger_physique_candent=="Non" ?'w-full':'hidden w-full'}
                      label="Sélectionnez"
                      id='cendent_ville_id'
                      name="cendent_ville_id"
                      value={props.formData.cendent_ville_id} 
                      onChange={props.handleChange}  
                      error={!props.formData.cendent_ville  && props.isSubmitted?true:false}                    
                    >
                      {
                        props.formData.cendent_villeArr.map((cityVal,cityIndex)=>{
                          return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                        })
                      }

                    </Select>
                  </FormControl>
              </div>
            </div>
            <div className={props.formData.Personne_loueur === "PERSONNE_PHYSIQUE" && props.formData.resident_etranger_physique_candent==="Oui"?'grid grid-cols-12 mt-6 items-center':'hidden grid-cols-12 mt-6 items-center'}>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Pays *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    className='w-full'
                    name="pays_cendent"
                    value={props.formData.pays_cendent} 
                    onChange={props.handleChange}  
                    error={!props.formData.pays_cendent  && props.isSubmitted?true:false}  
                  />
                </div>
              </div>
            <div className={props.formData.Personne_loueur === "PERSONNE_MORALE"?"grid grid-cols-12 mt-6 items-center":"grid-cols-12 mt-6 items-center hidden"} >
              <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Greffe *</label>
              <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                <TextField
                  hiddenLabel
                  id="cendent_greffe"
                  size="small"
                  className='w-full'
                  name="cendent_greffe"
                  value={props.formData.cendent_greffe} 
                  onChange={props.handleChange}  
                  error={!props.formData.cendent_greffe  && props.isSubmitted?true:false}
                />
              </div>
            </div>
            <div className={props.formData.Personne_loueur === "PERSONNE_MORALE"?"":"hidden"}>
              <div className='px-0 group flex items-center gap-2 text-secondary mt-12 '>                   
                <span className='text-base font-normal'>Représentée par</span>
              </div>
              <div className='grid grid-cols-12 items-start mt-6'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité </label>
                <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                  <FormControl size="small" className='w-full'>
                    <InputLabel id="representation_civilitecandent-label">Sélectionnez</InputLabel>
                    <Select
                      labelId="representation_civilitecandent-label"
                      id="representation_civilitecandent"
                      label="Sélectionnez"
                      name="representation_civilitecandent"
                      value={props.formData.representation_civilitecandent} 
                      onChange={props.handleChange}  
                      error={!props.formData.representation_civilitecandent  && props.isSubmitted?true:false}
                      
                    >
                      <MenuItem value={"Mme"}>Madame</MenuItem>
                      <MenuItem value={"M."}>Monsieur</MenuItem>

                    </Select>
                  </FormControl>
                  
                </div>
              </div>

              <div className='grid grid-cols-12 mt-6 items-center'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <TextField
                    hiddenLabel
                    id="representation_nomcnadent"
                    size="small"
                    className='w-full'
                    name="representation_nomcnadent"
                    value={props.formData.representation_nomcnadent} 
                    onChange={props.handleChange}  
                    error={!props.formData.representation_nomcnadent  && props.isSubmitted?true:false}
                  />
                </div>
              </div>

              <div className='grid grid-cols-12 mt-6 items-center'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <TextField
                    hiddenLabel
                    id="representation_prenomcandent"
                    size="small"
                    className='w-full'
                    name="representation_prenomcandent"
                    value={props.formData.representation_prenomcandent} 
                    onChange={props.handleChange}  
                    error={!props.formData.representation_prenomcandent  && props.isSubmitted?true:false}
                  />
                </div>
              </div>
            </div>
          </div>
          <Divider className='mb-6 mt-6' variant="middle" />
        </div>        
      </div>  
      {/* Le locataire */}   
      <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
        <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Le locataire</h2>            
        <div  >   
          <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
            <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Personne *</label>
            <div className='col-span-12 sm:col-span-9 md:col-span-9 md:mr-10 inline-flex items-center gap-2'>
              <FormControl size="small" className='w-full'>
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="Personne_locataire"
                  label="Sélectionnez"
                  name="Personne_locataire"
                  value={props.formData.Personne_locataire} 
                  onChange={props.handleChange}  
                  error={!props.formData.Personne_locataire  && props.isSubmitted?true:false}
                >
                  <MenuItem value={"PERSONNE_PHYSIQUE"}>Personne physique</MenuItem>
                  <MenuItem value={"PERSONNE_MORALE"}>Personne morale</MenuItem>

                </Select>
              </FormControl>
              
            </div>
            
          </div>
          <div className={props.formData.Personne_locataire === ""?"hidden":""}>
            <div className={props.formData.Personne_locataire === "PERSONNE_MORALE"?"":"hidden"}>
              <div className='grid grid-cols-12 mt-6 items-center'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Immatriculation *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <FormControl size="small" className='w-full'>
                    <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Sélectionnez"
                      name="Immatriculation_benificiare"
                      value={props.formData.Immatriculation_benificiare} 
                      onChange={props.handleChange}  
                      error={!props.formData.Immatriculation_benificiare  && props.isSubmitted?true:false}
                      
                    >
                      <MenuItem value={"Immatriculé au RCS"}>Immatriculé au RCS</MenuItem>
                      <MenuItem value={"En cours d'immatriculation au RCS"}>En cours d'immatriculation au RCS</MenuItem>

                    </Select>
                  </FormControl>
                </div>
              </div>      
              <div className={props.formData.Immatriculation_benificiare ==="Immatriculé au RCS"?"grid grid-cols-12 mt-6 items-center":"hidden grid-cols-12 mt-6 items-center"}>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>N° SIREN *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    className='w-full'
                    name="rcs_benificiare"
                    value={props.formData.rcs_benificiare} 
                    onChange={props.handleChange}  
                    error={!props.formData.rcs_benificiare  && props.isSubmitted?true:false}
                  />
                </div>
              </div>
              <div className='grid grid-cols-12 mt-6 items-center'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Dénomination *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    className='w-full'
                    name="denomination_benificiare"
                    value={props.formData.denomination_benificiare} 
                    onChange={props.handleChange}  
                    error={!props.formData.denomination_benificiare  && props.isSubmitted?true:false}
                  />
                </div>
              </div>
              <div className='grid grid-cols-12 items-start mt-6'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Forme juridique *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                  <FormControl size="small" className='w-full'>
                    <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Sélectionnez"
                      name="forme_juridique_benificiare"
                      value={props.formData.forme_juridique_benificiare} 
                      onChange={props.handleChange}  
                      error={!props.formData.forme_juridique_benificiare  && props.isSubmitted?true:false}
                    >
                      <MenuItem value={"SAS"}>Société par actions simplifiée (SAS)</MenuItem>
                      <MenuItem value={"SAS à capital variable"}>Société par actions simplifiée à capital variable</MenuItem>
                      <MenuItem value={"SASU"}>Société par actions simplifiée unipersonnelle (SASU)</MenuItem>
                      <MenuItem value={"SASU à capital variable"}>Société par actions simplifiée unipersonnelle à capital variable</MenuItem>
                      <MenuItem value={"SARL"}>Société à responsabilité limitée (SARL)</MenuItem>
                      <MenuItem value={"SARL à capital variable"}>Société à responsabilité limitée à capital variable</MenuItem>
                      <MenuItem value={"SARLU"}>Société à responsabilité limitée à associé unique (SARLU)</MenuItem>
                      <MenuItem value={"SARLU à capitale variable"}>Société à responsabilité limitée à associé unique à capitale variable</MenuItem>
                      <MenuItem value={"SCI"}>Société civile immobilière (SCI)</MenuItem>
                      <MenuItem value={"SCI à capital variable"}>Société civile immobilière à capital variable</MenuItem>
                      <MenuItem value={"SC"}>Société civile</MenuItem>
                      <MenuItem value={"SA"}>Société anonyme (SA)</MenuItem>
                      <MenuItem value={"SA à capital variable"}>Société anonyme à capital variable</MenuItem>
                      <MenuItem value={"SNC"}>Société en nom collectif (SNC)</MenuItem>
                      <MenuItem value={"SNC à capital variable"}>Société en nom collectif à capital variable</MenuItem>
                      <MenuItem value={"SCM"}>Société civile de moyens (SCM)</MenuItem>
                      <MenuItem value={"SCP"}>Société civile professionnelle (SCP)</MenuItem>
                      <MenuItem value={"SCPI"}>Société civile de placement immobilier (SCPI)</MenuItem>
                      <MenuItem value={"SCCV"}>Société civile immobilière de construction vente (SCCV ou SCICV)</MenuItem>
                      <MenuItem value={"SELAFA"}>Société d'exercice libéral sous forme anonyme (SELAFA)</MenuItem>
                      <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                      <MenuItem value={"SELAS"}>Société d'exercice libéral par actions simplifiée (SELAS)</MenuItem>
                      <MenuItem value={"SELCA"}>Société d'exercice libéral en commandite par Actions (SELCA)</MenuItem>
                      <MenuItem value={"SELARL"}>Société d'exercice libéral a responsabilité limitée (SELARL)</MenuItem>
                      <MenuItem value={"SELEURL"}>Société d'exercice libéral ayant un associé Unique (SELEURL)</MenuItem>
                      <MenuItem value={"SELASU"}>Société d'exercice libéral par actions simplifiée unipersonnelle (SELASU)</MenuItem>
                      <MenuItem value={"SCEA"}>Société civile d'exploitation agricole (SCEA)</MenuItem>
                      <MenuItem value={"EARL"}>Exploitation agricole à responsabilité limitée (EARL)</MenuItem>
                      <MenuItem value={"GAEC"}>Groupement agricole d'exploitation en commun (GAEC)</MenuItem>
                      <MenuItem value={"GFA"}>Groupement foncier agricole (GFA)</MenuItem>
                      <MenuItem value={"EIRL"}>Entrepreneur Individuel à Responsabilité Limité</MenuItem>
                      <MenuItem value={"SPFPLAS"}>Société de Participations Financières de Profession Libérale d'Avocats Simplifiée (SPFPLAS)</MenuItem>
                    </Select>
                  </FormControl>                      
                </div>
              </div>
              <div  className={"grid grid-cols-12 items-center mt-6"} >
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Capital *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-6 lg:col-span-3 inline-flex items-center gap-2'>
                  
                  <FormControl size="small" className='w-full'>
                    <OutlinedInput
                      id="outlined-adornment-weight"
                      endAdornment={<InputAdornment position="end"><MdEuroSymbol className='fill-primary' /></InputAdornment>}
                      aria-describedby="outlined-weight-helper-text"
                      inputProps={{
                        'aria-label': 'weight',
                      }}
                      name="capital_benificiare"
                      value={props.formData.capital_benificiare} 
                      onChange={props.handleChange}  
                      error={!props.formData.capital_benificiare  && props.isSubmitted?true:false}
                    />
                  </FormControl>

                </div>
              </div>
            </div> 
            <div className={props.formData.Personne_locataire === "PERSONNE_PHYSIQUE"?"":"hidden"}>
              <div className='grid grid-cols-12 items-start mt-6'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                  <FormControl size="small" className='w-full'>
                    <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Sélectionnez"
                      name="civilite_physique_benificiare"
                      value={props.formData.civilite_physique_benificiare} 
                      onChange={props.handleChange}  
                      error={!props.formData.civilite_physique_benificiare  && props.isSubmitted?true:false}
                      
                    >
                      <MenuItem value={"Mme"}>Madame</MenuItem>
                      <MenuItem value={"M."}>Monsieur</MenuItem>

                    </Select>
                  </FormControl>
                  
                </div>
              </div>

              <div className='grid grid-cols-12 mt-6 items-center'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    className='w-full'
                    name="nom_physique_benificiare"
                    value={props.formData.nom_physique_benificiare} 
                    onChange={props.handleChange}  
                    error={!props.formData.nom_physique_benificiare  && props.isSubmitted?true:false}
                  />
                </div>
              </div>

              <div className='grid grid-cols-12 mt-6 items-center'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    className='w-full'
                    name="prenom_physique_benificiare"
                    value={props.formData.prenom_physique_benificiare} 
                    onChange={props.handleChange}  
                    error={!props.formData.prenom_physique_benificiare  && props.isSubmitted?true:false}
                  />
                </div>
              </div>
            </div>
            <div className='grid grid-cols-12 mt-6 items-center'>
              <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse *</label>
              <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                <TextField
                  hiddenLabel
                  id="outlined-size-small"
                  size="small"
                  className='w-full'
                  name="adresse_benificiare"
                  value={props.formData.adresse_benificiare} 
                  onChange={props.handleChange}  
                  error={!props.formData.adresse_benificiare  && props.isSubmitted?true:false}
                />
              </div>
            </div>
            <div className='grid grid-cols-12 mt-6 items-center'>
              <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Complément adresse</label>
              <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                <TextField
                  hiddenLabel
                  id="outlined-size-small"
                  size="small"
                  className='w-full'
                  name="adresse_suite_benificiare"
                  value={props.formData.adresse_suite_benificiare} 
                  onChange={props.handleChange}  
                  // error={!props.formData.adresse_suite_benificiare  && props.isSubmitted?true:false}
                />
              </div>
            </div>
            <div className={props.formData.Personne_locataire === "PERSONNE_PHYSIQUE"?"grid grid-cols-12 mt-6 items-center":"grid-cols-12 mt-6 items-center hidden"} >
              <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Résidant à l’étranger</label>
              
              <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                <FormControl size="small" className='w-full'>
                  <InputLabel id="resident_etranger_physique_benificiare-label">Sélectionnez</InputLabel>
                  <Select
                    labelId="demo-resident_etranger_physique_benificiare-label"
                    id="resident_etranger_physique_benificiare"
                    label="resident_etranger_physique_benificiare"
                    name="resident_etranger_physique_benificiare"
                    value={props.formData.resident_etranger_physique_benificiare} 
                    onChange={props.handleChange}  
                    error={!props.formData.resident_etranger_physique_benificiare  && props.isSubmitted?true:false}
                  >
                    <MenuItem value={"Non"} >Non</MenuItem>
                    <MenuItem value={"Oui"} >Oui</MenuItem>

                  </Select>
                </FormControl>
              </div>
            </div> 
            <div className={props.formData.resident_etranger_physique_benificiare ==="Non"||  props.formData.Personne_locataire === "PERSONNE_MORALE"?'grid grid-cols-12 mt-6 items-center':'hidden grid-cols-12 mt-6 items-center'} >
              <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal *</label>
              <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                <TextField
                  hiddenLabel
                  id="outlined-size-small"
                  size="small"
                  className='w-full'
                  name="code_postal_benificiare"
                  value={props.formData.code_postal_benificiare} 
                  onChange={props.handleChange}  
                  error={!props.formData.code_postal_benificiare  && props.isSubmitted?true:false}
                />
              </div>
            </div>

            <div className='grid grid-cols-12 mt-6 items-center'>
              <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville *</label>
              <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                <TextField
                  hiddenLabel
                  id="outlined-size-small"
                  size="small"
                  className={props.formData.Personne_locataire === "PERSONNE_PHYSIQUE" && props.formData.resident_etranger_physique_benificiare=="Oui" ?'w-full':'hidden w-full'}
                  name="ville_benificiare"
                  value={props.formData.ville_benificiare} 
                  onChange={props.handleChange}  
                  error={!props.formData.ville_benificiare  && props.isSubmitted?true:false}
                />
                
                <FormControl size="small"  className={props.formData.Personne_locataire === "PERSONNE_PHYSIQUE" && props.formData.resident_etranger_physique_benificiare=="Oui"?'hidden w-full':' w-full'} >
                    <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      // className={props.formData.Personne_loueur === "PERSONNE_PHYSIQUE" && props.formData.resident_etranger_physique_candent=="Non" ?'w-full':'hidden w-full'}
                      label="Sélectionnez"
                      id='ville_benificiare_id'
                      name="ville_benificiare_id"
                      value={props.formData.ville_benificiare_id} 
                      onChange={props.handleChange}  
                      error={!props.formData.ville_benificiare  && props.isSubmitted?true:false}                  
                    >
                      {
                        props.formData.ville_benificiareArr.map((cityVal,cityIndex)=>{
                          return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                        })
                      }

                    </Select>
                  </FormControl>
              </div>
            </div>
            <div className={props.formData.Personne_locataire === "PERSONNE_PHYSIQUE" && props.formData.resident_etranger_physique_benificiare==="Oui"?'grid grid-cols-12 mt-6 items-center':'hidden grid-cols-12 mt-6 items-center'}>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Pays *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    className='w-full'
                    name="pays_benificiare"
                    value={props.formData.pays_benificiare} 
                    onChange={props.handleChange}  
                    error={!props.formData.pays_benificiare  && props.isSubmitted?true:false}  
                  />
                </div>
              </div>
            <div className={props.formData.Personne_loueur === "PERSONNE_MORALE"?"grid grid-cols-12 mt-6 items-center":"grid-cols-12 mt-6 items-center hidden"} >
              <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Greffe </label>
              <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                <TextField
                  hiddenLabel
                  id="outlined-size-small"
                  size="small"
                  className='w-full'
                  name="greffe_benificiare"
                  value={props.formData.greffe_benificiare} 
                  onChange={props.handleChange}  
                  error={!props.formData.greffe_benificiare  && props.isSubmitted?true:false}
                />
              </div>
            </div>
            <div className={props.formData.Personne_locataire === "PERSONNE_MORALE"?"":"hidden"}>
              <div className='px-0 group flex items-center gap-2 text-secondary mt-12 '>                   
                <span className='text-base font-normal'>Représentée par</span>
              </div>
              <div className='grid grid-cols-12 items-start mt-6'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Civilité </label>
                <div className='col-span-12 sm:col-span-9 md:col-span-4 mr-7 sm:mr-0 inline-flex items-center gap-2'>
                  <FormControl size="small" className='w-full'>
                    <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Sélectionnez"
                      name="representation_civilite_benificiare"
                      value={props.formData.representation_civilite_benificiare} 
                      onChange={props.handleChange}  
                      error={!props.formData.representation_civilite_benificiare  && props.isSubmitted?true:false}
                      
                    >
                      <MenuItem value={"Mme"}>Madame</MenuItem>
                      <MenuItem value={"M."}>Monsieur</MenuItem>

                    </Select>
                  </FormControl>
                  
                </div>
              </div>

              <div className='grid grid-cols-12 mt-6 items-center'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    className='w-full'
                    name="representation_nom_benificiare"
                    value={props.formData.representation_nom_benificiare} 
                    onChange={props.handleChange}  
                    error={!props.formData.representation_nom_benificiare  && props.isSubmitted?true:false}
                  />
                </div>
              </div>

              <div className='grid grid-cols-12 mt-6 items-center'>
                <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Prénom *</label>
                <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
                  <TextField
                    hiddenLabel
                    id="outlined-size-small"
                    size="small"
                    className='w-full'
                    name="representation_prenom_benificiare"
                    value={props.formData.representation_prenom_benificiare} 
                    onChange={props.handleChange}  
                    error={!props.formData.representation_prenom_benificiare  && props.isSubmitted?true:false}
                  />
                </div>
              </div>
            </div>
          </div>
          <Divider className='mb-6 mt-6' variant="middle" />
        </div>                     
      </div>    
      {/* Activité du fonds */}
      <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
        <h2 className='text-lg sm:text-[22px] font-bold text-primary'> </h2>
        <div className='grid grid-cols-12 mt-6 items-center'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'><h2 className='text-lg sm:text-[22px] font-bold text-primary'>Activité du fonds</h2></label>
          <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="outlined-size-small"
              size="small"
              className='w-full'
              rows="6"
              multiline={true}
              disabled={false}
              placeholder="Préciser l'activité principale du fonds"
              name="objet_lefondus"
              value={props.formData.objet_lefondus} 
              onChange={props.handleChange}                
            />
          </div>
        </div>        
      </div>
      
      <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
        <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Adresse des locaux</h2>
        <div className='grid grid-cols-12 mt-6 items-center'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Adresse *</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="adresse_siege"
              size="small"
              className='w-full'
              name="adresse_siege"
              value={props.formData.adresse_siege} 
              onChange={props.handleChange}  
              error={!props.formData.adresse_siege  && props.isSubmitted?true:false}
            />
          </div>
        </div>
        <div className='grid grid-cols-12 mt-6 items-center'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Complément adresse</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="adresse_suite_siege"
              size="small"
              className='w-full'
              name="adresse_suite_siege"
              value={props.formData.adresse_suite_siege} 
              onChange={props.handleChange}  
              // error={!props.formData.adresse_suite_siege  && props.isSubmitted?true:false}
            />
          </div>
        </div>
        <div className='grid grid-cols-12 mt-6 items-center'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Code postal *</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="code_postal"
              size="small"
              className='w-full'
              name="code_postal"
              value={props.formData.code_postal} 
              onChange={props.handleChange}  
              error={!props.formData.code_postal  && props.isSubmitted?true:false}
            />
          </div>
        </div>

        <div className='grid grid-cols-12 mt-6 items-center'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Ville *</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
           {/*  <TextField
              hiddenLabel
              id="ville"
              size="small"
              className='w-full'
              name="ville"
              value={props.formData.ville} 
              onChange={props.handleChange}  
              error={!props.formData.ville  && props.isSubmitted?true:false}
            /> */}
            <FormControl size="small"  className={' w-full'} >
                <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  // className={props.formData.Personne_loueur === "PERSONNE_PHYSIQUE" && props.formData.resident_etranger_physique_candent=="Non" ?'w-full':'hidden w-full'}
                  label="Sélectionnez"
                  id='ville_id'
                  name="ville_id"
                  value={props.formData.ville_id} 
                  onChange={props.handleChange}  
                  error={!props.formData.ville  && props.isSubmitted?true:false}                
                >
                  {
                    props.formData.villeArr.map((cityVal,cityIndex)=>{
                      return(<MenuItem value={cityVal.id}>{cityVal.city}</MenuItem>)
                    })
                  }

                </Select>
              </FormControl>
          </div>
        </div>
        
      </div>
      <div className='mt-4 sm:mt-0 py-3 sm:py-6 px-3 sm:px-6'>
        <h2 className='text-lg sm:text-[22px] font-bold text-primary'>Durée de la location</h2>
        <div className='grid grid-cols-12 mt-6 items-center'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Durée *</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
            <TextField
              hiddenLabel
              id="duree_deloction"
              size="small"
              className='w-full'
              name="duree_deloction"
              value={props.formData.duree_deloction} 
              onChange={props.handleChange}  
              error={!props.formData.duree_deloction  && props.isSubmitted?true:false}
            />
            <FormControl size="small" className='w-full'>
              <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label="Sélectionnez"
                id="type_duree" 
                name="type_duree"
                value={props.formData.type_duree} 
                onChange={props.handleChange}  
                error={!props.formData.type_duree  && props.isSubmitted?true:false}
              >
                <MenuItem value={"an(s)"}>An(s)</MenuItem>
                <MenuItem value={"mois"}>Mois</MenuItem>                
              </Select>
            </FormControl>
          </div>
        </div>
        <div className='grid grid-cols-12 mt-6 items-center'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Date de début</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}  >
              <DemoContainer components={['DatePicker']}  >
                {
                  props.formData.date_duree?
                  <DatePicker value={dayjs(props.formData.date_duree)}   name="date_duree"  onChange={(e)=>{props.handleChangeDate(e,"date_duree")}} format={"DD/MM/YYYY"}         
                  />
                  :
                  <DatePicker  name="date_duree"  onChange={(e)=>{props.handleChangeDate(e,"date_duree")}} format={"DD/MM/YYYY"}                   
                  />
                }
                
              </DemoContainer>
            </LocalizationProvider>
          </div>
        </div>
        <div className='grid grid-cols-12 mt-6 items-center'>
          <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Renouvellement *</label>
          <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
          <FormControl size="small" className='w-full'>
              <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label="Sélectionnez"
                id="Renouvellement" 
                name="Renouvellement"
                value={props.formData.Renouvellement} 
                onChange={props.handleChange}  
                error={!props.formData.Renouvellement  && props.isSubmitted?true:false}
              >
                <MenuItem value={"renouvelable par tacite reconduction."}>  renouvelable par tacite reconduction  </MenuItem>
                <MenuItem value={"sans possibilité de reconduction."}>Sans possiblité de reconduction</MenuItem>                
              </Select>
            </FormControl>
          </div>
        </div>

      </div>
  </div>
  </>
  )
}
