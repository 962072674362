/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from "react";
// import SideBar from "./SideBar";
import "./Style.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  TablePagination,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Footer from "../component/Footer";
import { HiOutlineDownload } from "react-icons/hi";
import { Link, Navigate } from "react-router-dom";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/fr.js";
import dayjs from "dayjs";
import { frFR } from "@mui/x-date-pickers/locales";
import SearchHead from "./SearchHead";
import { useSelector, useDispatch } from "react-redux";
import { orderActions } from "../_actions";
// import { alertActions } from "../_actions";
import { changeDateFormatFrench, convertNumberFrench } from "../_helpers";

import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import ScreenWrapper from "./ScreenWrapper";
import PageLoaderComp from "../component/pageLoaderComp";
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function Factures() {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [TabHead, setTabHead] = useState([
    "DENOMINATION",
    "FORMULAIRE",
    "NUMÉRO DE FACTURE",
    "MONTANT",
    "DATE DE CRÉATION",
    "",
  ]);
  const [pageLoading, setPageLoading] = useState(false);
  const [TabData, setTabData] = useState([]);
  const [totalRows, setTotalRows] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    start_date: "",
    end_date: "",
  });

  useEffect(() => {
    getAllFactures();
  }, []);

  const currentUser = useSelector((state) => state.authentication.user);
  const orders = useSelector((state) => state.orders.invoice);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - TabData.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getAllFactures = () => {
    setPageLoading(true);
    dispatch(orderActions.getAll(currentUser.data.id, "invoice"))
      .then((data) => {
        setPageLoading(false);
        setTabData(data);
        setTotalRows(data);
      })
      .catch(() => {
        setPageLoading(false);
      });
  };

  if (!currentUser) {
    return <Navigate to="/" />;
  }

  return (
    <>
      {pageLoading ? <PageLoaderComp /> : ""}
      <ScreenWrapper>
        <div className="xl:max-w-[1126px] w-full h-full bg-white rounded-[20px]">
          <div className="mainHead min-h-[80px] sm:min-h-[120px] flex sm:flex-row flex-col sm:items-center p-3 sm:p-8 sm:justify-between">
            <div>
              <h1 className="text-lg sm:text-xl md:text-lg xl:text-xl 2xl:text-xl font-extrabold text-white sm:mt-0 mt-4">
                Factures
              </h1>
            </div>
            <div className="w-full sm:w-auto flex justify-end">
              <SearchHead
                setTotalRows={setTotalRows}
                totalRows={totalRows}
                setTabData={setTabData}
              />
            </div>
          </div>

          <div className="mt-8 sm:px-10 px-4">
            <div className="mb-8 flex sm:flex-row flex-col items-center gap-x-4">
              <div className="flex items-center gap-x-4">
                <h3 className="text-lg font-normal text-primary pt-2">Du</h3>
                <LocalizationProvider
                  adapterLocale="fr"
                  dateAdapter={AdapterDayjs}
                  localeText={
                    frFR.components.MuiLocalizationProvider.defaultProps
                      .localeText
                  }
                >
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      value={dayjs(dateFilter.start_date)}
                      name="start_date"
                      format={"DD/MM/YYYY"}
                      onChange={(date) =>
                        setDateFilter((data) => ({
                          ...data,
                          ["start_date"]: date.$d,
                        }))
                      }
                      sx={{
                        "& .MuiInputLabel-root": { color: "#797979" },
                        "& fieldset": { border: "1px solid #eee" },
                      }}
                      localeText={{
                        clearButtonLabel: "Vider",
                      }}
                      slotProps={{ textField: { size: "small" } }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <div className="flex items-center gap-4">
                <h3 className="text-lg font-normal text-primary pt-2">au</h3>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="fr"
                  localeText={
                    frFR.components.MuiLocalizationProvider.defaultProps
                      .localeText
                  }
                >
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      value={dayjs(dateFilter.end_date)}
                      name="end_date"
                      format={"DD/MM/YYYY"}
                      onChange={(date) =>
                        setDateFilter((data) => ({
                          ...data,
                          ["end_date"]: date.$d,
                        }))
                      }
                      sx={{
                        "& .MuiInputLabel-root": { color: "#797979" },
                        "& fieldset": { border: "1px solid #eee" },
                      }}
                      slotProps={{ textField: { size: "small" } }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
              <button className="text-sm font-semibold sm:w-auto w-full text-white flex items-center justify-center px-4 py-3 mt-2  rounded-md bg-primary">
                Télécharger les factures
              </button>
            </div>
            <div className="mt-8 sm:px-9 px-4 hidden lg:block">
              <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      {TabHead.map((thItem, i) => {
                        return (
                          <TableCell
                            key={i}
                            className="text-base font-normal text-black-light whitespace-nowrap"
                          >
                            {thItem}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(rowsPerPage > 0
                      ? TabData.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                      : TabData
                    ).map((tdItem, i) => {
                      return (
                        <TableRow key={i}>
                          <TableCell
                            className="text-base font-normal text-primary whitespace-nowrap"
                            align="left"
                          >
                            {tdItem.username}
                          </TableCell>
                          <TableCell
                            className="text-base font-normal text-primary whitespace-nowrap"
                            align="left"
                          >
                            {tdItem.form_type}
                          </TableCell>
                          <TableCell
                            className="text-base font-normal text-primary whitespace-nowrap"
                            align="left"
                          >
                            {tdItem.payment_order_id}
                          </TableCell>
                          <TableCell
                            className="text-base font-normal text-primary whitespace-nowrap"
                            align="left"
                          >
                            {convertNumberFrench(tdItem.total)} €
                          </TableCell>
                          <TableCell
                            className="text-base font-normal text-primary whitespace-nowrap"
                            align="left"
                          >
                            {changeDateFormatFrench(tdItem.creation_date)}
                          </TableCell>
                          <TableCell
                            className="text-base font-normal text-primary whitespace-nowrap"
                            align="left"
                          >
                            <Link
                              target={"_blank"}
                              to={
                                process.env.REACT_APP_AWS_URL_STATIC_BUSYPLACE +
                                "assets/uploads/pdfgenerate/invoice/" +
                                tdItem.votre_pdf
                              }
                              rel="noopener noreferrer"
                            >
                              <HiOutlineDownload className="w-5 h-5" />
                            </Link>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {TabData.length <= 0 && (
                      <TableRow /* style={{ height: 53 * emptyRows }} */>
                        <TableCell colSpan={6}>
                          <p className="text-center font-bold capitalize text-lg">
                            Aucune donnée!
                          </p>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                  <TableFooter>
                    <TableRow>
                      <TablePagination
                        rowsPerPageOptions={[
                          5,
                          10,
                          25,
                          { label: "All", value: -1 },
                        ]}
                        // colSpan={3}
                        count={TabData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        SelectProps={{
                          inputProps: {
                            "aria-label": "rows per page",
                          },
                          native: true,
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
            </div>
            <div className="mt-8 block lg:hidden">
              {(rowsPerPage > 0
                ? TabData.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )
                : TabData
              ).map((tdItem, i) => {
                return (
                  <div
                    className="flex w-full flex-col justify-center gap-5 items-center bg-white rounded-[20px] shadow-md  p-3 mt-4"
                    style={{ borderBottom: "2px solid #d9d9d9" }}
                  >
                    <div className="flex flex-col justify-start items-start w-full">
                      <div
                        style={{ minWidth: "160px" }}
                        className="text-black-light font-medium"
                      >
                        DENOMINATION
                      </div>
                      <div>{tdItem.username}</div>
                    </div>
                    <div className="flex flex-col justify-start items-start w-full">
                      <div
                        style={{ minWidth: "160px" }}
                        className="text-black-light font-medium"
                      >
                        FORMULAIRE
                      </div>
                      <div>{tdItem.form_type}</div>
                    </div>

                    <div className="flex flex-col justify-start items-start w-full">
                      <div
                        style={{ minWidth: "160px" }}
                        className="text-black-light font-medium"
                      >
                        NUMÉRO DE FACTURE
                      </div>
                      <div
                        style={{ wordWrap: "break-word", overflow: "hidden" }}
                      >
                        {tdItem.payment_order_id}
                      </div>
                    </div>
                    <div className="flex flex-col justify-start items-start w-full">
                      <div
                        style={{ minWidth: "160px" }}
                        className="text-black-light font-medium"
                      >
                        MONTANT
                      </div>
                      <div>{convertNumberFrench(tdItem.total)} €</div>
                    </div>
                    <div className="flex flex-col justify-start items-start w-full">
                      <div
                        style={{ minWidth: "160px" }}
                        className="text-black-light font-medium"
                      >
                        DATE DE CRÉATION
                      </div>
                      <div> {changeDateFormatFrench(tdItem.creation_date)}</div>
                    </div>

                    <div className="flex flex-row justify-start gap-5 items-start w-full">
                      <Link
                        target={"_blank"}
                        to={
                          process.env.REACT_APP_AWS_URL_STATIC_BUSYPLACE +
                          "assets/uploads/pdfgenerate/invoice/" +
                          tdItem.votre_pdf
                        }
                        rel="noopener noreferrer"
                      >
                        <HiOutlineDownload className="w-5 h-5" />
                      </Link>
                    </div>
                  </div>
                );
              })}

              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: "All", value: -1 },
                    ]}
                    // colSpan={3}
                    count={TabData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        "aria-label": "rows per page",
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </div>
          </div>
        </div>
      </ScreenWrapper>
      <Footer />
    </>
  );
}
