/* eslint-disable no-useless-computed-key */
import React, { useState, useEffect } from "react";
import Sidebar from "./component/Sidebar";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import Breadcrumbs from "@mui/material/Breadcrumbs";

import Grid from "@mui/material/Unstable_Grid2";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { adminActions } from "../_actions";
import ToastMessageComp from "../component/ToastMessageComp";

export default function JournalUpdate() {
  const currentAdmin = useSelector((state) => state.administrator.admin);
  const alert = useSelector((state) => state.alert);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [departmentList, setDepartmentList] = useState([]);
  const [submitted, setsubmitted] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [formData, setFormData] = useState({
    id: id ? id : "",
    journal_empowered_name: "",
    department_id: "",
    attestation: "",
    duration: "",
    economique: "",
    medialex: "",
    partenaire: "",
    position: "",
    modification_other: "",
    discount_on_flat_rate_ad: "",
    copies: "",
    allow_logo: "",
    allow_header: "",
    wait_time: "",
    wait_time_value: "",
    missing_percent: "",
    publication_date: "",
    date_limit: "",
    price_journal: "",
    forfaitaires_other: "",
    adresse: "",
    telephone: "",
    fax: "",
    email: "",
    header: "",
    page_url: "",
    journal_image: "",
    old_journal_image: "",
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    getAllDepartment();
    getJournalDetails(id);
  }, []);
  async function getAllDepartment() {
    let departementGet = await axios.get(
      `${process.env.REACT_APP_API_URL}api/announce/departments`
    );

    setDepartmentList(departementGet.data.data);
  }

  async function getJournalDetails(id) {
    let JournalDetailsGet = await axios.get(
      `${process.env.REACT_APP_API_URL}api/admin/journal/${id}`
    );

    let getDetails = JournalDetailsGet.data.data;
    setFormData({
      id: id,
      journal_empowered_name: getDetails.journal_empowered_name,
      department_id: getDetails.department_id,
      attestation: getDetails.attestation,
      duration: getDetails.duration,
      economique: getDetails.economique,
      medialex: getDetails.medialex,
      partenaire: getDetails.partenaire,
      position: getDetails.position,
      modification_other: getDetails.modification_other,
      discount_on_flat_rate_ad: getDetails.discount_on_flat_rate_ad,
      copies: getDetails.copies,
      allow_logo: getDetails.allow_logo,
      allow_header: getDetails.allow_header,
      wait_time: getDetails.wait_time,
      wait_time_value: getDetails.wait_time_value,
      missing_percent: getDetails.missing_percent,
      publication_date: getDetails.publication_date,
      date_limit: getDetails.date_limit,
      price_journal: getDetails.price_journal,
      forfaitaires_other: getDetails.forfaitaires_other,
      adresse: getDetails.adresse,
      telephone: getDetails.telephone,
      fax: getDetails.fax,
      email: getDetails.email,
      header: getDetails.header,
      page_url: getDetails.page_url,
      journal_image: "",
      old_journal_image: getDetails.image,
    });
  }

  const changeHandle = async (e) => {
    const { name, value } = e.target;
    setFormData((formData) => ({ ...formData, [name]: value }));
  };

  const fileChangeHandle = async (e) => {
    if (e.target.files) {
      setFormData((formData) => ({
        ...formData,
        ["journal_image"]: e.target.files[0],
      }));
    }
  };

  const handleSubmit = async (e) => {
    setsubmitted(true);
    setSuccessful(false);
    if (
      formData.department_id &&
      formData.journal_empowered_name &&
      formData.price_journal
    ) {
      dispatch(adminActions.updateJournal(formData))
        .then((responseData) => {
          setSuccessful(true);
          if (responseData.sucess === true) {
            setTimeout(() => {
              navigate("/admin/journal");
            }, 1000);
          }
        })
        .catch(() => {
          setSuccessful(true);
        });
    }
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/">
      Tableau de bord
    </Link>,
    <Link underline="hover" key="1" color="inherit" href="/admin/journal">
      Journal habilité
    </Link>,
    <Typography key="3">Update journal</Typography>,
  ];

  if (!currentAdmin) {
    return <Navigate to="/admin/login" />;
  }

  return (
    <>
      {successful ? (
        <ToastMessageComp message={alert.message} type={alert.type} />
      ) : (
        ""
      )}

      <div className=" w-full h-full bg-white p-8">
        <Typography
          variant="h4"
          component="h4"
          fontWeight={500}
          color={"#1CBE93"}
        >
          Update journal
        </Typography>
        <Stack spacing={2} mb={3}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        </Stack>
        <Grid container spacing={4}>
          <Grid xs={12}>
            <Stack direction="row" spacing={2} className="pt-4">
              <FormControl fullWidth sx={{ textAlign: "left" }}>
                <InputLabel
                  id="Departement_de_publication *"
                  error={!formData.department_id && submitted ? true : false}
                >
                  Département de publication *
                </InputLabel>
                <Select
                  labelId="Departement_de_publication"
                  id="Departement_de_publicformat_economication"
                  value={formData.department_id}
                  label="Département de publication *"
                  // onChange={handleDepartementDePublication}
                  name="department_id"
                  onChange={changeHandle}
                  error={!formData.department_id && submitted ? true : false}
                  autoFocus={
                    !formData.department_id && submitted ? true : false
                  }
                >
                  {departmentList.map((department, index) => {
                    return (
                      <MenuItem value={department.id} key={index}>
                        {department.department_num} -{" "}
                        {department.department_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <TextField
                label="Journal empowered"
                variant="outlined"
                fullWidth
                name="journal_empowered_name"
                onChange={changeHandle}
                value={formData.journal_empowered_name}
                error={
                  !formData.journal_empowered_name && submitted ? true : false
                }
                autoFocus={
                  !formData.journal_empowered_name && submitted ? true : false
                }
              />

              <TextField
                label="Prix du journal"
                variant="outlined"
                fullWidth
                name="price_journal"
                onChange={changeHandle}
                value={formData.price_journal}
                error={!formData.price_journal && submitted ? true : false}
                autoFocus={!formData.price_journal && submitted ? true : false}
              />
            </Stack>
            <Stack direction="row" spacing={2} className="pt-4">
              
              <TextField
                label="Position"
                variant="outlined"
                fullWidth
                name="position"
                onChange={changeHandle}
                value={formData.position}
              />
              <TextField
                label="Remise Annonces Forfaitaires  (en%)"
                variant="outlined"
                fullWidth
                name="forfaitaires_other"
                onChange={changeHandle}
                value={formData.forfaitaires_other}
              />
              <TextField
                label="Remise Annonces aux caractères  (en%)"
                variant="outlined"
                fullWidth
                name="modification_other"
                onChange={changeHandle}
                value={formData.modification_other}
              />
              <FormControl sx={{ width: "100%" }}>
                <FormLabel id="demo-radio-buttons-group-label">Partenaire</FormLabel>
                <RadioGroup
                  sx={{ width: "100%" }}
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="partenaire"
                  onChange={changeHandle}
                  row
                  value={formData.partenaire}
                >
                  <FormControlLabel
                    value="Oui"
                    control={<Radio />}
                    label="Oui"
                  />
                  <FormControlLabel
                    value="Non"
                    control={<Radio />}
                    label="Non"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
            <Stack direction="row" spacing={2} className="pt-4">
              <FormControl sx={{ width: "100%" }}>
                <FormLabel id="demo-radio-buttons-group-label">
                  Attestation automatique
                </FormLabel>
                <RadioGroup
                  sx={{ width: "100%" }}
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="attestation"
                  onChange={changeHandle}
                  row
                  value={formData.attestation}
                >
                  <FormControlLabel
                    value="Oui"
                    control={<Radio />}
                    label="Oui"
                  />
                  <FormControlLabel
                    value="Non"
                    control={<Radio />}
                    label="Non"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              className={
                formData.attestation === "Non" ? "pt-4" : "pt-4 hidden"
              }
            >
              <TextField
                label="Délais moyen constaté de réception de l'attestation :"
                variant="outlined"
                fullWidth
                name="duration"
                onChange={changeHandle}
                value={formData.duration}
              />
            </Stack>

            <Stack direction="row" spacing={2} className="pt-4">
              <FormControl sx={{ width: "100%" }}>
                <FormLabel id="demo-radio-buttons-group-label">
                  Mode économique
                </FormLabel>
                <RadioGroup
                  sx={{ width: "100%" }}
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="economique"
                  onChange={changeHandle}
                  row
                  value={formData.economique}
                >
                  <FormControlLabel
                    value="Oui"
                    control={<Radio />}
                    label="Oui"
                  />
                  <FormControlLabel
                    value="Non"
                    control={<Radio />}
                    label="Non"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel id="demo-radio-buttons-group-label">
                  Regie medialex
                </FormLabel>
                <RadioGroup
                  sx={{ width: "100%" }}
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="medialex"
                  onChange={changeHandle}
                  row
                  value={formData.medialex}
                >
                  <FormControlLabel
                    value="Oui"
                    control={<Radio />}
                    label="Oui"
                  />
                  <FormControlLabel
                    value="Non"
                    control={<Radio />}
                    label="Non"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>

            <Stack direction="row" spacing={2} className="pt-4">
              <TextField
                label="Discount on flat rate ad"
                variant="outlined"
                fullWidth
                name="discount_on_flat_rate_ad"
                onChange={changeHandle}
                value={formData.discount_on_flat_rate_ad}
              />
              <TextField
                label="Copies"
                variant="outlined"
                fullWidth
                name="copies"
                onChange={changeHandle}
                value={formData.copies}
              />
            </Stack>

            <Stack direction="row" spacing={2} className="pt-4">
              <FormControl sx={{ width: "100%" }}>
                <FormLabel id="demo-radio-buttons-group-label">
                  Allow logo
                </FormLabel>
                <RadioGroup
                  sx={{ width: "100%" }}
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="allow_logo"
                  onChange={changeHandle}
                  row
                  value={formData.allow_logo}
                >
                  <FormControlLabel
                    value="Oui"
                    control={<Radio />}
                    label="Oui"
                  />
                  <FormControlLabel
                    value="Non"
                    control={<Radio />}
                    label="Non"
                  />
                </RadioGroup>
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <FormLabel id="demo-radio-buttons-group-label">
                  Allow header
                </FormLabel>
                <RadioGroup
                  sx={{ width: "100%" }}
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="allow_header"
                  onChange={changeHandle}
                  row
                  value={formData.allow_header}
                >
                  <FormControlLabel
                    value="Oui"
                    control={<Radio />}
                    label="Oui"
                  />
                  <FormControlLabel
                    value="Non"
                    control={<Radio />}
                    label="Non"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
            <Stack direction="row" spacing={2} className="pt-4">
              <FormControl sx={{ width: "100%" }}>
                <FormLabel id="demo-radio-buttons-group-label">
                  Wait time
                </FormLabel>
                <RadioGroup
                  sx={{ width: "100%" }}
                  aria-labelledby="demo-radio-buttons-group-label"
                  name="wait_time"
                  onChange={changeHandle}
                  row
                  value={formData.wait_time}
                >
                  <FormControlLabel
                    value="Oui"
                    control={<Radio />}
                    label="Oui"
                  />
                  <FormControlLabel
                    value="Non"
                    control={<Radio />}
                    label="Non"
                  />
                </RadioGroup>
              </FormControl>
            </Stack>
            <Stack
              direction="row"
              spacing={2}
              className={formData.wait_time === "Oui" ? "pt-4" : "pt-4 hidden"}
            >
              <TextField
                label="Délais moyen de réception des justificatifs papier:"
                variant="outlined"
                fullWidth
                name="wait_time_value"
                onChange={changeHandle}
                value={formData.wait_time_value}
              />
            </Stack>
            <Stack direction="row" spacing={2} className="pt-4">
              <TextField
                type="number"
                min={1}
                label="Missing percent"
                variant="outlined"
                fullWidth
                name="missing_percent"
                onChange={changeHandle}
                value={formData.missing_percent}
              />
            </Stack>
            <Stack direction="row" spacing={2} className="pt-4">
              <TextField
                id="outlined-multiline-static"
                label="Attestation Header"
                multiline
                rows={4}
                fullWidth
                name="header"
                onChange={changeHandle}
                value={formData.header}
              />
            </Stack>
            <Stack direction="row" spacing={2} className="pt-4">
              <TextField
                fullWidth
                type="file"
                // label="Upload image"
                name="journal_image"
                onChange={fileChangeHandle}
                //  value={formData.image}
              />
              {/* <Button
                     variant="contained"
                     component="label"
                   >
                     Upload Image
                     <input
                       type="file"
                       hidden
                       name="image"
                       onChange={fileChangeHandle}
                       value={formData.image}
                     />
                   </Button> */}
            </Stack>
            <Stack direction="row" spacing={2} className="pt-4">
              <FormControl fullWidth sx={{ textAlign: "left" }}>
                <InputLabel id="Jour-de-publication">
                  Jour de publication
                </InputLabel>
                <Select
                  labelId="Jour-de-publication"
                  id="Jour-de-publication"
                  value={formData.publication_date}
                  label="Jour de publication"
                  // onChange={handleJourdepublication}
                  onChange={changeHandle}
                  name="publication_date"
                >
                  <MenuItem value={"Lundi"}>1-Lundi</MenuItem>
                  <MenuItem value={"Mardi"}>2-Mardi</MenuItem>
                  <MenuItem value={"Mercredi"}>3-Mercredi</MenuItem>
                  <MenuItem value={"Jeudi"}>4-Jeudi</MenuItem>
                  <MenuItem value={"Vendredi"}>5-Vendredi</MenuItem>
                  <MenuItem value={"Samedi"}>6-Samedi</MenuItem>
                  <MenuItem value={"Dimanche"}>7-Dimanche</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ textAlign: "left" }}>
                <InputLabel id="Jourlimitedecommande">
                  Jour limite de commande
                </InputLabel>
                <Select
                  labelId="Jourlimitedecommande"
                  id="Jourlimitedecommande"
                  value={formData.date_limit}
                  label="Jour limite de commande"
                  // onChange={handleJourlimitedecommande}
                  name="date_limit"
                  onChange={changeHandle}
                >
                  <MenuItem value={"Lundi"}>1-Lundi</MenuItem>
                  <MenuItem value={"Mardi"}>2-Mardi</MenuItem>
                  <MenuItem value={"Mercredi"}>3-Mercredi</MenuItem>
                  <MenuItem value={"Jeudi"}>4-Jeudi</MenuItem>
                  <MenuItem value={"Vendredi"}>5-Vendredi</MenuItem>
                  <MenuItem value={"Samedi"}>6-Samedi</MenuItem>
                  <MenuItem value={"Dimanche"}>7-Dimanche</MenuItem>
                </Select>
              </FormControl>

              <TextField
                label="Adresse"
                variant="outlined"
                fullWidth
                name="adresse"
                onChange={changeHandle}
                value={formData.adresse}
              />
            </Stack>
            <Stack direction="row" spacing={2} className="pt-4">
              <TextField
                label="Téléphone"
                variant="outlined"
                fullWidth
                name="telephone"
                onChange={changeHandle}
                value={formData.telephone}
              />
              <TextField
                label="Fax"
                variant="outlined"
                fullWidth
                name="fax"
                onChange={changeHandle}
                value={formData.fax}
              />
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                name="email"
                onChange={changeHandle}
                value={formData.email}
              />
            </Stack>

            <Box sx={{ textAlign: "left" }}>
              <Button
                variant="contained"
                type="button"
                className="bg-secondary btnShadow w-1/4  my-4 font-bold text-sm text-white  py-3   rounded-lg hover:bg-primary transition-all duration-300"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </Grid>
        </Grid>
      </div>
    </>
  );
}
