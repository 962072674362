/* eslint-disable array-callback-return */
import React, {useState, useEffect } from 'react' 
import moment from 'moment';
import axios from 'axios';
import dayjs from 'dayjs';
import { BsFillQuestionCircleFill, BsPlusCircle } from 'react-icons/bs'
import { MdEuroSymbol } from 'react-icons/md'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/fr.js';
import { frFR } from '@mui/x-date-pickers/locales';
import Tooltip from '@mui/material/Tooltip';
import { FormControl, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select,ListSubheader , TextField ,Divider} from '@mui/material';
import RemoveCircleRoundedIcon from '@mui/icons-material/RemoveCircleRounded';

export  function Additif(props) {
  const [Departments, setDepartments] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0)
    getAllDepartment();
  }, [])

  async function getAllDepartment(){
    let departementGet =  await axios.get(`${process.env.REACT_APP_API_URL}api/announce/departments`); 
    
    setDepartments(departementGet.data.data)
  }

  const StyledTooltip = ({ title, children, ...props }) => (
    <Tooltip
      {...props}
      title={title}
      placement="right"
      componentsProps={{
        tooltip: {
          sx: {
            background: '#DEE3E2',
            width: '129px',
            padding: '10px',
            color: '#27295B',
            fontSize: '14px',
            fontWeight: '400'
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );

  return (
  <>
  <div className='' >
    <div className='py-6 px-3 sm:px-6' style={{ borderBottom: '0.5px solid #DEE3E2' }}>
      <h1 className='text-lg sm:text-2xl font-black text-primary'>Annonce légale : {props.adsname}</h1>
    </div>
    <div className='py-3 sm:py-6 px-3 sm:px-6'>
      <h4 className='text-lg sm:text-[22px] font-bold text-primary mb-5'>Journal de publication de l'annonce</h4>
      <h2 className='text-lg sm:text-[22px] font-bold text-primary mb-3'>Département de publication</h2>
      <hr />
      
      <div className='grid grid-cols-12 items-start mt-3 sm:mt-6'>
        <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Département *</label>
        <div className='col-span-12 sm:col-span-9 md:col-span-9 md:mr-10 inline-flex items-center gap-2'>
          <FormControl size="small" className='w-full'>
            <InputLabel id="demo-simple-select-label">Sélectionnez</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              // id={val.index}
              label="Sélectionnez"
              name="department_name"
              value={props.formData.department_name} 
              onChange={props.handleChange}  
              error={!props.formData.department_name  && props.isSubmitted?true:false}
            >
              {
                Departments.map((department,key)=>{
                  return <MenuItem value={department.id}>{department.department_num} - {department.department_name}</MenuItem>
                })
              }
              
            </Select>
          </FormControl>
                        
        </div>                
      </div>
      <div className='grid grid-cols-12 mt-6 items-center'>
        <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Nom de la société *</label>
        <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
          <TextField
            hiddenLabel
            id="outlined-size-small"
            size="small"
            className='w-full'
            name="societe_name"
            value={props.formData.societe_name} 
            onChange={props.handleChange}  
            error={!props.formData.societe_name  && props.isSubmitted?true:false}
          />
        </div>
      </div>
     
      <div className='grid grid-cols-12 items-center mt-6'>
        <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Date de publication *</label>
        <div className='col-span-12 sm:col-span-9 mr-7 md:mr-0 md:col-span-7 lg:col-span-4'>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fr" localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}   >
            <DemoContainer components={['DatePicker']}  >
              {
                props.formData.date_typepublication?
                <DatePicker value={dayjs(props.formData.date_typepublication)}   name="date_typepublication"   onChange={(e)=>{props.handleChangeDate(e,"date_typepublication")} }     format={"DD/MM/YYYY"}               
                />
                :
                <DatePicker  name="date_typepublication"   onChange={(e)=>{props.handleChangeDate(e,"date_typepublication")} }      format={"DD/MM/YYYY"}                
                />
              }
              
            </DemoContainer>
          </LocalizationProvider>
        </div>

      </div>
      <div className='grid grid-cols-12 mt-6 items-center'>
        <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'>Journal de publication *</label>
        <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
          <TextField
            hiddenLabel
            id="journal_publication"
            size="small"
            className='w-full'
            name="journal_publication"
            value={props.formData.journal_publication} 
            onChange={props.handleChange}  
            error={!props.formData.journal_publication  && props.isSubmitted?true:false}
          />
        </div>
      </div>
      <div className='grid grid-cols-12 mt-6 items-center'>
        <label className='col-span-12 sm:col-span-3 my-1 sm:mb-0 text-base font-normal text-black-light'><h2 className='text-lg sm:text-[22px] font-bold text-primary'>Additif</h2></label>
        <div className='col-span-12 sm:col-span-9 md:col-span-7 mr-7 inline-flex items-center gap-2'>
          <TextField
            hiddenLabel
            id="outlined-size-small"
            size="small"
            className='w-full'
            rows="6"
            multiline={true}
            disabled={false}
            placeholder="Pour ajouter une information dans le texte initialement publié,saisir ou Copier-Coller la rectification de votre annonce dans le cadre ci-dessous"
            name="additif_type"
            value={props.formData.additif_type} 
            onChange={props.handleChange} 
                           
          />
        </div>
      </div> 
    </div>      
  </div>
  </>
  )
}
