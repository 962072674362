import React, { useEffect, useState, useRef } from "react";
import {
  useNavigate,
  useLocation,
  useHistory,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import axios from "axios";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import PageLoaderComp from "../component/pageLoaderComp";
import { announceConstants } from "../_constants";

export default function FormSuccessAdmin(props) {
  // console.log("props",props)
  const announceDetailsRef = useRef(props.announceDetails);
  const [PageLoading, setPageLoading] = useState(false);
  const [announceDetailsGet, setannounceDetailsGet] = useState("");
  const [announceDataGet, setannounceDataGet] = useState("");
  let navigate = useNavigate();
  const dispatch = useDispatch();
  let { ads, adsname, annonce_id } = useParams();
  let location = useLocation();
  const currentUser = useSelector((state) => state.authentication.user);
  const announceData = useSelector((state) => state.announce.announce);
  // console.log("announceData",announceData);
  // console.log("props.announceDetails",props.announceDetails);

  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.removeItem("announceCurrentUrl");
    localStorage.removeItem("activeStep");
  }, []);
  // console.log("props", props);
  useEffect(() => {
    setPageLoading(true);
    announceDetailsRef.current = props.announceDetails;
    if (props.announceDetails) {
      setPageLoading(false);
    }
    /* setTimeout(() => {
      setPageLoading(false)
    }, 2000); */
  }, [props.announceDetails]);
  // console.log("announceDetailsRef.current", announceDetailsRef.current);
  async function getAnnounceDetails(announce_id) {
    try {
      let response = await axios.get(
        `${process.env.REACT_APP_API_URL}api/announce/announce/${announce_id}`
      );

      if (response.data.sucess === true) {
        setannounceDataGet(response.data);
        announceDetailsRef.current.data = response.data;
        let announce = {
          data: response.data,
          announce_id: announce_id,
        };
        //console.log('announce.datatatata',announce);
        dispatch({ type: announceConstants.CREATE_SUCCESS, announce });
        /* if(parseInt(response.data.data.status) === 3){
         navigate("/");
        } */
      }
      return { success: true, data: response.data };
    } catch (error) {
      // // console.log(error);
      return { success: false, data: "" };
    }
  }

  const handleBack = () => {
    navigate(`/admin/clientdetails/${props.user_id}`);
  };
  const handleOpenPdf = (e, url) => {
    // navigate("/");
  };

  // console.log("announceDetailsRef.current", announceDetailsRef.current);

  return (
    <>
      {props.loadingFullpage || PageLoading ? <PageLoaderComp /> : ""}
      <div className="my-0 sm:my-12 max-w-[680px] mx-4 sm:m-auto">
        <h6 className="text-lg sm:text-[22px] font-black text-primary py-4">
          Votre règlement a été effectué avec succès !
        </h6>
        <p className="font-normal text-[15px] text-gray">
          Achat :
          <span className="text-base font-medium text-primary">
            {" "}
            Annonce légale{" "}
            {announceDetailsRef.current &&
            announceDetailsRef.current.data &&
            announceDetailsRef.current.data.add_type
              ? announceDetailsRef.current.data.add_type.toUpperCase()
              : ""}
          </span>
        </p>
        <p className="font-normal text-[15px] text-gray">
          N° de commande :
          <span className="text-base font-medium text-primary">
            {announceDetailsRef.current && announceDetailsRef.current.data
              ? announceDetailsRef.current.data.payment_order_id
              : moment().format("DDMMY") + "_" + annonce_id.padStart(8, "0")}
          </span>
        </p>
        <p className="font-normal text-[15px] text-gray">
          Paiement par :
          <span className="text-base font-medium text-primary">
            {" "}
            carte bancaire
          </span>
        </p>
        <p className="font-normal text-[15px] text-gray">
          Montant total de la transaction :
          <span className="text-base font-medium text-primary">
            {" "}
            {announceDetailsRef.current &&
            announceDetailsRef.current.data &&
            announceDetailsRef.current.data.payment_price
              ? announceDetailsRef.current.data.payment_price
              : 0}{" "}
            €
          </span>
        </p>
        <p className="font-normal text-[15px] text-gray">
          Référence de la transaction :
          <span className="text-base font-medium text-primary">
            {" "}
            {announceDetailsRef.current &&
            announceDetailsRef.current.data &&
            announceDetailsRef.current.data.payment_order_id
              ? announceDetailsRef.current.data.payment_order_id
              : moment().format("DDMMY") + "_" + annonce_id.padStart(8, "0")}
          </span>
        </p>
        <p className="font-normal text-[15px] text-gray">
          Commande passée le :
          <span className="text-base font-medium text-primary">
            {" "}
            {announceDetailsRef.current &&
            announceDetailsRef.current.data &&
            announceDetailsRef.current.data.date_payment
              ? moment(announceDetailsRef.current.data.date_payment).format(
                  "D MMMM YYYY"
                )
              : ""}
          </span>
        </p>
        <p className="font-normal text-[15px] text-gray">
          Parution :
          <span className="text-base font-medium text-primary">
            {" "}
            département{" "}
            {announceDetailsRef.current &&
            announceDetailsRef.current.data &&
            announceDetailsRef.current.data.select_department &&
            announceDetailsRef.current.data.select_department.department_num
              ? announceDetailsRef.current.data.select_department.department_num
              : ""}{" "}
            sur le journal{" "}
            {announceDetailsRef.current &&
            announceDetailsRef.current.data &&
            announceDetailsRef.current.data.select_cartdetailJ &&
            announceDetailsRef.current.data.select_cartdetailJ
              .journal_empowered_name
              ? announceDetailsRef.current.data.select_cartdetailJ
                  .journal_empowered_name
              : ""}{" "}
            du{" "}
            {announceDetailsRef.current &&
            announceDetailsRef.current.data &&
            announceDetailsRef.current.data.date_parution
              ? moment(announceDetailsRef.current.data.date_parution).format(
                  "dddd D MMMM YYYY"
                )
              : ""}{" "}
            {/* lundi 11 juillet 2022 */}
          </span>
        </p>
        <p className="my-6 text-xs sm:text-base font-medium text-primary bg-[#602db42b] rounded-md p-2">
          Une attestation de parution officielle et votre facture vous ont été
          envoyées par e-mail.{" "}
        </p>

        <Stack spacing={2} direction="row">
          <Button
            onClick={handleBack}
            className={
              "w-50 h-12 pl-2 pr-2 pt-5 pb-5  normal-case  rounded-md bg-thColor font-extrabold text-sm sm:text-base  3xl:text-lg leading-[1.2rem]  md:leading-[1.2rem]  3xl:leading-[1.2rem]  text-white transition-all duration-300 hover:bg-secondary hover:btnShadow"
            }
          >
            De retour à la maison
          </Button>
          {announceDetailsRef.current &&
          announceDetailsRef.current.data &&
          announceDetailsRef.current.data.attestation &&
          announceDetailsRef.current.data.votre_pdf ? (
            <>
              <Link
                className=""
                href={`${process.env.REACT_APP_AWS_URL_STATIC_BUSYPLACE}assets/uploads/pdfgenerate/attestation/${announceDetailsRef.current.data.attestation}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  variant="text"
                  className={
                    "w-50 h-12 p-2 pt-5 pb-5   capitalize  rounded-md bg-thColor font-extrabold text-sm sm:text-base  3xl:text-lg leading-[1.2rem]  md:leading-[1.2rem]  3xl:leading-[1.2rem]  text-white transition-all duration-300 hover:bg-secondary hover:btnShadow"
                  }
                >
                  VOTRE ATTESTATION
                </Button>
              </Link>
              <Link
                className=""
                href={`${process.env.REACT_APP_AWS_URL_STATIC_BUSYPLACE}assets/uploads/pdfgenerate/invoice/${announceDetailsRef.current.data.votre_pdf}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Button
                  variant="text"
                  className={
                    "w-50 h-12 p-2 pt-5 pb-5  capitalize   rounded-md bg-thColor font-extrabold text-sm sm:text-base  3xl:text-lg leading-[1.2rem]  md:leading-[1.2rem]  3xl:leading-[1.2rem]  text-white transition-all duration-300 hover:bg-secondary hover:btnShadow"
                  }
                >
                  VOTRE FACTURE
                </Button>
              </Link>
            </>
          ) : (
            ""
          )}
        </Stack>
      </div>
    </>
  );
}
