import React from "react";

export default function CommentPublier() {
  const CommentData = [
    {
      id: "1",
      heading: "Sélectionnez le formulaire",
      title: `Choisissez le modèle d'annonce légale qui correspond à vos besoins : Création de société (création de SAS, SASU, SARL, etc.), cessation d'activité (dissolution, liquidation)...`,
    },
    {
      id: "2",
      heading: "Complétez le formulaire ",
      title: `Toutes nos annonces légales respectent un modèle d'annonce légale optimisé et validé par nos formalistes (chacune de nos annonces légales respecte les exigences administratives du greffe du tribunal de commerce).`,
    },
    {
      id: "3",
      heading: "Validez et payer ",
      title: `Après validation et paiement de votre annonce légale, vous obtenez immédiatement l'attestation de publication de votre annonce légale, qui viendra compléter votre dossier de formalités, prêt à être déposé au greffe du tribunal de commerce. Vous recevez en parallèle la facture de votre annonce légale.`,
    },
    {
      id: "4",
      heading: "Publiez l’annonce",
      title: `Votre annonce légale est publiée dans un journal d'annonces légales (JAL), parmi l'ensemble des journaux d'annonces légales habilités sur l'ensemble des départements en France et DOM-TOM.`,
    },
  ];
  return (
    <>
      <div className="md:max-w-[1477px] relative w-full h-full m-auto py-12 sm:py-16 lg:py-24 px-6 sm:px-14">
        <div className="text-center">
          <h2 className="text-2xl xl:text-3xl font-black text-primary">
            Comment publier une annonce légale ?
          </h2>
          <p className="max-w-[876px] m-auto text-base text-gray mt-4 text-justify sm:text-center">
            Vous créez une société ? Vous allez effectuer une modification des
            statuts de votre entreprise ? Avec busyplace.fr, publier une annonce
            légale, c'est à la fois{" "}
            <span className="text-lg text-secondary font-bold">
              simple, rapide et pas cher !
            </span>{" "}
          </p>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-4 items-start justify-between gap-5 mt-10 sm:mt-24">
          {CommentData.map((value, index) => {
            return (
              <div className="" key={index}>
                <div className="flex gap-3 items-center">
                  <span className="bg-thColor w-[42px] h-[42px] flex items-center rounded-full justify-center text-white text-xl font-black">
                    {value.id}
                  </span>
                  <h3 className="text-base font-extrabold text-primary">
                    {value.heading}{" "}
                  </h3>
                </div>
                <p className="text-base text-gray font-light md:max-w-[305px] mt-3">
                  {value.title}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
