import React from "react";
// import heroImg from "../../assets/Home/herobg.svg";
import heroImgWebP from "../../assets/Home/herobg.webp";

import { ReactComponent as CheckHero } from "../../assets/Home/CheckHero.svg";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
export default function Hero({ scrollToLesFormulaires }) {
  return (
    <>
      <Helmet>
        <title>Annonces légales instantanées pas cher partout en France</title>
        <meta
          name="description"
          content="Annonce légale au prix le plus juste ; publiez toutes vos annonces légales simplement et au meilleur prix. Publication immédiate apres paiement sur tous les départements de France et DOM TOM."
        />
        {/* <link rel="canonical" href={`${process.env.REACT_APP_WEB_URL}/`} /> */}
      </Helmet>
      <div className="md:max-w-[1477px]  w-full h-full m-auto py-8 sm:py-16 lg:py-24">
        <div className="grid grid-cols-12 items-center justify-between px-6 sm:px-14 ">
          <div className="col-span-12 lg:col-span-7 order-last lg:order-first">
            <div className="w-max-[740px] flex gap-5 sm:gap-10 flex-col">
              <span className="text-xl sm:text-3xl xl:text-[35px] font-extrabold md:leading-[45px] mt-6 text-primary">
                {" "}
                La plateforme nationale de publication des{" "}
                <span style={{ color: "#1CBE93" }}>annonces légales</span>{" "}
              </span>
              <ul className="flex flex-col gap-0 sm:gap-4">
                <li className="flex items-center gap-3">
                  <CheckHero /> Publiez vos annonces légales en 3 clics
                </li>
                <li className="flex items-center gap-3">
                  <CheckHero /> Simplifiez la rédaction et la publication de vos
                  annonces légales
                </li>
                <li className="flex items-center gap-3">
                  <CheckHero /> Recevez votre attestation officielle
                  immédiatement
                </li>
              </ul>
              <Link
                to="/nos-formulaires"
                className="btnShadow flex items-center justify-center text-white rounded-[25.5px] font-semibold text-base sm:text-xl py-2 sm:py-3 sm:h-[51px] sm:w-[254px] transition-all duration-300 mt-3 bg-primary hover:bg-secondary"
              >
                Publier une annonce
              </Link>
              {/* <button
                className='btnShadow flex items-center justify-center text-white rounded-[25.5px] font-semibold text-base sm:text-xl py-2 sm:py-3 sm:h-[51px] sm:w-[254px] transition-all duration-300 mt-3 bg-primary hover:bg-secondary'
                // onClick={scrollToLesFormulaires}
              >
                Publier une annonce
              </button> */}
            </div>
          </div>
          {/* <div className='order-first lg:order-last col-span-12 lg:col-span-5'>
            <img src={heroImg} alt="Hero Images" className='w-full h-full m-auto' />
          </div> */}
          <div className="order-first lg:order-last col-span-12 lg:col-span-5">
            <picture>
              <source type="image/webp" srcSet={heroImgWebP} />
              <img
                src={heroImgWebP}
                alt="Hero Images"
                className="w-full h-full m-auto max-w-full h-auto"
              />
              {/* style={{ maxWidth: '100%', height:'auto' }} */}
            </picture>
          </div>
        </div>
      </div>
    </>
  );
}
